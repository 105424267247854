<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Brands
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>

        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Brands</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg" />
          </v-hover>
        </v-badge>
        <div>
          <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="pad px-8">
      <v-row>
        <v-col>
          <v-dialog max-width="887px" v-model="dialog" class="cdz" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-8">
                  {{ formbtn() }} Brand</v-card-title>
                <v-card-text class="px-6 pb-0">
                  <v-text-field outlined class="rounded-xl" v-model="name" label="Enter Brand*"
                    :rules="[(v) => !!v || 'Brand name is required']" required></v-text-field>
                  <v-text-field outlined class="rounded-xl" v-model="description" label="Description"></v-text-field>
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="btn outline-btn pa-4"
                    @click="dialog = false">Cancel</v-btn>
                  <v-btn width="102px" height="48px" :loading="loading" rounded @click="saveInputs" class="btn primary-btn pa-4">{{
                    formbtn() }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" prepend-inner-icon="mdi-magnify" clearable v-model="search"></v-text-field></v-col> -->
      </v-row>
      <v-row class="d-flex justify-center my-0 py-0">
        <v-col>
          <div class="text-h6">Brands</div>
        </v-col>

        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo v-model="search" label="Search here..." prepend-inner-icon="mdi-magnify" hide-details
              class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
            <!-- <v-btn  depressed outlined rounded class="border-button mx-3"
            ><v-icon>mdi-tune</v-icon>FILTER</v-btn
          >  -->
          <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
              rounded
              depressed
              v-bind="attrs"
              v-on="on"
            >
            <v-img
            v-bind="attrs" 
            v-on="on" 
            :src="require(`@/assets/svg/sort.svg`)"
            
            />              
              <span>SORT</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item 
              v-for="option in sortOptions" 
              :key="option.value" 
              @click="selectSortOption(option.value)"
              :class="{'selected-sort-option': selectedSort === option.value}"
            >
              <v-list-item-title>
                {{ option.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
            <v-btn class="btn mx-3" :disabled="selected.length == 0"
              :class="!selected.length == 0 ? 'outline-btn' : 'disabled-outline-btn'"
              outlined rounded
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2"
            rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn depressed rounded outlined class="btn outline-btn mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-btn v-if="$store.state.role.Brand.admin" @click="(dialog = true), newCreateBrands()"
              class="btn primary-btn mx-3" rounded depressed><v-icon class="pr-2">mdi-plus</v-icon>Create</v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table 
      :disable-sort="true"
      :items-per-page="options.itemsPerPage"
      :server-items-length="count"
      :options.sync="options"
      @update:options="getBrand"
      fixed-header height="calc(100vh - 230px)" class="rounded-table" v-model="selected"
        :loading="dataTableLoading" loading-text="Loading Brands" :headers="headers" :items="tableData" show-select
        :single-select="singleSelect" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 100],
        }">
        <template v-slot:loading>
          <bubble-loader/>
        </template>
        <template v-slot:no-results>
          <div class="custom-no-data">
            <p>No Data Available</p>
          </div>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.Brand.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-container></v-container>
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

              <p class="text-h5 pt-6 pb-0">Delete Brands</p>
              <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="btn outline-btn" large width="157px" rounded
                  @click="deleteDialog = false">CANCEL</v-btn>
                <v-btn class="btn primary-btn" depressed large width="157px" :loading="dLoading" rounded
                  @click="deleteData(selected)">DELETE</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errorDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Brand Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" max-width="366px" class="cdz" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Brand {{ formbtnValue() }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>

<script>
import BrandController from "@/controllers/BrandController";
import MastersDropdownComponent from "@/components/MastersDropdownComponent.vue";
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import { getDateFromTimestamp } from "@/helpers/utils";
import BubbleLoader from "@/components/BubbleLoader.vue";

export default {
  name: "BrandsView",
  components: {
    Notification, MastersDropdownComponent, BubbleLoader
  },
  data() {
    return {
      notificationDialog: false,
      options: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      errorMessage: "",
      dialog: false,
      dialogTitle: "Dialog Title",
      name: "",
      description: "",
      editId: null,
      singleSelect: false,
      deleteDialog: false,
      dLoading: false,
      formbtnBool: false,
      brandData: [],
      selected: [],
      search: "",
      dataTableLoading: true,
      loading: false,
      searchBool: false,
      sortCol:"name",
      sortOrder: "asc", // Default sort order (asc or desc)
      selectedSort: "brandAsc", // Default selected sort option
      sortOptions: [
        { value: "brandAsc", label: "Brand - Ascending (A-Z)" },
        { value: "brandDesc", label: "Brand - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],
      headers: [
        { text: "Brand Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Created  On", value: "createdOn" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
    };
  },
  watch: {
    // Add watcher for search
    search(newValue) {
      // Update URL when search changes
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
      this.search = newValue || "";
      this.options.page = 1
      this.getBrand();
    }
    // options: {
    //   handler() {
    //     console.log(this.options);
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     if(this.searchBool){
    //       this.searchData(this.search);
    //     }else{
    //     this.getBrand();
    //     }
    //   },
    //   deep: true,
    // },
    // search(newValue){
    //   console.log(newValue);
    //     this.searchBool=true
    //     this.pageSize = this.options.itemsPerPage;
    //     this.page = this.options.page;
    //     this.options.page=1;
    //     this.searchData(newValue);
    //     if(newValue=="" || newValue==null){
    //       this.getBrand();
    //       this.searchBool=false;
    //     }
    // }
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    // async searchData(search){
    //   const response = await BrandController.searchBrand(
    //     this.pageSize,
    //     this.page,
    //     search,
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },
    getDate(timeStamp) {
      return getDateFromTimestamp(timeStamp);
    },
    updateData(item) {
      this.editId = item.id; // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
      this.name = item.name;
      this.description = item.description;
    },
    selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "brandAsc":
          this.sortCol = "name";
          this.sortOrder = "asc";
          break;
        case "brandDesc":
          this.sortCol = "name";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "brandAsc";
          this.sortCol = "name";
          this.sortOrder = "asc";
      }
      this.options.page = 1;
      this.getBrand();
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },

    async saveInputs() {
      if (this.$refs.form.validate()) {
        this.formbtnBool == false;
        var res;
        this.loading = true;
        // checking case for update/create
        if (this.formbtnBool == false) {
          const response = await BrandController.createBrand(
            this.name,
            this.description
          );
          res = response;
          console.log("create cate", response);
        } else {
          const response = await BrandController.updateBrand(
            this.name,
            this.description,
            this.editId
          );
          console.log(response);
          res = response;
          this.getBrand();
        }
        // Close the dialog

        this.dialog = false;
        if (res.data.flag) {
          this.loading = false;
          this.successDialog = true;
        } else {
          this.loading = false;
          this.errorDialog = true;
          this.errorMessage = res.data.error;
        }
        this.getBrand();
      }
    },

    async deleteData(data) {
      // if(data.length==1){
      // this.dLoading = true;
      // this.deleteDialog = true
      // const response = await BrandController.deleteBrand(data[0].id);
      // this.getBrand();
      // this.deleteDialog = false
      // console.log("single select reponse",response)
      // this.dLoading = false
      // this.selected = []
      // }else{
      //   var ids="";
      //   for(var i=0;i<data.length;i++){
      //     ids = ids + data[i].id;
      //       if( i != data.length - 1 ) {
      //           ids = ids + ",";
      //       }
      //   }
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id);
      });
      this.dLoading = true;
      this.deleteDialog = true;
      const response = await BrandController.deleteBulkBrand(ids);
      if (response.data.flag) {
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
      } else {
        alert(response.data.error);
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = [];
      }
      this.getBrand();
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    async newCreateBrands() {
      this.name = null;
      this.description = null;
      this.formbtnBool = false;
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },

    async getBrand() {
      this.page = this.options.page;
      this.pageSize = this.options.itemsPerPage;
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        sortCol: this.sortCol,
        sortOrder: this.sortOrder,
      }
      const query = "?"+(new URLSearchParams(params).toString());
      const response = await BrandController.getBrandsByPagination(
       query
      );
      if (response.status == 200) {
        this.dataTableLoading = false;
        this.count = response.data.count;
        if (response.data.brands.length > 0) {
          this.tableData = response.data.brands;
          this.count = response.data.count;
        }
      } else {
        this.dataTableLoading = false;
        // alert(response.data.error);
        alert("Something went wrong");
      }
    },
    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    downlaodRoles() {
      const fileName = 'brands.csv';
      const brands = BrandController.getBrands();
      this.downloadCSV(brands, fileName);
    },
  },
  created() {
    if (!this.$store.state.role.Brand.read) {
      this.$router.push("/notFound");
    }
    // Get search from URL if it exists
    const urlSearch = this.$route.query.search;
    if (urlSearch) {
      this.search = urlSearch;
    }
    // this.getBrand();
    this.$store.state.breadcrumb = "Brands";
  },
};
</script>
<style></style>