<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else> 
    <div class="d-flex flex-column pb-0 px-8 interview-main--container">
      <v-card
        height="64px"
        width="100%"
        class="background transparent header fixed elevation-0 d-flex flex-row justify-space-between align-center pt-2"
      >
        <v-card-title class="pl-0 ml-0">
          <p
            class="breadcrumb-text-unselected underline-on-hover cursor"
            @click="$router.push('/campaign')"
          >
            Campaigns
          </p>
          <v-icon
            class="breadcrumb-arrow-unselected"
            style="margin-top: 6px !important"
            >mdi-chevron-right</v-icon
          >

          <p
            class="breadcrumb-text-unselected underline-on-hover cursor"
            @click="backToCampaignUserListingPage"
          >
            {{ campaignName }}
          </p>
          <v-icon
            class="breadcrumb-arrow-unselected"
            style="margin-top: 6px !important"
            >mdi-chevron-right</v-icon
          >
          <p class="breadcrumb-text" style="margin-top: 2px">
            Schedule Interview
          </p>
          <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important"
            >mdi-chevron-down</v-icon
          >
        </v-card-title>
        <div
          class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
          style="position: fixed; right: 0"
        >
          <v-badge
            color="red"
            dot
            right
            transition="slide-x-transition"
            class="mr-8"
          >
            <v-hover>
              <img
                class="cursor"
                width="24px"
                src="../assets/bell 1.svg"
              />
            </v-hover>
          </v-badge>

          <div>
            <img
              src="../assets/avtar.png"
              class="rounded-xl mr-2"
              width="32"
              height="32"
            />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="40px"
                height="40px"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>
      <section class="d-flex flex-column flex-1">
        <p class="text-h6">Schedule Interview</p>

        <v-card class="mb-4">
          <div class="schedule-card--container rounded-lg pa-4 d-flex align-center">
            <div id="first-box" class="d-flex align-center flex-1">
              <div>
                <v-img
                  contain
                  src="../assets/avtar.png"
                  height="48px"
                  width="48px">
                </v-img>
              </div>
              <div class="mr-auto">
                <p class="ml-4 text-subtitle-2" v-if="this.userData.personalInfo.firstName">
                  {{ userData.personalInfo?.firstName + " " + this.userData.personalInfo.lastName }}
                </p>
                <p class="ml-4 text-subtitle-2" v-if="userData.personalInfo.contactInfo.email">
                  {{ userData.personalInfo.contactInfo.email }}
                </p>
              </div>
              <v-divider vertical></v-divider>
            </div>
            <div class="second-box d-flex align-center flex-1">              
              <div id="level-subject" class="w-100 pr-4">                  
                <div class="d-flex subject-level--container preferred-level--container">
                  <span class="interview-text--label">
                    Preferred Level
                  </span>
                  <span> - </span>
                  <p role-type="subtitle-3">
                    {{this.userData.teachingInterests?.levels?.join(", ")}}
                  </p>
                </div>
                <div class="d-flex subject-level--container">
                  <span class="interview-text--label">
                    Preferred Subjects
                  </span>
                  <span> - </span>
                  <p role-type="subtitle-3" class="interview-text--value">
                    {{ this.userData.teachingInterests?.subjects?.join(", ")}}
                  </p>
                </div>
              </div>
              <v-divider vertical></v-divider>
            </div>           
            <div class="second-box d-flex justify-space-between flex-1">
              <div id="role-localtion">
                <div class="d-flex align-center job-role--container">
                  <span class="interview-text--label interview-job--location">
                    Preferred Job role
                  </span>
                  <span> - </span>                
                  <p role-type="subtitle-3" class="interview-text--value">
                    {{
                      this.userData.teachingInterests?.jobRole?.HRIS_unique_role
                    }}
                  </p>
                </div>
                <div class="d-flex align-center job-role--container">
                  <span class="interview-text--label interview-job--location">
                    Preferred Location 
                  </span>
                  <span>-</span>
                  <p role-type="subtitle-3" class="interview-text--value">
                    {{ this.userData.teachingInterests.schools }}
                  </p>
                </div>
                </div>
              </div>
            </div>            
        </v-card>
        <v-card class="rounded-lg mb-4"  v-if="showStep1 && showSchool">
          <div class="pa-4 d-flex align-center">
            <p class="font-14">School</p>
            <span class="mx-4">-</span>
            <p role-type="subtitle-4"> {{ this.userData.teachingInterests.schools }} </p>
          </div>
        </v-card>

       
        <div class="side-container my-3" v-if="showStep2" >
            <div class="d-flex align-center">
              <span class="round-text">
                Round {{ this.roundToggleIndex+1 }} Details
              </span>
              <div class="w-100 toggle-btn--container" v-if="showToggleBtn">              
                <v-btn-toggle
                  v-model="roundToggleIndex"
                  mandatory
                  dense
                  rounded
                  @change="updateRoundFeedback"
                >
                  <v-btn
                  v-for="(round, index) in rounds"
                  class="skill-btn"
                  :class="
                  roundToggleIndex == index
                  ? 'gray-05 blue--text caption white'
                  : 'caption white'
                  " :key="round"
                  >
                  Round {{ round }} 
                  </v-btn>
                </v-btn-toggle>
                </div>
              </div>        
    
            <v-row class="mt-2">
                <v-col cols="4" class="pl-0 pr-4" v-for="data in currentRoundFeedback">
                    <div class="rounded-xl elevation-3 pa-4 current-round--card cursror-pointer	"  :class="data.bgClass" @click="showInterviewReport">
                      <div>
                          <p class="interview-user--email mb-2">{{data.interviewerEmail}}</p>
                          <div class="d-flex interview-details">                          
                            <span class="interview-card--label">Score</span>
                            <span> - </span>
                            <span>{{ data.avgScore }}</span>
                          </div>
                          <div class="d-flex interview-details">
                            <span class="interview-card--label">Recommendation</span>
                            <span> - </span>
                            <span>{{ data.recommendation  }}</span>
                          </div>
               
                          <div class="d-flex interview-details">
                            <span class="interview-card--label">Comment</span>
                            <span> - </span>
                            <v-tooltip bottom >
                              <template v-slot:activator="{ on, attrs }">
                                <span class="interview-round--comment flex-1 " v-bind="attrs" v-on="on" >{{data.remark}}</span>
                              </template>
                              <span>{{data.remark}}</span>
                          </v-tooltip>
                           
                          </div>
                      </div>
                    </div>
                </v-col> 
            </v-row>

            <v-divider class="my-4"></v-divider>            

            <v-row>
             <v-col cols="5">
                <div class="my-card pa-4 rounded-lg" >
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-space-between round-card">
                            <v-radio-group
                            :disabled="!disableActionSchedule"
                            class="ma-0 pa-0"
                            v-model="userStatusCheckbox"
                            @change="handleStatusChange"
                            row
                            >
                            <div class="d-flex flex-wrap gap-4">
                               
                              <v-radio    
                                label="Reject Candidate"
                                value="REJECT"
                              ></v-radio>
                              <v-radio
                                label="On Hold Candidate"
                                value="ONHOLD"
                              ></v-radio>
                            </div>

                            <div>
                              <div class="text-center my-2 d-block">OR</div>
                              <div>
                                <v-radio
                                  label="Send To Hiring Manager For Decision"
                                  value="SEND_TO_HIRING_MANAGER"
                                ></v-radio>
                              </div>
                            </div>
                            </v-radio-group>

                            <v-btn
                            width="125px"
                            height="48px"
                            class="btn text-btn"
                            :disabled="!disableActionSchedule"
                            @click="clearFinalInput"
                            >Clear</v-btn
                          >
                        </div>
                        <v-row>
              <v-col>
                <v-form ref="finalForm">
                  <p class="text-body-2">Remark*</p>
                  <ckeditor :config="editorConfig" v-model="interviewRemark" class="m-ckeditor" 
                 @ready="onEditorReady" :readOnly="!disableActionSchedule">
                </ckeditor>
              
                <v-autocomplete
                outlined
                clearable
                flat
                solo
                label="Hiring Manager : Chairman*"
                :items="[hiringManagerDetails]"
                v-model="selectedManager"  
                return-object
                class="mt-1 rounded-xl ma-2 mt-4"
                required>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ `${item.hiringManagerEmail} (${item.hiringManagerName})` }}
                        </v-list-item-title>
                      </v-list-item-content> 
                    </template>
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  {{ `${item.hiringManagerEmail} (${item.hiringManagerName})` }}
                </template>
              </v-autocomplete>
                </v-form>
              </v-col>
            </v-row>
                    </div>
                  </div>
             </v-col>
             <v-col cols="2" class="d-flex justify-center align-center"><div >OR</div></v-col>
             <v-col cols="5">
              <div class="d-flex align-center h-100">
                <div class="pa-4 d-flex flex-column my-card rounded-lg">
                  <p class="font-16 mb-3">Schedule</p>
                  <p role-type="body-5" class="mb-6"> Schedule a new interview by adding the interviewer's email ID and selecting available time slots.</p>
                  <div class="d-flex justify-center">
                    <v-btn                
                      width="225px"                
                      height="48px"
                      :loading="loading"
                      :disabled="newRoundSchedule || disableActionSchedule"
                      rounded
                      @click="ScheduleNewInterViewRound"
                      class="btn primary-btn"
                      >Schedule </v-btn
                    >
                  </div>
                </div>
              </div>
             </v-col>
                
            </v-row>
         
           <v-divider class="mt-7"></v-divider> 
        </div>
         <v-container fluid v-if="showStep1">
          <v-row>
            <v-col cols="6" class="pa-0">
              <div class="side-container">
                <div id="right-container" class="d-flex flex-column">
                  <p role-type="body-3" class="font-weight-bold mb-5">{{
              this.userData.isResuduleFun ? "RESCHEDULE" : "SCHEDULE"
            }}</p>
                  
                  <div>
                    <p role-type="subtitle-3" class="mb-3">Select Date</p>
                    <v-radio-group v-model="interviewMode" row class="h-50px">
                      <v-radio
                      key="Offline"
                      :label="`Offline`"
                      value="Offline"
                    ></v-radio>
                    <v-radio
                      key="Online"
                      :label="`Online`"
                      value="Online"
                    ></v-radio>
                  </v-radio-group>
                    <div v-if="interviewMode == 'Offline'" class="mb-4">
                      <p role-type="subtitle-6" >Address</p>
                       <p class="mt-1" role-type="body-4-gray">{{ schoolAddress }}</p>
                    </div>
                    <v-text-field
                    v-else
                    class="rounded-xl pr-0 no-details h-50px mb-4"
                    outlined
                    placeholder="Interview Link"
                    v-model="onlineInterviewLink"

                  ></v-text-field>
                  <p role-type="subtitle-6"  class="mb-4" v-if="interviewMode == 'Online'">Paste the link for the interview</p>
                  
                    <v-btn-toggle class="interview-date--toggle d-flex align-center w-100"
                      v-model="dateToggle"
                      @change="findTimeSlots(dateToggle)"
                    >
                      <v-btn
                        v-for="(data, index) in this.slotsData"
                        :key="data.slotDate"
                        class="flex-1"
                        :class="
                          currentSlotId === data.slotDate
                            ? 'gray-02 blue--text outline--blue'
                            : ''
                        "
                      >
                        <div>
                          <p role-type="subtitle-6" class="py-1">
                            {{ data.weekday }}
                          </p>
                          <p class="text-body-1">{{ data.date }}</p>
                        </div>
                      </v-btn>
                    </v-btn-toggle>
                  </div>

                  <div>
                    <p class="text-body-2 mb-3">Select Slot</p>
                    <div class="w-100 d-flex flex-row mb-8">
                      <v-item-group class="d-flex flex-row w-100 select-slot--container" mandatory>
                        <v-item
                          v-for="data in this.currentTimeSlots"
                          class="w-33"
                          v-slot="{ toggle }"
                          :key="data.index"
                        >
                          <v-card
                            width="33%"
                            class="d-flex flex-column rounded-r-0 justify-center align-center pt schedule-card"
                          >
                            <v-card
                              @click="setCurrentTimeSlotIndex(data.index)"                              
                              :class="
                                currentTimeSlotIndex === data.index
                                  ? 'SI-time-card-active'
                                  : 'SI-time-card'
                              "
                              class="w-100 h-100 rounded-lg text-center align-center d-flex justify-center py-2"
                            >
                              {{ data.slotStart }} - {{ data.slotEnd }}
                            </v-card>
                          </v-card>
                        </v-item>
                      </v-item-group>
                    </div>
                    <p class="text-body-2 mb-3">Interviewer's email ID</p>
                    <div outlined class="rounded-xl outlined d-flex tag-compo" :class="getBorderColor()">
                      <v-chip
                        v-for="(tag, index) in emailList"
                        :key="index"
                        :close="true"
                        
                        @click:close="removeTag(index)"
                        class="ma-2 blue--text email-chip n-overflow-hidden"
                      >
                        <div
                          v-if="!tag.isSlotAvalable"
                          class="red--text fit-content h-fit-content ma-4"
                        >
                          <v-icon>mdi-file-account-outline</v-icon>
                          <span class="tooltip"
                            >{{ tag.email
                            }}<span class="tooltiptext"
                              >Already three interviews are scheduled for this
                              user on given date</span
                            ></span
                          >
                        </div>
                        <div v-if="tag.isSlotAvalable">{{ tag.email }}</div>
                      </v-chip>
                      <input
                        class="ma-4 tag-input"
                        :disabled="
                          this.emailList.length >= 3 
                        "
                        :placeholder="this.emailList.length === 0 ? `Interviewer's email ID` : ''"
                        @keydown="handleKeyDown($event)"
                        @paste="handlePaste($event)"
                        @focus="isFocused = true"
                        @blur="isFocused = false"
                        v-model="tag"
                      />
                    </div>
                    <div v-if="isInvalidEmailDomain" class="invalid-message">
                      {{ "Please enter a valid interviewer’s email ID" }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="pt-0 pr-0">
              <v-form ref="form" class="overflow-hidden" lazy-validation>
                <div class="side-container">
                  <p role-type="body-3" class="font-weight-bold mb-5">Candidate Details</p>
                <v-row
                  ><v-col>
                    <v-text-field
                      :rules="[
                        (v) => v >= 0 || 'Only a positive value is allowed',
                      ]"
                      outlined
                      label="Total Experience (in Years)"
                      type="number"
                      class="rounded-xl"
                      min="0"
                      v-model="candidateDetails.totalExperience.years"                      
                      hide-details
                      required
                    ></v-text-field>
                  </v-col
                  ><v-col>
                    <v-text-field
                      outlined
                      label="Total Experience (in Months)"
                      class="rounded-xl"
                      :rules="[
                        (v) => v >= 0 || 'Only a positive value is allowed',
                      ]"
                      v-model="candidateDetails.totalExperience.months"
                      min="0"
                      max="11"
                      type="number"                      
                      text
                      hide-details
                    ></v-text-field></v-col
                ></v-row>
                <v-row
                  ><v-col>
                    <v-text-field
                      outlined
                      label="Relevant Experience (in Years)"
                      class="rounded-xl"
                      :rules="[
                        (v) => v >= 0 || 'Only a positive value is allowed',
                        this.checkExperience() ||
                          'Relevant experience cannot be more than the Total Experience',
                      ]"
                      v-model="candidateDetails.relevantExperience.years"
                      min="0"                      
                      text
                      type="number"
                      hide-details
                    ></v-text-field></v-col
                  ><v-col>
                    <v-text-field
                      :rules="[
                        (v) => v >= 0 || 'Only a positive value is allowed',
                        this.checkExperience() ||
                          'Relevant experience cannot be more than the Total Experience',
                      ]"
                      outlined
                      label="Relevant Experience (in Months)"
                      class="rounded-xl"
                      v-model="candidateDetails.relevantExperience.months"
                      min="0"
                      max="11"
                      text
                      type="number"
                      hide-details
                    ></v-text-field> </v-col
                ></v-row>
                <v-row
                  ><v-col>
                    <v-text-field
                      outlined
                      class="rounded-xl pt-1"
                      v-model="candidateDetails.currentCTC"
                      label="Current CTC (Annual CTC in Lakhs)"
                      text
                      min="0"
                      :rules="[
                        (v) => v >= 0 || 'Only a positive value is allowed',
                      ]"
                      type="number"
                      hide-details
                    ></v-text-field></v-col
                  ><v-col
                    ><v-text-field
                      :rules="[
                        (v) => v >= 0 || 'Only a positive value is allowed',
                      ]"
                      outlined
                      label="Expected CTC (CTC in Lakhs)"
                      class="rounded-xl pt-1"
                      v-model="candidateDetails.expectedCTC"                      
                      text
                      min="0"
                      type="number"
                      hide-details
                    ></v-text-field> </v-col
                ></v-row>
                <v-row
                  ><v-col>
                    <v-text-field
                      :rules="[
                        (v) => v >= 0 || 'Only a positive value is allowed',
                      ]"
                      outlined
                      label="Notice Period (in days)"
                      class="rounded-xl pt-1"
                      v-model="candidateDetails.noticePeriod"                      
                      min="0"
                      type="number"
                      text
                      hide-details
                    ></v-text-field></v-col
                  ><v-col></v-col
                ></v-row>
              </div>
              </v-form>
              
            </v-col>
          </v-row>
        </v-container>
      </section>
      <div>
        <div
          width="100%"
          class="d-flex sticky-bottom mt-4 flex-row justify-end py-2"
        >
          <v-btn
            width="125px"
            height="48px"
            rounded
            outlined
            class="btn outline-btn mr-4 pa-4"
            @click="goBack"
            >Cancel</v-btn
          >
          <v-btn
            v-if="showStep1"
            width="125px"
            :disabled="
              this.emails?.length == 0 ||
              userData.teachingInterests.schools == undefined 
            "
            height="48px"
            :loading="loading"
            rounded
            @click="saveInputs"
            class="btn tertiary-btn pa-4 mr-2"
            >{{
             emailAnddateChange
            }}</v-btn
          >
          <v-btn
            v-if="showStep2 && !showStep1"
            width="125px"
            height="48px"
            :loading="finalSubmitProgress"
            rounded
            :disabled="canFinalSubmit"
            @click="candidateInterviewAction"
            class="btn tertiary-btn pa-4 mr-2"
            >Submit</v-btn
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="showSuccessDialog">
      <v-card class="interview-schedule--popup pa-6">
        <div class="d-flex align-center flex-column">
            <img              
            class="cursor"
            width="96px"
            height="96px"
            src="../assets/success.svg"
          />
          <p class="interview-popup--text">Interview  {{ (round > 0 && userData.isReshedule) ? `Round ${round}`:'' }}  {{userData.isResuduleFun ? 'Rescheduled': 'Scheduled'}} </p>
          <p><v-btn class="btn primary-btn" @click="goBack">ok</v-btn></p>
        </div>    
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CampaignController from "@/controllers/CampaignController";
import AuthService from "../services/AuthService";
import { getDownloadURL, uploadBytes, ref as storageRef } from "firebase/storage";
import { db, storage } from "../firebase";
import UsersController from "@/controllers/UsersController";
export default {
  name: "scheduleInterviewRounds",
  computed: {
    canFinalSubmit(){
      if(this.userStatusCheckbox === "SEND_TO_HIRING_MANAGER") {
        return !(this.interviewRemark !== "" && this.selectedManager?.hiringManagerEmail);
      }
      else {
        return !(this.interviewRemark !== "" && this.userStatusCheckbox !== "");
      }
    },
    newRoundSchedule() {
      return this.userData?.interviewFinalStatus?.status === "NOTIFY_HR" && Number(this.userData?.interviewFinalStatus?.round) === this?.userData?.round;
    },
    emailAnddateChange(){
      this.hasEmailChanged = this.oldEmails !== this.emails.sort().join('') 
      this.hasDateTimeChanged = this.oldSlotId+this.oldSlotIndex !== this.currentSlotId+this.currentTimeSlotIndex;
      let res = 'Schedule'
  
      if(this.userData?.isResuduleFun){
        if(this.hasEmailChanged && this.hasDateTimeChanged){
          res =  'Reschedule'
          }else if(this.hasEmailChanged && !this.hasDateTimeChanged){
          res =  'Reschedule' //Modify
          } else if(!this.hasEmailChanged && this.hasDateTimeChanged){
            res =  'Reschedule'
          }else {
            res = 'Reschedule'
          }

      }

      return res
        
      
 
    }
  },
  watch: {
    tag(newValue) {
      if (newValue.indexOf(",") != -1) {
        var temp = newValue.split(",")[0];
        if (temp != "") {
          // var value ={
          //   email : temp,
          //   isSlotAvalable : true
          // }
          // this.emailList.push(value);
        }
        this.tag = "";
      }
      if(newValue == "") this.isInvalidEmailDomain = false;
      console.log("index value", newValue.indexOf(","));
      console.log("outside", this.tag);
    },
  },
  data() {
    return {
      emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid'
            ], 
      emailBool: false,
      approverEmail:'',
      schoolName: "",
      interviewRemark:"",
      showSuccessDialog: false,
      loading: false,
      emailList: [],
      preloader: false,
      campaignName: "",
      dateToggle: 0,
      currentTimeSlotIndex: 0,
      slotsData: [],
      currentTimeSlots: [],
      currentSlotId: "",
      id: "",
      tag: "",
      round:1,
      isResheduled: false,
      colColors: ["", "", ""],
      emails: [],
      editorConfig: {
        readOnly: false,
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'Link', 
           'NumberedList', 'BulletedList', 'Undo', 'RemoveFormat']
        ],
        removeButtons: '',
        toolbarStartupExpanded: true,
        removePlugins: 'elementspath,resize'
      },
      userStatusCheckbox: '',
      interviewerNotificationType: '',
      emailFileBlob: {},
      emailFileName: '',
      candidateDetails: {
        totalExperience: {
          years: 0,
          months: 0,
        },
        relevantExperience: {
          years: 0,
          months: 0,
        },
        noticePeriod: 0,
        currentCTC: 0.0,
        expectedCTC: 0.0,
      },
      userData:{},
      id:'',
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      isEditorValid: true,
      editorError:'',
      preloader: true,
      finalSubmitProgress: false,
      emailfileUrl:'',
      showStep1:false,
      showStep2:false,
      showSchool: true,
      rounds:[],
      roundToggleIndex:0,
      latestFeedbackRound:1, 
      currentRoundFeedback:[],
      rescheduleInterviewPayload:{},
      imagePreview:'',
      userId: "",
      campaignUserData:{},
      showToggleBtn: false,
      maxFeedbackRound: 0,
      oldSlotIndex: 0,
      oldSlotId:"",
      oldEmails: '',
      hasDateTimeChanged:false,
      hasEmailChanged:false,
      currentRoundInterviewersCount:0,
      latestRoundFeedbackCount:0,
      disableActionSchedule: true,
      hiringManagerDetails: {},
      selectedManager: {},
      interviewMode:'Offline',
      onlineInterviewLink:'',
      schoolAddress: '',
      schoolCode: '',
      isInvalidEmailDomain: false,
      isFocused: false
    };
  },
  async created() {
    this.id = this.$route.query.id 
    this.userId = this.$route.query.userId
    this.campaignName = this.$route.query.campaignName;
    await this.fetchCampaignAudience()
    await this.scheduleInterview(this.campaignUserData)
    this.getAvalableSlots();
    this.isReshedule();
   
    if(this.userData?.interViewFeedback?.length > 0){
      this.getColColors()
    }
    this.actionScheduleEligblity()
    this.getHiringManagerEmail();
    this.preloader = false;
  },
  methods: {
    actionScheduleEligblity(){
    if(this.userData?.interviewFinalStatus?.status === "NOTIFY_HR") {
      this.disableActionSchedule = false;
      const interViewFeedback = this.userData.interViewFeedback;
      const currentRound = this.userData.round;
      const interviewEmails = this.userData.interviewDetails.interviewSlotDetails[currentRound-1].interviewerEmails;
      const requiredRound = Number(this.userData.interviewFinalStatus.round);
      if(currentRound === requiredRound) {
        const currentRoundFeebacks = interViewFeedback.filter((interViewFeedback) => 
          Number(interViewFeedback.round) === currentRound
        )
        const currentInterViewEmails = currentRoundFeebacks.map((interViewFeedback) =>{
          interViewFeedback.interviewerEmail
        })
        const uniqueInterviewEmails = [...new Set(currentInterViewEmails)];
        this.disableActionSchedule = interviewEmails.length >=3 ? uniqueInterviewEmails.length >= 2 : interviewEmails.length === uniqueInterviewEmails.length;
      }
    }
    },
    handlePaste(event) {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const emails = pastedText.split(',').map(email => email.trim());
    emails.forEach(email => {
      if (this.emailList.length < 3) {
        this.tag = email;
        this.addTag();
      }
    });
  },
    handleKeyDown(event){
        if(event.key == 'Enter' || event.key == ','){
          console.log("key")
          this.addTag()
          event.preventDefault()
            return
        }
      
    },
    getBorderColor(){
      if(this.isInvalidEmailDomain) return 'input-invalid-border';
      if(this.isFocused) return 'input-focus'; 
      else return 'tag-compo';
    },
    isValidEmailDomain(email) {
      if(email == "") return true;
      const allowedDomains = ["vgos.org", "hubblehox.com", "ampersandgroup.in"];
      const emailRegex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
      const match = email.match(emailRegex);
      return match && allowedDomains.includes(match[1]);
    },
    showInterviewReport(){
      this.$router.push({path: `/interviewReport/${this.id}/${this.userId}`, query: {round: this.latestFeedbackRound-1}});
    },
    goBack(){
      let query = this.$route.query;
      this.$router.push({ 
        path: '/campaignUser', 
        query 
      });
    },
    updateRoundFeedback(){
      this.latestFeedbackRound = this.roundToggleIndex+1 
      this.currentRoundFeedback = this.campaignUserData.interViewFeedback.filter(({round})=> round == this.latestFeedbackRound)
    
    },
    handleStatusChange() {
      if(this.userStatusCheckbox !== ''){
        this.showStep1 = false;
      }
    },  
   
    validateEditor() {
    
      const strippedContent = this.interviewRemark.replace(/<[^>]*>/g, '').trim();
      const wordCount = strippedContent.split(/\s+/).length;
        
            if (!strippedContent) {
              this.editorError = 'Editor content cannot be empty';
              this.isEditorValid = false;
            } else if (wordCount < 10) {
              this.editorError = 'Content must be at least 10 words';
              this.isEditorValid = false;
            } else if (strippedContent.length > 1000) {
              this.editorError = 'Content is too long (maximum 1000 characters)';
              this.isEditorValid = false;
            } else {
              this.editorError = '';
              this.isEditorValid = true;
            }
            console.log('is editor valid', this.isEditorValid)
            return this.isEditorValid;
    },
    async candidateInterviewAction(){
    this.finalSubmitProgress = true
    let interviewFinalStatus = {}
    try {
      interviewFinalStatus['remark'] = this.interviewRemark
      interviewFinalStatus['status'] = this.userStatusCheckbox
      interviewFinalStatus["hiringManagerDetails"] = this.selectedManager
      interviewFinalStatus["actionTakenBy"] = "HR"
      const response = await CampaignController.submitInterviewFinalStatus({interviewFinalStatus, userId: this.campaignUserData.userId,   campaignId:this.id    })
      if(response.status === 200) {
        this.finalSubmitProgress = false; 
        this.showSuccessDialog = true
      }
      else {
        this.finalSubmitProgress = false;
        console.error(response.data.message)
        alert(response.data.message);
      }

    } catch (error) {
      this.finalSubmitProgress = false;
      console.error(error)
      alert(error);
    }
    },
    async scheduleInterview(userData) {
      try {
  
        const responce = await CampaignController.getAvalableSlots(this.id);
        if (responce.data.flag) {
          var slotsData = responce?.data?.data;
          if (slotsData?.length == 0 ) {
            alert("Slots are not available for this campaign now");
          } else {
            this.currentUserDataForScheduleInterview = userData;
            this.currentUserDataForScheduleInterview.slotsData = slotsData;
            this.currentUserDataForScheduleInterview.campaignId = this.id;
            this.scheduleInterviewDialog = true;
            this.currentUserDataForScheduleInterview.disableReschedule = false;
            if (userData.interviewDetails !== undefined) {
              this.currentUserDataForScheduleInterview.isResuduleFun = true;
              if(userData?.interViewFeedback !== undefined)
                this.currentUserDataForScheduleInterview.disableReschedule = true;

            } else {
              this.currentUserDataForScheduleInterview.isResuduleFun = false;
            }
            this.userData = this.currentUserDataForScheduleInterview;
          }
        }
      } catch (error) {
        alert('eror at line 743', error);
      }
    },
    ScheduleNewInterViewRound(){
      this.userData.isResuduleFun = false;
      this.round++
      this.showStep1 = true;
      this.showStep2 = true
      this.showSchool = false; 
      this.userData.disableReschedule = false
      this.currentSlotId = "",
      this.currentTimeSlotIndex = ""
      this.emailList = []
      this.emails = []
     
    
    },
    async checkSlotCall(email){
      const res =
          await CampaignController.checkAvalableSlotsForInterviewer(
            email,
            this.currentSlotId,
            this.currentTimeSlotIndex.toString()
          );
        return {  email,
          isSlotAvalable: res?.data?.flag,}
    }, 
    async addTag() {
      if(!this.tag) return;
      if(this.isValidEmailDomain(this.tag)){
        this.emails.push(this.tag)
        this.emails = [...new Set(this.emails)]
        this.isInvalidEmailDomain =  false;
      }
      else{
        this.isInvalidEmailDomain = true;
      }
      if (this.emails.length > 0  && this.currentSlotId && this.currentTimeSlotIndex.toString()){
        this.emailList = await Promise.all(this.emails.map(async email => await this.checkSlotCall(email)))
         this.tag = ''
      }
    },
    onEditorReady(editor) {

      
      editor.on("afterCommandExec", function(event) {
        var commandName = event.data.name;
        if(['ckeditor_wiris_openFormulaEditor', 'ckeditor_wiris_openFormulaEditorChemistry'].includes(commandName) ) {
          setTimeout(() => {
              const wiris_license_overlays = document.querySelectorAll('.wrs_tickContainer')
              wiris_license_overlays.forEach(element => {
                element.remove();
              });
            }, 2000)
        }
      })
    },
    handleFileUpload(event){
      const file = event.target.files[0];
      const maxSize = 512 * 1024;
      const validTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/webp'];
      if(file){
        if (!validTypes.includes(file.type)) {
          alert('Please upload only SVG, PNG, JPG or WEBP files');
          event.target.value = ''; // Clear the input
          return;
        }

        // Check file size
        if (file.size > maxSize) {
          alert('File size should not exceed 512KB');
          event.target.value = ''; // Clear the input
          return;
        }
        this.createImagePreview(file)
        this.emailFileName = file.name;
        this.emailFileBlob = new Blob([file], {type: file.type});

      }  
    },
    clearImageFile(){
      this.emailFileName = "", 
      this.emailFileBlob = {}

    },
    async uploadEmailFileInFirebase(){
        if(!this.isResumeUplaoded){
          this.isResumeUploading = true;
          const resumeRef = storageRef(storage, `email_approval/${this.emailFileName}`);
          const uploadTask = uploadBytes(resumeRef,   this.emailFileBlob);
          await Promise.all([uploadTask]);
          return  await getDownloadURL(resumeRef);
                          
        }
    },
    clearFinalInput(){  
      this.userStatusCheckbox = ""
      this.interviewRemark = ""
      this.emailFileBlob = {}
      this.approverEmail = ""
      this.emailFileName = ""
    
    },
    createImagePreview(file){
      const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
    },
    async getColColors() {
        this.userData.interViewFeedback = this.userData.interViewFeedback.map((item, index)=>{
            if(["Not Recommended", "Below Standard"].includes(item.recommendation)){
              item['bgClass'] =   'rejected-bg'
                // this.textColors[index] = 'rejected-txt'
            }else if( item.recommendation == "Moderately Recommended"){
              item['bgClass'] =   'hold-bg'
                // this.textColors[index] = 'hold-txt'
            } else if(["Highly Recommended","Strongly Recommended" ].includes(item.recommendation)) {
              item['bgClass'] =   'recommend-bg'

                // this.textColors[index] = 'recommended-txt'
            }
            return item
        })
        await this.$nextTick()
    },
    showConfirmationDialog(){
      if(this.userData.isResuduleFun){
        // this.updateInterviewerDialog = true
      }else{
        this.showSuccessDialog = true
      }
    },
   async rescheduleInterviewAfterConsent(){
      try {
          const responce =
            await CampaignController.submitInterviewScheduleDetails(this.rescheduleInterviewPayload);
          if (responce.data.flag) { 
            await this.scheduleInterview(this.campaignUserData)
            this.showSuccessDialog = true
          } else {
            alert(responce.data.message);
          }
        } catch (error) {
          alert(error);
        }
    },
    async saveInputs() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.userData.isResuduleFun) {
        let data = {
          
          schoolName: this.userData.teachingInterests.schools,
          interviewerEmails: this.emails,
          slotId: this.currentSlotId,
          slotIndex: this.currentTimeSlotIndex.toString(),
          campaignId: this.userData.campaignId,
          userId: this.userData.userId,
          candidateDetails: this.candidateDetails,
          isReshedule: this.hasDateTimeChanged,
          oldSlotId: this.oldSlotId.toString(),
          oldSlotIndex: this.oldSlotIndex.toString(),
          round: this.round,
          scheduledByName: this.$store.state.userInfo.name,
          scheduledByEmail: this.$store.state.userInfo.email,
          interviewMode: this.interviewMode,
          interviewLocation: this.interviewMode == 'Offline' ? this.schoolAddress : this.onlineInterviewLink
        };
         this.rescheduleInterviewPayload = data
         console.log(this.rescheduleInterviewPayload)
         this.rescheduleInterviewAfterConsent()
         this.showSuccessDialog = true
      } else {
         let data = {
          schoolName: this.userData.teachingInterests.schools,
          interviewerEmails: this.emails,
          slotId: this.currentSlotId,
          slotIndex: this.currentTimeSlotIndex.toString(),
          campaignId: this.userData.campaignId,
          userId: this.userData.userId,
          candidateDetails: this.candidateDetails,
          isReshedule: false,
          round: this.round,
          scheduledByName: this.$store.state.userInfo.name,
          scheduledByEmail: this.$store.state.userInfo.email,
          interviewMode: this.interviewMode,
          interviewLocation: this.interviewMode == 'Offline' ? this.schoolAddress : this.onlineInterviewLink
        };

        console.log(data)
        try {
            const responce =
              await CampaignController.submitInterviewScheduleDetails(data);
          if (responce.data.flag) {
            await this.scheduleInterview(this.campaignUserData)
            this.showSuccessDialog = true
          } else {
            alert(responce.data.message);
          }
        } catch (error) {
          alert(error);
        }
      }
    },
    removeTag(index) {
      this.emailList.splice(index, 1);
       this.emails.splice(index, 1);
       console.log(this.emailList, this.emails)
    },
    checkExperience() {
      let relevantExperience =
        Number(this.candidateDetails.relevantExperience.months) +
        Number(this.candidateDetails.relevantExperience.years) * 12;
      let totalExperience =
        Number(this.candidateDetails.totalExperience.months) +
        Number(this.candidateDetails.totalExperience.years) * 12;
      return totalExperience >= relevantExperience;
    },
    async isReshedule() {
      if (this.userData.isResuduleFun) {
        (this.schoolName =
          this.userData.interviewDetails.interviewSlotDetails[this.round-1]?.schoolName),
          (this.candidateDetails =
            this.userData.interviewDetails.candidateDetails),
          (this.emails =
            this.userData.interviewDetails.interviewSlotDetails[this.round-1]?.interviewerEmails),
            this.oldEmails = this.emails.sort().join('')
          this.userData.interviewDetails.interviewSlotDetails[this.round-1]?.interviewerEmails.forEach(
            (email) => {
             
              this.emailList.push({
                email: email,
                isSlotAvalable: true,
              });
            }
          );
        (this.currentSlotId =
          this.userData.interviewDetails.interviewSlotDetails[this.round-1]?.slotDate),
          (this.currentTimeSlotIndex = parseInt(
            this.userData.interviewDetails.interviewSlotDetails[this.round-1]?.timeSlotDetails
              .index
          )),
          (this.oldSlotId =
            this.userData.interviewDetails.interviewSlotDetails[this.round-1]?.slotDate),
          (this.oldSlotIndex =
            this.userData.interviewDetails.interviewSlotDetails[this.round-1]?.timeSlotDetails.index);
      }
    },
    showStep(){
      if(this.maxFeedbackRound == 0){
        // not received the atleast one interview feedback
        this.showStep1 = true;
        this.showStep2 = false
      } else  if(this.maxFeedbackRound  < this.round && this.maxFeedbackRound > 0){
        // not received feedback for the current round
        // disable the schedule btn
        this.showStep1 = true
       this.showStep2 = true
      } else  if(this.latestFeedbackRound  == this.round){
        // enable the schedule btn
        this.showStep1 = false
        this.showStep2 = true
       } 
   
   
    },
    async getHiringManagerEmail() {
      this.hiringManagerDetails = {}
      const data = {
        schoolCode: this.userData.teachingInterests.schoolCode,
        Job_Role: this.userData.teachingInterests.jobRole.hiringManagerJobRole,
      }
      const query = "?" + new URLSearchParams(data).toString();
      
      try {
        const response = await UsersController.getHiringManagerEmail(query);
        if (response.status === 200) {
            this.hiringManagerDetails = {
              hiringManagerName: response.data.fullName,
              hiringManagerEmail: response.data.email,
              hiringManagerDesignation: this.userData.teachingInterests.jobRole.hiringManagerJobRole,
            }
            // this.emailBool = true;
        }
        else {
          console.error('Error fetching hiring manager email:');
          this.hiringManagerDetails = {
            hiringManagerName: '',
            hiringManagerEmail: this?.userData?.interviewFinalStatus?.hiringManagerDetails?.hiringManagerEmail || '',
            hiringManagerDesignation: this.userData.teachingInterests.jobRole.hiringManagerJobRole,
          }
          // this.emailBool = this?.userData?.interviewFinalStatus?.hiringManagerDetails?.hiringManagerEmail ? true : false;
        }
        if(["NOTIFY_HR","SEND_TO_HIRING_MANAGER"].includes(this.userData?.interviewFinalStatus?.status)) {
          this.hiringManagerDetails = this.userData?.interviewFinalStatus?.hiringManagerDetails;
        }
      } catch (error) {
          console.error('Error fetching hiring manager email:', error);
      }
  },
    getAllRounds(){
      let data = this.campaignUserData.interViewFeedback.filter(({round})=> round !== undefined ).map(({round})=>round)
      let unq = new Set(data);
      this.rounds = [...unq].filter(item => item !== undefined)
     
      this.latestFeedbackRound = Math.max(...this.rounds)
      this.maxFeedbackRound = this.latestFeedbackRound
      if(this.maxFeedbackRound > 1){
        this.showToggleBtn = true
      }
      
      this.currentRoundFeedback = this.campaignUserData.interViewFeedback.filter(({round})=> round == this.maxFeedbackRound)
      this.roundToggleIndex = this.maxFeedbackRound-1

      this.currentRoundInterviewersCount = this.campaignUserData?.interviewDetails.interviewSlotDetails[this.round-1]?.interviewerEmails?.length || 0;
      this.latestRoundFeedbackCount = Math.max(...this.rounds) == this.round ? this.getCurrentRoundFeedbackCount(data): 0
      
    },
    getCurrentRoundFeedbackCount(data){
      return data.filter((round)=>round == this.round).length
    },
    async getSchoolAddress(){
      let query = '?schoolCode='+this.schoolCode;
      let res = await UsersController.getSchoolAddresss(query)
      try{
        if(res.data.flag){
          this.schoolAddress = res.data.schoolAddress;
        }
      } catch(error){
        console.log(error)
      }
    
    },
    async fetchCampaignAudience() { 
      this.dataTableLoading = true;
      let query = "?campaignId="+this.id+"&itemsPerPage="+1+"&page="+1+"&userId="+this.userId
      const audienceData = await CampaignController.getCampaignAudience(query, [], []);
      this.campaignUserData = audienceData.result.users[0]
      this.schoolCode = this.campaignUserData.teachingInterests.schoolCode
      this.getSchoolAddress()
      // data assignment
      // this.$store.dispatch('setCampaignUserData', audienceData.result.users[0])
      
      this.round = this.campaignUserData?.round || 1
      this.roundToggleIndex = this.round-1;
    
      if(this.campaignUserData?.interViewFeedback && this.campaignUserData?.interViewFeedback?.length > 0){

        this.getAllRounds()
      }else{
        this.latestFeedbackRound = 0
      }
      
      this.showStep()
      if(this?.campaignUserData?.hasOwnProperty('interviewFinalStatus')) {
        let finalStatus = this.campaignUserData.interviewFinalStatus
        if(["ONHOLD","REJECT","SEND_TO_HIRING_MANAGER"].includes(finalStatus.status)) {
        this.userStatusCheckbox = finalStatus.status;
        this.interviewRemark = finalStatus.remark
        }
      }
    
     
    },
    async downloadFile() {
      try {
        // Fetch the file
        const response = await fetch(this.emailfileUrl);
        const blob = await response.blob();

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = 'emailApproval'; // Set desired filename

        // Append to document, click, and remove
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Download failed:', error);
        // Handle error (show notification, etc.)
      }
    },
    findTimeSlots(index) {
      this.dateToggle = index;
      this.currentSlotId = this.slotsData[index].slotDate;
      this.currentTimeSlots = this.slotsData[index].subAllSlot;
      this.addTag()
    },
    async getAvalableSlots() {
      try {
        this.slotsData = this.userData?.slotsData;
        this.currentTimeSlots = this.slotsData[0]?.subAllSlot;
        this.currentSlotId = this.slotsData[0]?.slotDate;
        this.currentTimeSlotIndex = this.slotsData[0]?.subAllSlot[0]?.index;
      } catch (error) {
        // alert('alert at 994',);
        console.log(error)
      }
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    setCurrentTimeSlotIndex(index) {
      this.currentTimeSlotIndex = index;
      this.addTag()
    },
    backToCampaignUserListingPage() {
      let query = this.$route.query;
      delete query.userId;
      delete query.campaignName;
      this.$router.push({path:'/campaignUser', query});
    },
  },
 
};
</script>
<style>
.input-focus {
  border-color: #3f41d1 !important;
}
.input-invalid-border {
  border-color: #e6393e !important;
  border-radius: 0;
}
.invalid-message {
  color: #e6393e;
  font-size: 12px;
  line-height: 1.5;
  margin: 4px 0px 0px 8px;
  font-weight: 400;
}
.h-50px{
  height: 50px;
}
.side-container {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
}

.hold-bg{
    background-color: rgba(233, 182, 94, 0.24);
}
.recommend-bg{
    background-color: rgba(95, 159, 104, 0.24);
}
.rejected-bg {
    background-color: #F9DBE1;
}

.interview-remark{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Adjust the number of lines for your needs */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

.my-card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  min-height: 205px;
}

.dotted-border{
  border: 2px dashed var(--gray-04);
  padding: 20px;
  border-radius: 16px;
}

.interview-main--container {
  min-height: 100vh;
  .m-ckeditor {
    height: 115px;    
  }
}

.schedule-card--container {
  border: 1px solid #0000001F;
}

.schedule-card--container, .subject-level--container,
.job-role--container, .select-slot--container {
  gap: 20px;
}

.interview-text--label {
  font-size: 14px;
  width: 124px;
  white-space: nowrap;
  ~ p {
    font-size: 14px;
    font-weight: 600;
  }
}

.interview-text--value {
  max-width: 244px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.interview-job--location {
  width: 130px;
}

.interview-date--toggle {
  margin-bottom: 30px;
  .v-btn {
    height: 55px !important;
    background: var(--white) !important;
  }
}

.schedule-card {
  .SI-time-card {
    background: var(--white) !important;
    border: 1px solid #0000001F !important;
  }
}

.round-card {
  .v-messages {
    display: none;
  }
  .v-input__slot {
    display: block;
    margin: 0;
  }
  .text-btn {
    height: 30px !important;
  }
  .v-label {
    font-size: 16px !important;
    margin-left: 10px;
  }
}

.current-round--card {
  min-height: 150px;
}

.interview-round--comment {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.interview-card--label {    
  width: 116px;
}

.interview-details {
  gap: 20px;
  span {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }
}

.interview-user--email {
  font-size: 14px;
  font-weight: 600;
}

.interview-schedule--popup {
  max-width: 366px;
  margin: auto;
  .interview-popup--text {
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0 !important;
  }
  .v-radio {
    align-items: start;
  }
}

.v-dialog {
  box-shadow: none;
}

.header {
  position: fixed;
  z-index: 1;
}

section {
  padding-top: 40px;
}

.round-text {
  white-space: nowrap;
}

.toggle-btn--container {
  display: flex;
  justify-content: end;
  .v-btn {
    padding: 10px 24px !important; 
    color: var(--blue-01);
    font-weight: 600;
  }
  .v-btn-toggle {
    border: 1px solid var(--blue-01);
  }
}

.skill-btn {
  border-right-color: var(--blue-01) !important;
  &.v-item--active {
    font-weight: 600;
  }
}

.cursror-pointer{
  cursor: pointer;
}
</style>
