<template>
  <div>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">
      <v-card-title class="pl-0 ml-0">
        <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/masters')">
          Masters
        </p>
        <v-icon class="breadcrumb-arrow-unselected" style="margin-top: 6px !important">mdi-chevron-right</v-icon>
        <v-menu offset-y class="menuZindex">
          <template v-slot:activator="{ on }">
            <span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
              Subjects
              <v-icon class="breadcrumb-arrow cursor" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </span>
          </template>
          <MastersDropdownComponent />
        </v-menu>

        <!-- <p class="breadcrumb-text" style="margin-top: 2px">Subjects</p> -->
        <!-- <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon> -->
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center" style="position: fixed; right: 0;">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img class="cursor" @click="notificationDialog = true" src="../../assets/bell 1.svg">
          </v-hover>
        </v-badge>

        <div>

          <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">

        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="px-8 pad">

      <v-row>
        <v-col>

          <v-dialog max-width="887px" class="cdz" v-model="dialog" center>
            <v-form ref="form" lazy-validation>
              <v-card>
                <v-card-title class="gray-02 mb-8">{{ formbtn() }} Subject</v-card-title>
                <!---------------------- subject upload section starts here --------------------------->

                <v-list-item-title class="text-center mb-4">
                  Upload subject icon<sup>*</sup>
                </v-list-item-title>

                <div class="d-flex justify-center mb-6 bg-surface-variant">
                  <div class="box" @dragover.prevent @drop="onDrop" v-if="!image">
                    <div class="uploadrow">
                      <div class="uploadleft">
                        <v-icon class="uploadicon">mdi-tray-arrow-up</v-icon>
                      </div>
                      <div class="uploadright">
                        <div>
                          <p class="font-weight-light">Drag .png or .svg here</p>
                          <p class="font-weight-light">OR</p>
                          <div class="font-weight-light">
                            <div class="uplaodbtn">
                              Click here
                              <input type="file" name="image" @change="onChange" />
                            </div>
                            to browse(512 Kb max.)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div v-if="image">
                    <div class="imgback">
                      <img :src="image" alt="" width="100" height="auto" class="img" />
                      <div class="pencil">
                        <v-icon class="editpen">mdi-pencil-circle</v-icon>
                        <input class="editlogo" type="file" name="image" @change="onChange" />
                      </div>
                    </div>
                  </div>
                </div>

                <v-card-text class="px-6 pb-0">
                  <v-row no-gutters>
                    <v-col cols="6" sm="6">
                      <div class="pr-2">
                        <v-text-field outlined class="rounded-xl" v-model="subName" label="Subject Name*"
                          :rules="[v => !!v || 'Subject Name is required']" required></v-text-field>
                      </div>
                    </v-col>

                    <v-col cols="6" sm="6">
                      <div class="pl-2">
                        <v-select :items="subCategory" item-text="name" class="rounded-xl" label="Subject Category*"
                          v-model="subCat" item-value="name" outlined
                          :rules="[v => !!v || 'Subject Category is required']" required></v-select>
                      </div>
                    </v-col>
                  </v-row>

                  <!---------------------- subject upload section ends here --------------------------->
                </v-card-text>
                <v-card-actions class="px-6 pb-6">
                  <small>*All fields are mandatory</small>
                  <v-spacer></v-spacer>
                  <v-btn width="102px" height="48px" rounded outlined class="btn outline-btn pa-4"
                    @click="dialog = false">Cancel</v-btn>
                  <v-btn width="102px" height="48px" :disabled="selectedFile == null" rounded @click="uploadIcon"
                    class="btn primary-btn pa-4" :loading="loading">{{ formbtn() }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
          <v-dialog v-model="deleteDialog" class="cdz" max-width="366px" persistent>
            <v-card fluid>
              <v-container fluid class="pa-0">
                <v-card-text class="text-center">
                  <v-container></v-container>
                  <v-avatar color="background" size="90"><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar>

                  <p class="text-h5 pt-6 pb-0">Delete Subject</p>
                  <p class="text-disabled grey--text text-subtitle-1 pt-3" color="rgba(0, 0, 0, 0.6)" disabled>This
                    action
                    will permanently delete the item . This cannot be undone</p>

                  <div class="d-flex justify-space-between pt-4 pb-2" fluid> <v-btn depressed
                      class="btn outline-btn" large width="157px" rounded
                      @click="deleteDialog = false">CANCEL</v-btn> <v-btn class="btn primary-btn" depressed
                      :loading="dLoading" large width="157px" rounded @click="deleteData(selected)">DELETE</v-btn></div>
                </v-card-text>
              </v-container>
            </v-card>
          </v-dialog>
          <!-- filter dialog-->
          <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
            <v-card width="570px" height="100%" class="filterDialogClass">
              <v-row class="mt-4 mx-1">
                <v-col>
                  <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">Filter</div>
                </v-col>
                <v-col class="d-flex justify-end">
                  <div @click="clearFilter" class="clear-filter-btn">CLEAR FILTER </div>
                </v-col>
              </v-row>

              <v-tabs vertical>


                <div class="ml-3 mt-4 mr-4 filterLine">

                  <v-tab class="black--text justify-start" active-class="activeTab">
                    <span>Subject Category</span>
                    <span
                      v-if="selectedSubjectCatFilter.length > 0" class="ml-1">({{ selectedSubjectCatFilter.length
                      }})</span></v-tab>

                </div>


                <v-tab-item>
                  <v-card flat class="school-filter">
                    <v-card-text>
                      <v-chip-group v-model="selectedSubjectCatFilter" active-class="active-chip" column :multiple="true">
                        <v-chip v-for="(SubCatType, index) in subCategory" :key="index" :value="SubCatType.name"
                          elevated>
                          {{ SubCatType.name }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

              </v-tabs>
              <div class="filterDialogButton">
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn rounded outlined class="btn outline-btn pa-4" @click="filterDialog = false">Cancel</v-btn>
                  <v-btn :disabled="selectedSubjectCatFilter.length == 0" :loading="subjectsFilterLoading" rounded
                    class="btn primary-btn pa-4" @click="filterSubject(selectedSubjectCatFilter), getFilterCount()">Apply</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>

          <!-- <v-dialog v-model="filterDialog" max-width="400px">
            <v-card width="400px" height="100%">
              <v-card-text class="pa-6">
                <v-row>
                  <v-col>
                    <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <div @click="clearFilter" class="text-body1 font-weight-bold black--text cursor">
                      CLEAR FILTER
                    </div>
                  </v-col>
                </v-row>
                <div>
                  <v-card height="450px" elevation="0" id="myScroll" class="pt-5 ,pb-5">
                    <v-row class="pl-1">
                      <v-col>
                        <div class="text-body1 font-weight-normal black--text">
                          Subject Category
                        </div>
                      </v-col>
                    </v-row>
                    <v-chip-group v-model="selectedSubjectCatFilter" active-class="primary" column :multiple="true">
                      <v-chip v-for="(SubCatType, index) in subCategory" :key="index" :value="SubCatType.name" elevated>
                        {{ SubCatType.name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card>
                  <div>
                    <v-card-actions class="px-6 pb-6">
                      <v-spacer></v-spacer>
                      <v-btn rounded outlined class="pa-4" @click="filterDialog = false">Cancel</v-btn>
                      <v-btn :disabled="selectedSubjectCatFilter.length == 0" :loading="subjectsFilterLoading" rounded class="accent pa-4" @click="filterSubject(selectedSubjectCatFilter)">Apply</v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog> -->

        </v-col>
        <!-- <v-col cols="4">
        <v-text-field label="Search" v-model="search" clearable prepend-inner-icon="mdi-magnify"></v-text-field></v-col> -->
      </v-row>
      <v-row class="d-flex justify-center my-0 py-0">
        <v-col>
          <div class="text-h6">Subjects</div>
        </v-col>

        <v-col cols="10" class="pl-0 ml-0">
          <v-row justify="end" class="mb-1 mt-0">
            <v-text-field solo label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search" hide-details
              class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
              rounded
              depressed
              v-bind="attrs"
              v-on="on"
            >
            <v-img
            v-bind="attrs" 
            v-on="on" 
            :src="require(`@/assets/svg/sort.svg`)"
            
            />              
              <span>SORT</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item 
              v-for="option in sortOptions" 
              :key="option.value" 
              @click="selectSortOption(option.value)"
              :class="{'selected-sort-option': selectedSort === option.value}"
            >
              <v-list-item-title>
                {{ option.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
              <v-btn depressed outlined rounded class="btn outline-btn mx-3"><v-icon class="pr-2">mdi-tune</v-icon><span
                v-if="filterCount > 0" @click="filterDialog = true">Filter ({{ filterCount }})</span>
              <span v-else @click="filterDialog = true">Filter</span></v-btn><v-btn class="btn mx-3" outlined
              rounded :disabled="selected.length == 0"
              :class="!selected.length == 0 ? 'outline-btn' : 'disabled-outline-btn'"
              @click="deleteDialog = true"><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn>
            <!-- <v-btn class="primary mx-2"
            rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
            <v-btn depressed outlined rounded class="btn outline-btn mx-3" @click="downlaodRoles"><v-icon>mdi-import
                mdi-rotate-90</v-icon></v-btn>

            <v-btn v-if="$store.state.role.subjects.admin" @click="dialog = true, newSubjectCreate()"
              class="btn primary-btn mx-3 white--text" depressed rounded><v-icon class="pr-2">mdi-plus</v-icon>Create
            </v-btn>

          </v-row>
        </v-col>
      </v-row>
      <v-data-table fixed-header height="calc(100vh - 230px)" class="rounded-table" v-model="selected"
        :headers="headers" :loading="dataTableLoading" 
        :disable-sort="true"
        :items-per-page="options.itemsPerPage"
        :server-items-length="count"
        :options.sync="options"
        @update:options="getSubjects"
        loading-text="Loading Subjects" :items="tableData" show-select
        :single-select="singleSelect" :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 50, 100]
        }">
        <template v-slot:loading>
          <bubble-loader/>
        </template>
        <template v-slot:no-results>
          <div class="custom-no-data">
            <p>No Data Available</p>
          </div>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ getDate(item.created_at) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn v-if="$store.state.role.subjects.admin" icon class="mr-2 pa-4" @click="updateData(item)">
            <img width="36px" height="36px" class="cursor" src="../../assets/edit.svg" />
          </v-btn>
        </template>
        <template v-slot:[`item.icon`]="{ item }">
          <v-img height="30px" width="30px" :src="item.icon"
            class="pa-6 bg-secondary rounded-circle d-inline-block"></v-img>
        </template>
      </v-data-table>

      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="errorDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteSuccessDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="#228B22" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 font-weight-bold py-4">Subject Deleted</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="deleteSuccessDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="successDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center d-flex flex-column align-center">
              <v-icon color="success" size="96">mdi-check-circle-outline</v-icon>
              <p class="text-h5 py-4">Subject {{ formbtnValue() }}</p>
              <v-btn class="btn primary-btn" large width="157px" rounded @click="successDialog = false">OK</v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <!-- Notification Dialog -->
    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

  </div>
</template>
<script>
import { storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import SubjectController from "@/controllers/SubjectController";
import SubjectCategoryController from "@/controllers/SubjectCategoryController";
import AuthService from "../../services/AuthService";
import Papa from "papaparse";
import Notification from "../Notification.vue";
import MastersDropdownComponent from "../../components/MastersDropdownComponent.vue"
import BubbleLoader from '@/components/BubbleLoader.vue';

export default {
  name: "SubjectsView",
  components: {
    Notification, MastersDropdownComponent,
    BubbleLoader
  },
  data() {
    return {
      notificationDialog: false,
      appliedFilter: false,
      options: {},
      pageSize: 10,
      page: 1,
      count: 0,
      errorDialog: false,
      successDialog: false,
      deleteSuccessDialog: false,
      errorMessage: "",
      dialog: false,
      dialogTitle: "Dialog Title",
      subName: "",
      preSignedUrl: '',
      selectedFile: null,
      iconURl: "NA",
      dLoading: false,
      subCat: "",
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      image: "",
      formbtnBool: false,
      subCategory: [],
      loading: false,
      singleSelect: false,
      deleteDialog: false,
      selected: [],
      subjectData: null,
      filterDialog: false,
      selectedSubjectCatFilter: [],
      filterData: false,
      search: '',
      subjectsFilterLoading: false,
      searchBool: false,
      dataTableLoading: true,
      sortCol:"name",
      sortOrder: "asc", // Default sort order (asc or desc)
      selectedSort: "subjectAsc", // Default selected sort option
      sortOptions: [
        { value: "subjectAsc", label: "Subject Name - Ascending (A-Z)" },
        { value: "subjectDesc", label: "Subject Name - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],
      headers: [
        { text: "Subject Icon", value: "icon" },
        { text: "Subject Name", value: "name" },
        { text: "Subject Category", value: "subjectCategory" },
        // { text: "Created  On", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
      tableData: [],
      rules: {
        required: (value) => !!value || "Field is required",
      },
    };
  },
  watch: {
    // Add watcher for search
    search(newValue) {
      // Update URL when search changes
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
      this.search = newValue || "";
      this.getSubjects();
    }
  },
  // watch: {
  //   options: {
  //     handler() {
  //       console.log(this.options);
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       if(this.filterData){
  //         this.filterSubject(this.selectedSubjectCatFilter)
  //       }else if(this.searchBool){
  //         this.searchData(this.search);
  //       }else{
  //         this.getSubjects();
  //       }
  //     },
  //     deep: true,
  //   },
  //   search(newValue){
  //     console.log(newValue);
  //       this.searchBool=true
  //       this.pageSize = this.options.itemsPerPage;
  //       this.page = this.options.page;
  //       this.options.page=1;
  //       this.searchData(this.search);
  //       if(newValue=="" || newValue==null){
  //         this.getSubjects();
  //         this.searchBool=false;
  //       }
  //   }
  // },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async downloadCSV(csvData, fileName) {

      const csv = Papa.unparse(csvData);


      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {

        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {

          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    async downlaodRoles() {
      const fileName = 'subjects.csv';
      const subjects = await SubjectController.getSubject();
      this.downloadCSV(subjects, fileName);
    },
    // async searchData(search){
    //   const response = await SubjectController.searchSubject(
    //     this.pageSize,
    //     this.page,
    //     search,
    //   );
    //   console.log(response.data);
    //   console.log(this.searchBool);
    //   this.count = response.data.data.count;
    //   this.tableData = response.data.data.rows;
    // },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    updateData(item) {
      this.editId = item.id; // selected id for edit
      this.formbtnBool = true; // change update/create btn value
      this.dialog = true;
      this.subName = item.name;
      this.subCat = item.subjectCategory;
      this.image = item.icon
    },

    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },
    formbtnValue() {
      return this.formbtnBool === false ? "Created" : "Updated";
    },
    selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "subjectAsc":
          this.sortCol = "name";
          this.sortOrder = "asc";
          break;
        case "subjectDesc":
          this.sortCol = "name";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "subjectAsc";
          this.sortCol = "name";
          this.sortOrder = "asc";
      }
      this.options.page = 1;
      this.getSubjects();
    },

    async saveInputs() {
      var res;
      this.loading = true;
      // checking case for update/create
      if (this.formbtnBool == false) {
        const response = await SubjectController.createSubject(
          this.subName,
          this.subCat,
          this.iconURl,
        );3
        console.log(response);
        res = response
      } else {
        const response = await SubjectController.updateSubject(
          this.subName,
          this.subCat,
          this.iconURl,
          this.editId
        );
        console.log(response);
        res = response
      }
      // Close the dialog
      this.loading = false;
      this.formbtnBool == false;
      this.dialog = false;
      if (res.data.flag) {
        this.successDialog = true;
      } else {
        this.errorDialog = true;
        this.errorMessage = res.data.error;
      }
      this.getSubjects();
    },
    newSubjectCreate() {
      this.subName = '';
      this.subCat = '';
      this.formbtnBool = false;
      this.selectedFile = null;
      this.image = null
    },

    async deleteData(data) {
      // if(data.length==1){
      // this.dLoading = true;
      // const response = await SubjectController.deleteSubject(data[0].id);
      // this.getSubjects();
      // this.deleteDialog = false;
      // console.log(response);
      // this.dLoading = false;
      // this.selected = []
      // }
      // else{
      //   var ids="";
      //   for(var i=0;i<data.length;i++){
      //     ids = ids + data[i].id;
      //       if( i != data.length - 1 ) {
      //           ids = ids + ","
      //       }
      //   }
      var ids = [];
      data.forEach((dataDelete) => {
        ids.push(dataDelete.id)
      })
      this.dLoading = true;
      const response = await SubjectController.deleteBulkSubject(ids);
      if (response.data.flag) {
        this.getSubjects();
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = []
      } else {
        this.getSubjects();
        this.deleteDialog = false;
        console.log(response);
        this.dLoading = false;
        this.selected = []
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    async getSubjects() {
      this.page = this.options.page;
      this.pageSize = this.options.itemsPerPage;
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        search: this.search,
        sortCol: this.sortCol,
        sortOrder: this.sortOrder,
        subjectCategory: this.selectedSubjectCatFilter.join(',')
      }
      const query = "?"+(new URLSearchParams(params).toString());
      const response = await SubjectController.getSubjectByPagination(
        query
      );
      if (response.status == 200) {
        this.dataTableLoading = false;
        console.log(response);
        this.count = response.data.count;
        if (response.data.subjects.length > 0) {
          this.tableData = response.data.subjects;
        }
        this.getSubjectCategory();
      } else {
        // alert(response.data.error);
        alert("Something went wrong");
      }


    },
    async getSubjectCategory() {
      const response = await SubjectCategoryController.getCategory();
      if (response.status == 200) {
        if (response.data.subjectCatgories.length > 0) {
          this.subjectCategoryData = response.data.subjectCatgories;
          this.subCategory = this.subjectCategoryData;
        }
      }
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.selectedFile = e.dataTransfer.files[0];
      this.createFile(this.selectedFile);
    },
    onChange(e) {
      this.selectedFile = e.target.files[0];
      this.createFile(this.selectedFile);

    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      // var img = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = function (e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);

    },

    uploadIcon() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.selectedFile != null) {
          const storageRef = ref(storage, "/subjects/" + this.selectedFile.name);
          uploadBytes(storageRef, this.selectedFile).then((snapshot) => {
            console.log("Uploaded");
            getDownloadURL(snapshot.ref).then((url) => {
              this.iconURl = url;
              this.saveInputs();
            });
          });
        }
      }

    },
    // async getPreSignedUrl() {
    //   this.loading = true;

    //   const response = await UploadController.getPreSignedUrl({
    //     context: "masters",
    //     file_name: this.selectedFile.name,
    //     mime_type: this.selectedFile.type,
    //     file_type: "images",
    //     business_type: "b2c",
    //     uuid: "123-456-7",
    //   });
    //   this.preSignedUrl = response.data.data.signed_request;
    //   this.iconURl=response.data.data.url;
    //   this.uploadToS3();
    // },
    // async uploadToS3() {
    //   console.log("Pre-Signed URL: ", this.preSignedUrl);
    //   const uploadResponse = await UploadController.uploadFile(
    //     this.preSignedUrl,
    //     this.selectedFile
    //   );
    //   console.log("Upload Response: ", uploadResponse);
    //   // this.saveInputs();
    // },
    removeFile() {
      this.image = "";
    },
    clearFilter() {
      this.selectedSubjectCatFilter = [];
      // this.filterDialog = false;
      this.selectedSort = "subjectAsc";
      this.sortCol = "name";
      this.sortOrder = "asc";
      this.getSubjects();
      this.filterData = false;
      this.appliedFilter = false;
      this.filterCount = 0;

    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
    async filterSubject(selectSubjectCategories) {
        this.selectedSort = "subjectAsc";
        this.sortCol = "name";
        this.sortOrder = "asc";
        this.subjectsFilterLoading = true;

        this.subjectsFilterLoading = false;
        this.filterDialog = false;
        this.appliedFilter = true;
        this.filterData = true;
        this.appliedFilter = true;
        this.getSubjects();
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedSubjectCatFilter.length > 0) {
        this.filterCount++;
      }
    },
  },
  created() {
    if (!this.$store.state.role.subjects.read) {
      this.$router.push('/notFound')
    }
    // Get search from URL if it exists
    const urlSearch = this.$route.query.search;
    if (urlSearch) {
      this.search = urlSearch;
    }
    // this.getSubjects();
    // this.getSubjectCategory();
    this.$store.state.breadcrumb = "Subjects";
  },
};
</script>
<style></style>