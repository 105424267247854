import { render, staticRenderFns } from "./SchoolTeachersTBD.vue?vue&type=template&id=a28503ba&scoped=true&"
import script from "./SchoolTeachersTBD.vue?vue&type=script&lang=js&"
export * from "./SchoolTeachersTBD.vue?vue&type=script&lang=js&"
import style0 from "./SchoolTeachersTBD.vue?vue&type=style&index=0&id=a28503ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a28503ba",
  null
  
)

export default component.exports