<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else>
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Candidate List</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <v-container fluid class="qpad pb-0 px-8">
    
    <!-- Header Actions -->
    <v-row class="justify-space-between mr-5 " max-width="366px" persistent>
      <v-col class="pl-5" cols="3">
        <div class="text-h6">Candidate List</div>
      </v-col>
      <v-col cols="9" class="mt-0 p-0">
        <v-row class="justify-end align-center">
          <!-- Search -->
          <v-text-field
            solo
            label="Search here..."
            prepend-inner-icon="mdi-magnify"
            v-model="searchValue"
            hide-details
            class="search-bar border-button rounded-pill mx-2"
            dense
            clearable
          ></v-text-field>

          <!-- Filter Button -->
          <v-btn
            depressed
            rounded
            outlined
            class="btn outline-btn mx-2 pl-4 pr-6"
          >
            <v-icon class="pr-2">mdi-tune</v-icon>
            <span>Filter</span>
          </v-btn>

          <!-- Export Button -->
          <v-btn 
            depressed 
            outlined 
            rounded 
            class="btn outline-btn mx-2"
          >
            <v-icon>mdi-import mdi-rotate-90</v-icon>
          </v-btn>

          
        </v-row>
      </v-col>
    </v-row>

    <!-- Status Tabs -->
    <v-row justify="space-between" class="mr-0 pr-4">
      <v-col class="pl-5 pt-0" cols="8">
        <v-chip-group mandatory v-model="currentSelectedStatus">
          <v-chip 
            class="mr-4" 
            :color="currentSelectedStatus === 0 ? 'active-chip' : ''"
          >
            All
          </v-chip>
          <v-chip 
            class="mr-4" 
            :color="currentSelectedStatus === 1 ? 'active-chip' : ''"
          >
          Yet to take decision
          </v-chip>
          <v-chip 
            class="mr-4" 
            :color="currentSelectedStatus === 2 ? 'active-chip' : ''"
          >
          Decision done
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <!-- Assessment Table -->
    <v-card class="mt-6">
      <v-data-table
        :headers="headers"
        :items="filteredCandidates"
        :search="searchValue"
        class="elevation-1"
      >
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <img 
            width="36px" 
            height="36px" 
            @click="item.status === 'SEND_TO_HIRING_MANAGER' ? navigateToUserReport(item) : null" 
            class="cursor" 
            :style="item.status === 'SEND_TO_HIRING_MANAGER' ? '' : 'opacity: 0.5; cursor: not-allowed;'"
            src="../assets/candidateListGrey.svg" 
          />
          <img 
            width="36px" 
            height="36px" 
            @click="item.status !== 'SEND_TO_HIRING_MANAGER' ? navigateToUserReport(item) : null" 
            class="cursor ml-2" 
            :style="item.status !== 'SEND_TO_HIRING_MANAGER' ? '' : 'opacity: 0.5; cursor: not-allowed;'"
            src="../assets/viewCandidate.svg" 
          />
        </div>
      </template>

        <template v-slot:item.languages="{ item }">
          {{ item.availableLanguages.join(', ') }}
        </template>

        <template v-slot:item.subjects="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.subjects[0] }} {{ item.subjects.length > 1 ? `+${item.subjects.length - 1}` : '' }}
              </span>
            </template>
            <span>{{ item.subjects.join(', ') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
  
</template>

<script>
import "../styles.css";
import CampaignController from "@/controllers/CampaignController";
import AuthService from "../services/AuthService";


export default {
  name: 'candidateList',
  data() {
    return {
      preloader: true,
      candidates: [],
      searchValue: '',
      currentSelectedStatus: 0,
      headers: [
        { text: 'Name', value: 'name', align: 'start' },
        { text: 'Level', value: 'level' },
        { text: 'Subject', value: 'subjects' },
        { text: 'Screening', value: 'screeningTotal' },
        { text: 'Mains', value: 'mainsTotal' },
        { text: 'Demo', value: 'demoScore' },
        { text: 'Interview', value: 'interviewScore' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
    }
  },
  computed: {
    filteredCandidates() {
      console.log('Candidates:', this.candidates);
      
      if (this.currentSelectedStatus === 0) {
        return this.candidates;
      }
      const statusMap = {
        0: 'All',
        1: 'Yet to take decision',
        2: 'Decision done'
      };
      return this.candidates.filter(candidate => {
        const decisionStatus = candidate.status === 'SEND_TO_HIRING_MANAGER' ? 'Yet to take decision':'Decision done' ;
        return decisionStatus === statusMap[this.currentSelectedStatus];
      });
    }
  },
  methods: {
    async fetchCandidates() {
      try {
        this.preloader = true;
        const adminId = this.$store.state.userInfo.id;
        const response = await CampaignController.fetchCandidates(adminId);
        
        if (response.data.flag) {
          console.log('API Response:', response.data);
          this.candidates = response.data.candidates;
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching candidates:', error);
      } finally {
        this.preloader = false;
      }
    },
    navigateToUserReport(item) {
      this.$router.push({
        name: 'CandidateUserReport',
        query: {
          userId: item.userId,
          campaignId: item.campaignId
        }
      });
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
  },
  created() {
    if (!this.$store.state.role?.candidatesListing || !this.$store.state.role.candidatesListing.read) {
      this.$router.push('/notFound');
    }
    this.fetchCandidates();
  }
}
</script>

<style scoped>
.qpad {
  padding-top: 80px !important;
}
.menuable__content__active {
z-index: 1034 !important;
margin: 6px 0px 0px -10px;
.v-picker {
  .v-picker__body {
    width: 218px !important   
  }
  .v-date-picker-title__date {
    font-size: 24px;
  }
  .v-date-picker-header .v-btn {
    height: 36px !important;
  }
  .v-date-picker-table--date .v-btn {
    height: 26px !important;
    width: 26px;
  }
  .v-date-picker-table {
    height: 190px;
  }
}
}
.search-bar {
  max-width: 300px;
}
.active-chip {
  background-color: primary !important;
  color: white;
}
</style>