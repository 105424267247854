<template>
  <div>
    <v-col>
      <v-card height="64px" width="100%"
        class="ml-4 pl-1 pt-5 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <v-span @click="$router.push('/')"
            class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</v-span>

          <v-span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></v-span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <v-span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </v-span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/schooljobseekersappraisal')">No. of users recommended</v-list-item>
              <v-list-item @click="$router.push('/schooljobseekerspip')">No. of users rejected</v-list-item>
              <v-list-item @click="$router.push('/schooljobseekerstbd')">No. of teachers on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start w-fit align-center fixLog">
          <div>
            <img src="../../assets/avtar.png" class="rounded-xl mr-2" width="24px" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4">

          <v-container class="funnel-contianer pr-6 ml-1" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      JOB SEEKERS
                     </p>
                  </v-card-title>

                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Users Invited</span
                    >
                    <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersInvited" propertyEnd="totalJobSeekersScreeningStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolJobSeekerChartData.totalJobSeekersInvited -
                            schoolJobSeekerChartData.totalJobSeekersScreeningStarted,
                            schoolJobSeekerChartData.totalJobSeekersInvited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningStarted" propertyEnd="totalJobSeekersScreeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                     <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolJobSeekerChartData.totalJobSeekersScreeningStarted -
                            schoolJobSeekerChartData.totalJobSeekersScreeningPassed,
                            schoolJobSeekerChartData.totalJobSeekersScreeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningPassed" propertyEnd="totalJobSeekersMainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolJobSeekerChartData.totalJobSeekersScreeningPassed -
                            schoolJobSeekerChartData.totalJobSeekersMainsStarted,
                            schoolJobSeekerChartData.totalJobSeekersScreeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">MAINS</p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsStarted" propertyEnd="totalJobSeekersMainsPassed" funnelColor="#6AD644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolJobSeekerChartData.totalJobSeekersMainsStarted -
                            schoolJobSeekerChartData.totalJobSeekersMainsPassed,
                            schoolJobSeekerChartData.totalJobSeekersMainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsPassed" propertyEnd="totalJobSeekersDemoSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolJobSeekerChartData.totalJobSeekersMainsPassed -
                            schoolJobSeekerChartData.totalJobSeekersDemoSubmitted,
                            schoolJobSeekerChartData.totalJobSeekersMainsPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      DEMO
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersDemoSubmitted" propertyEnd="totalJobSeekersInterviewSubmitted"/>
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                     <img src="../../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolJobSeekerChartData.totalJobSeekersDemoSubmitted -
                            schoolJobSeekerChartData.totalJobSeekersInterviewSubmitted,
                            schoolJobSeekerChartData.totalJobSeekersDemoSubmitted
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
              <div class="px-0 py-0 pl-1 w-19">
                <v-card class="cardBorder rounded pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                    <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>

                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Passed : {{ schoolJobSeekerChartData.totalJobSeekersMainsPassed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Rejected :  {{ schoolJobSeekerChartData.totalJobSeekersMainsFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    For TBD : {{ schoolJobSeekerChartData.totalJobSeekersScreeningFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                </v-card>
              </div>
            </div>
            <!-- <v-col cols="2.5">
                    <FunnelChart/>
                   </v-col>
                   <v-col cols="2.5">
                    <FunnelChart/>
                   </v-col>
                   <v-col cols="2.5">
                    <FunnelChart/>
                   </v-col> -->
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-1 pr-1" fluid>
            <v-col cols="4" md="4" sm="4">
              <div class="text-h6 font-weight-bold highEmphasis">
                Recommended for Hiring ({{ displayedClusterTeachers.length }})
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined class="btn outline-btn mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn>
              <!-- <v-btn depressed rounded outlined class="border-button mx-3"><img src="../../../public/svgs/SORT.svg"
              alt="" />SORT</v-btn> -->

              <!-- <v-btn
                  class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded class="btn outline-btn mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

        <v-card elevation="0" class="ml-6 mr-4 mt-0">
          <v-card-title class="text-subtitle-1 pa-0">
            <table class="tbl">
              <thead class="t-head">
                <tr class="t-row">
                  <th class="head">Rank</th>
                  <th class="head">Full Name</th>
                  <th class="head">Campaign</th>
                  <!-- <th class="head">School Name</th> -->
                  <th class="head">Status</th>
                  <th class="head">Screening Net Score</th>
                  <th class="head">Mains Net Score</th>
                </tr>
              </thead>
              <tbody class="t-body">
                <tr class="t-row" v-for="(teacher, index) in displayedClusterTeachers" :key="teacher.id">
                  <td class="t-data">{{ index + 1 }}</td>
                  <td class="t-data">
                    {{ `${teacher.firstName} ${teacher.lastName}` }}
                  </td>
                  <td class="t-data">{{ teacher.campaignName }}</td>
                  <!-- <td class="t-data">{{ teacher.schoolName }}</td> -->
                  <td class="t-data">
                    <v-chip class="pass-color" outlined text-color="#06C270">
                      <div class="dot-pass"></div>{{ teacher.status }}
                    </v-chip>
                  </td>
                  <td class="t-data">
                    {{ teacher.screeningTotal }}
                  </td>
                  <td class="t-data">
                    {{ teacher.mainsTotal }}
                  </td>
                </tr>
              </tbody>
              <!-- <tbody class="t-body">
              <tr class="t-row">
                <td class="t-data">1</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Screening</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">68%</td>
                <td class="t-data">-</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">2</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">3</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">4</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">5</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">6</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img src="../../../public/svgs/u_map-marker.svg" alt="" srcset="" />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
            </tbody> -->
            </table>
          </v-card-title>
        </v-card>

      </div>

    </v-col>
  </div>
</template>
      
<script >
import FunnelChart from "../FunnelChart.vue";
import AuthService from "@/services/AuthService";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import FunnelChartCommon from "../FunnelChartCommon.vue";
import { mapGetters } from "vuex";
import { liveListenerUnsubscriber } from "@/mixins/liveListenerUnsubcriber";
export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon
  },
  mixins: [ liveListenerUnsubscriber ],
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      topJobSeekerInSchool: [],
      schoolJobSeeker: [],
      schoolClusterTeacher: [],
      topTeacherInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      schoolJobSeekerChartData: {},
      emptySchoolJobSeekerChartData: {
        totalJobSeekersInvited: 0,
        totalJobSeekersScreeningStarted: 0,
        totalJobSeekersScreeningPassed: 0,
        totalJobSeekerssInvited: 0,
        totalJobSeekersMainsStarted: 0,
        totalJobSeekersMainsPassed: 0,
        totalJobSeekersMainsFailed: 0,
        totalJobSeekersDemoSubmitted: 0,
        totalJobSeekersInterviewSubmitted: 0,
      },
      roleCluster: "",
      roleSchool: "",
      recommendedJobseekerCount: 0,
      jobSeekerList : []
    };
  },
  computed: {
    ...mapGetters({
      userRole: 'getRoleFromStore', 
      userRoleId: 'getRoleId'
    }),
    displayedClusterTeachers() {
      return this.topTeacherInCluster;
    },
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async setupSnapshotListenerJobSeekersSchoolHrFunnel() {
      const roleId = this.userRoleId;
      console.log("roleId: " + roleId);
      const dashboardRef = doc(db, "dashboards", `${roleId}-jobseekers-dashboard`);

      if (!dashboardRef) {
          this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
          return;
      }

      const resultsRef = collection(dashboardRef, "results");

      if (!resultsRef) {
          this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
          return;
      }

      const campaignDocRef = doc(resultsRef, "campaignresults");

      if (!campaignDocRef) {
          this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
          return;
      }

      let unsubscribe = onSnapshot(campaignDocRef, (docSnapshot) => {
          if (!docSnapshot.exists()) {
              this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
              return;
          }

          const data = docSnapshot.data();

          if (
              data.invited === undefined ||
              data.screeningStarted === undefined ||
              data.screeningPassed === undefined ||
              data.screeningFailed === undefined ||
              data.mainsStarted === undefined ||
              data.mainsPassed === undefined ||
              data.mainsFailed === undefined ||
              data.demoSubmitted === undefined ||
              data.interviewSubmitted === undefined
          ) {
              this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
          } else {
              this.schoolJobSeekerChartData = {
                  totalJobSeekersInvited: data.invited,
                  totalJobSeekersScreeningStarted: data.screeningStarted,
                  totalJobSeekersScreeningPassed: data.screeningPassed,
                  totalJobSeekersScreeningFailed: data.screeningFailed,
                  totalJobSeekersMainsStarted: data.mainsStarted,
                  totalJobSeekersMainsPassed: data.mainsPassed,
                  totalJobSeekersMainsFailed: data.mainsFailed,
                  totalJobSeekersDemoSubmitted: data.demoSubmitted,
                  totalJobSeekersInterviewSubmitted: data.interviewSubmitted,
              };

          }
          // console.log(this.schoolJobSeekerChartData);
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);

    },

    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      console.log("my cluster>>", this.roleCluster);
      console.log("my school>>", this.roleSchool);


    },
    // async jobSeekerSchoolSnapshotListener() {
    //   const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}
    //   onSnapshot(campaignRef, (snapshot) => {
    //     snapshot.docs.forEach(async (doc) => {
    //       //console.log("asldfjlasdkfj...>>>", doc.id, ".....", doc.data())
    //       const campaignName = doc.data().name;
    //       // if teacher, run this
    //       // console.log("doc id >>", doc.id)
    //       if (doc.data().audienceType.toLowerCase() === "job_seeker") {
    //         console.log("job seeker >>", doc.id);
    //         try {
    //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
    //           const jobSeekDocs = await getDocs(jobSeekerRef);

    //           jobSeekDocs.forEach((d) => {
    //             console.log("jobSeekersDocsTraverse...", d.data());
    //             //countTeacherCluster++;
    //             console.log("screening total >>", d.data().screeningTotal);
    //             if (d.data().schoolName == this.$store.state.role.schools) {
    //               console.log("entered...////");
    //               const topTeachersClusterData = {
    //                 screeningTotal:
    //                   d.data().screeningTotal == null
    //                     ? "-"
    //                     : d.data().screeningTotal,
    //                 mainsTotal:
    //                   d.data().mainsTotal == null ? "-" : d.data().mainsTotal,
    //                 firstName:
    //                   d.data().personalInfo.firstName == null
    //                     ? ""
    //                     : d.data().personalInfo.firstName,
    //                 lastName:
    //                   d.data().personalInfo.lastName == null
    //                     ? ""
    //                     : d.data().personalInfo.lastName,
    //                 schoolName:
    //                   d.data().schoolName == null ? "" : d.data().schoolName,
    //                 lastStatus: (() => {
    //                   let stageValue = "";
    //                   let statusValue = "";

    //                   if (
    //                     d.data().campaignStatus &&
    //                     d.data().campaignStatus.length > 0
    //                   ) {
    //                     const lastStatus =
    //                       d.data().campaignStatus[
    //                         d.data().campaignStatus.length - 1
    //                       ].status;
    //                     if (lastStatus === "INVITED") {
    //                       stageValue = "Screening";
    //                       statusValue = "Yet to Attempt";
    //                     } else if (lastStatus === "SCREENING PASSED") {
    //                       stageValue = "Mains";
    //                       statusValue = "Yet to Attempt";
    //                     }
    //                   }

    //                   return {
    //                     stageValue: stageValue,
    //                     statusValue: statusValue,
    //                   };
    //                 })(),

    //                 campaignName: campaignName,
    //               };
    //               this.schoolClusterTeacher.push(topTeachersClusterData);
    //               console.log(
    //                 "screening total result show >>",
    //                 d.data().screeningTotal
    //               );
    //             }
    //             //console.log("naresh sirrrrr", this.schoolClusterTeacher);
    //             // data.schoolMarks[d.data().schoolName] += d.data().screeningTotal
    //           });
    //           //this.countTeacherCluster = countTeacherCluster;
    //           let sortedProducts = this.schoolClusterTeacher.sort((p1, p2) =>
    //             p1.screeningTotal < p2.screeningTotal
    //               ? 1
    //               : p1.screeningTotal > p2.screeningTotal
    //                 ? -1
    //                 : 0
    //           );
    //           this.topTeacherInCluster = this.schoolClusterTeacher;
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //       }
    //     });
    //   });
    // },
    async jobSeekerSchoolSnapshotListener() {
      const campaignRef = collection(db, "campaigns");
      const audienceTypes = ['JOB_SEEKER', 'JOBSEEKER', 'JOB SEEKER', 'JOB-SEEKER'];
      const clusterName = this.$store.state.role.clusterName;

      const jobSeekerQuery = query(
        campaignRef,
        where('audienceType', 'in', audienceTypes),
        where('clusters', 'array-contains-any', [clusterName]),
      );

      let unsubscribe = onSnapshot(jobSeekerQuery, (snapshot) => {
        this.passedJobSeekersCount = 0;
        this.rejectedJobSeekersCount = 0;
        this.tdbJobSeekersCount = 0;
        this.otherJobSeekersCount = 0;
        this.jobSeekerList.splice(0);
        console.log("this.$store.state.role.schools--->",clusterName);
        // Loop through each document in the campaigns collection snapshot
        snapshot.docs.forEach(async (doc) => {
          const campaignName = doc.data().name;
            
            try {
              const jobSeekerRef = collection(campaignRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);

              // Process each job seeker document in the audience sub-collection
              for (const d of jobSeekDocs.docs) {
                const jobSeekerData = d.data();
                const schoolName = this.$store.state.role.schools;
                const jobSeekerSchoolName = Array.isArray(jobSeekerData?.teachingInterests?.schools) ? jobSeekerData?.teachingInterests?.schools[0] ?? "" : jobSeekerData?.teachingInterests?.schools ?? "";
                const demoStatus = jobSeekerData?.demoStatus ?? false
                const interviewSubmitted = jobSeekerData?.interViewFeedback?.length
                if (
                  jobSeekerSchoolName.toUpperCase() == schoolName && demoStatus && interviewSubmitted
                ) {
  
                  if (jobSeekerData?.campaignStatus?.length > 0  ) {
                    
                      // if (dt.league !== "Not Eligible") {
                        // Here we need to fetch the SCREENING and MAINS sub-collections
                        try {  
                          // Prepare the job seeker data for display
                          const temp = {
                            firstName: jobSeekerData?.personalInfo?.firstName || "",
                            lastName: jobSeekerData?.personalInfo?.lastName || "",
                            campaignName: campaignName,
                            schoolName: jobSeekerSchoolName.toUpperCase(),
                            screeningTotal: jobSeekerData?.screeningTotal || 0,
                            mainsTotal: jobSeekerData?.mainsTotal || 0,
                            status: jobSeekerData.campaignStatus ? jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length - 1].status : "INVITED",
                            // stage: this.getLastStatus(jobSeekerData.campaignStatus),
                            campaignId: doc.id,
                            userId: jobSeekerData.userId
                          };
                          
                          this.jobSeekerList.push(temp);
                        } catch (error) {
                          console.error("Error fetching screening or mains documents:", error);
                        }
                      // }
                  }
                }
              }
              this.topTeacherInCluster = this.jobSeekerList.sort((a, b) => {
              const scoreA = a.mainsTotal === "-" ? -Infinity : Number(a.mainsTotal);
              const scoreB = b.mainsTotal === "-" ? -Infinity : Number(b.mainsTotal);
              
              if (isNaN(scoreA) && isNaN(scoreB)) return 0;
              if (isNaN(scoreA)) return 1;
              if (isNaN(scoreB)) return -1;
              
              return scoreB - scoreA;
            });
              
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          
        });
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
    },
  //   getLastStatus(campaignStatus) {
  //     const lastStatus = campaignStatus[campaignStatus.length - 1].status;
  //   const statusMap = {
  //     "INVITED": { stageValue: "Screening", statusValue: "Yet to Attempt" },
  //     "SCREENING STARTED": { stageValue: "Screening", statusValue: "In Progress" },
  //     "SCREENING PASSED": { stageValue: "Mains", statusValue: "Yet to Attempt" },
  //     "SCREENING FAILED": { stageValue: "Screening", statusValue: "Fail" },
  //     "MAINS STARTED": { stageValue: "Mains", statusValue: "In Progress" },
  //     "MAINS PASSED": { stageValue: "Mains", statusValue: "Pass" },
  //     "MAINS FAILED": { stageValue: "Mains", statusValue: "Fail" },
  // return statusMap[lastStatus] || { stageValue: "", statusValue: "" };
  //   };
  // },
},

  created() {
    this.getClusterOrSchool()
    this.jobSeekerSchoolSnapshotListener()
    this.setupSnapshotListenerJobSeekersSchoolHrFunnel()
  },
};
</script>
      
<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}
</style>