import axios from "axios";
import AuthService from "@/services/AuthService";

const instance = axios.create({
  baseURL: process.env.VUE_APP_CAMPAIGNS_API_URL,
});
export default {

  async getCampaigns(query) {
    // console.log("query",query);

    try {
      // console.log("campain start controller")
      const response = await instance.get("campaigns" + query, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      // console.log("campain end controller",response)
      return response;
    } catch (error) {
      alert("Something went wrong");
      return error.response;
    }
  },

  async getCampaignsCount(query) {
    try {

      const response = await instance.get("campaignsCounts"+ query, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      alert("Something went wrong");
      return error.response;
    }
  },

  async fetchCampaignAudience(id) {
    try {
      const response = await instance.get("camapignAudience?id=" + id, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async fetchMestaCampaignAudienceCount({
    campaignId="",
    levels=[],
    grades=[],
    assessmentName=[],
  }){
    try {
      
      const response = await instance.get( `getMestaCampaignAudienceCount?campaignId=${campaignId}&level=${levels}&grade=${grades}&assesmentName=${assessmentName}`,{
        headers: {
            'Authorization': AuthService.getToken()
        }
      })
      return response;
    }
    catch(error){
      alert("Something went wrong");
      return error.response;
    }
  },
  async fetchMestaCampaignAudience({
    campaignId="",
    itemsPerPage="10",
    page="1",
    latestStatus="",
    search="",
    levels=[],
    grades=[],
    assessmentName=[],
    sortOrder = 'desc'
  }){
    try {
      
      const response = await instance.get( `getMestaCampaignAudience?campaignId=${campaignId}&itemsPerPage=${itemsPerPage}&page=${page}&latestStatus=${latestStatus}&search=${search}&level=${levels}&grades=${grades}&assesmentName=${assessmentName}&sortOrder=${sortOrder}`,{
        headers: {
            'Authorization': AuthService.getToken()
        }
      })
      return response;
    }
    catch(error){
      alert("Something went wrong");
      return error.response;
    }
  },
  async demoTopicsConfigration(id, demoConfigration) {
    try {
      const response = await instance.put("campaignDemoConfigration?id=" + id, {
        demoConfigration: demoConfigration
      }, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  updateCampaignDates: async function ({
    screeningEndDate = "",
    mainsEndDate = "",
    endDate = "",
    campaignId  = ""
  }) {
    try {
      const response = await instance.put(
        "/updateCampaignDates",
        { screeningEndDate, mainsEndDate, endDate, campaignId },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },

  createCampaign: async function (data) {
    try {
      const response = await instance.post("campaign", data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  createCampaignConfigration: async function (campaignConfigration, id) {
    // console.log("id", id)
    try {
      const response = await instance.put(
        "campaignConfigration?id=" + id,
        { campaignConfigration },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  createCampaignAudiance: async function (audiance, id, isAudianceAdded) {
    try {
      const response = await instance.put(
        "campaignAudiance?id=" + id,
        { audiance, isAudianceAdded },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async getResult(userId, campaignId) {
    try {
      const response = await instance.get(
        "getResult?userId=" + userId + "&campaignId=" + campaignId,
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async deleteCampaign(id) {
    try {
      const response = await instance.delete("deleteCampaign?id=" + id, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  async filterCampaign(query,schools, clusters, levels, skills) {
    try {
      const response = await instance.put(
        "filterCampaigns"+query,
        { schools, clusters, levels, skills },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async filterCampaignUser(clusters, boards, id) {
    try {
      const response = await instance.put(
        "filterCamapignAudience",
        { clusters, boards, id },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },

  async getReportData(userId, camapignId) {
    try {
      const response = await instance.get(
        "getUserResult?userId=" + userId + "&campaignId=" + camapignId,
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async getAvalableSlots(camapignId) {
    try {
      const response = await instance.get('/getInterviewSlots?campaignId=' + camapignId, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async submitInterviewFinalStatus(body) {
    try {
      const response = await instance.post('/submitInterviewFinalStatus', body, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async checkAvalableSlotsForInterviewer(email, slotId, slotIndex) {
    try {
      const response = await instance.post('/slotAvalable', {
        email: email,
        slotId: slotId,
        slotIndex: slotIndex,
      }, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async submitInterviewScheduleDetails(data) {
    try {
      const response = await instance.post('/bookInterviewSlot', data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async getInterViewUserInfo(emailId, slotId, timeSlotId, userId, campaignId) {
    console.log('emailId', emailId)
    console.log('slotId', slotId)
    console.log('timeSlotId', timeSlotId)
    console.log('userId', userId)

    try {
      const response = await instance.get(
        "scheduledInterview/" + emailId + "/bookedSlots/" + slotId + "/timeSlot/" + timeSlotId + "/userId/" + userId + "/campaignId/" + campaignId,
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async submitInterViewFeedback(emailId, slotId, timeSlotId, data, userId, campaignId) {
    console.log("campaignId", campaignId)
    console.log("emailId", emailId)
    console.log("slotId", slotId)
    console.log("timeSlotId", timeSlotId)
    console.log("timeSlotId", userId)
    try {
      const response = await instance.post(
        "scheduledInterview/" + emailId + "/bookedSlots/" + slotId + "/timeSlot/" + timeSlotId + "/userId/" + userId + "/campaignId/" + campaignId,
        data,
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async getInterviewReport(userId, campaignId) {
    try {
      const response = await instance.get("getInterviewFeedback", {
        headers: {
          Authorization: AuthService.getToken(),
        },
        params: {
          userId,
          campaignId
        }
      });
      return response;
    } catch (error) {
      alert("Something went wrong");
      return error.response;
    }
  },
  async inviteUsersToCampaign(campaignId, audiance) {
    try {
      const response = await instance.put(`campaignAudiance?id=${campaignId}`, {
        audiance
      }, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  
  async getCampaign(query) {
    console.log('getCampaign')
    try {
      // console.log("campain start controller")
      const response = await instance.get("campaign" + query, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      // console.log("campain end controller",response)
      return response;
    } catch (error) {
      alert("Something went wrong");
      return error.response;
    }
  },

  async clearUserAssessment(userId, campaignId, email) {
    try {
      const response = await instance.put(`clearUserAssessment`, {
        userId, campaignId, sendInvite: true, email,
      }, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },

  async getEligibleTeachers(id, limit, offset) {
    console.log('getEligibleTeachers for id : ', id)
    try {
      const response = await instance.get("eligibleTeachers?campaignId=" + id, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      console.log("getEligibleTeachers end controller",response)
      return response;
    } catch (error) {
      return error;
    }
  },

  async getTopTeachers(limit) {
    try {
    console.log("limit:"+ limit);
    const response = await instance.get("getTopTeachers/"+ limit);
    return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async getCampaignAudience(query,clusters,boards) {
    try{
      console.log('query',query)
      const response = await instance.put('getCampaignAudience'+query,{
        clusters,boards
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async downloadCampaignAudience(query){
    try{
      console.log('query',query)
      const response = await instance.get('downloadCampaignAudience'+query);
      return response.data;
    } catch (error) {
      return error.response;
    }
  },

  async getMestaCampaigns() {
    try {
      const response = await instance.get("getMestaCampaigns", {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  },
  async getPerformanceReport(userId, campaignId, testType){
    try{
      const response =  await instance.get(`getPerformanceReport?userId=${userId}&campaignId=${campaignId}&testType=${testType}`);
      return response.data;
    }
    catch(error){
      return error
    }
  },

  async fetchCandidates(adminId) {
    try {
      const response = await instance.get(`getCandidateListing?adminId=${adminId}`);
      
      return response;
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  },

};