import axios from "axios";
const instance = axios.create({
  baseURL: process.env.VUE_APP_USERS_API_URL,
});

export default {
  // createUser: async function (formData) {
  //   try {
  //     const response = await instance.post("user", formData);
  //     return response;
  //   } catch (error) {
  //     return error.response;
  //   }
  // },
  bulkCreateUsers: async function (usersData) {
    try {
      const response = await instance.post("bulkUsers", usersData);
      return response;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  },
  getUsers: async function () {
    try {
      const response = await instance.get("users");
      return response;
    } catch (error) {
     
      return error.response;
    }
  },
  getSchoolAddresss: async function (query){
    try {
      const response = await instance.get('getSchoolAddress'+query)
      return response;
    }catch (error){
      return error.response
    }
  },
  updateUserInfo: async function (data, id) {
    try {
      console.log("update data for personal info ", data, "id is", id);
      const response = await instance.put(
        "user?userId=" + id,
        data
        //     , {
        //     headers: {
        //         'Authorization': AuthService.getToken()
        //     }
        // }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  
 
  deleteUser: async function (obj) {
    try {
      const response = await instance.delete("users", {
        data: obj,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  filteredUsers: async function (data) {
    try {
      const response = await instance.post("filteredUsers" , data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
  resetAdminPassword: async function (email, newPassword) {
    try{
      const response = await instance.post("reset-password", {email, newPassword})
      return response.data;
    }
    catch(error){
      return error.response;
    }
  },

  fetchRBACRoles: async function () {
    try{
      const response = await instance.get("getRBACRoles")
      console.log('response.data', response.data)
      return response.data;
    }
    catch(error){
      return error.response;
    }
  },
  
  getHiringManagerEmail: async function (query) {
    try {
     const response = await instance.get("getHiringManagerDetails"+query);
    return response;  
    } catch (error) {
      return error.response;
    }
  },
 
};
