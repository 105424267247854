import { render, staticRenderFns } from "./SchoolJobSeekersPIP.vue?vue&type=template&id=99009912&scoped=true&"
import script from "./SchoolJobSeekersPIP.vue?vue&type=script&lang=js&"
export * from "./SchoolJobSeekersPIP.vue?vue&type=script&lang=js&"
import style0 from "./SchoolJobSeekersPIP.vue?vue&type=style&index=0&id=99009912&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99009912",
  null
  
)

export default component.exports