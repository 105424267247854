<template>
    <div>
        <div v-if="preloader">
            <template>
                <bubble-loader />
            </template>
        </div>

        <div v-else>
            <v-card height="64px" width="100%"
                class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
                style="position: fixed; z-index: 200; top: 0; width: 98%; padding-top: 16px;">
                <v-card-title class="pl-0 ml-0">
                    <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/campaign')">
                        Campaigns
                    </p>
                    <v-icon class="breadcrumb-arrow-unselected"
                        style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                    <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.back()">
                        {{ campaignName }}
                    </p>
                    <v-icon class="breadcrumb-arrow-unselected"
                        style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                    <p class="breadcrumb-text" style="margin-top: 2px">Interview History</p>
                    <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
                </v-card-title>
                <div class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
                    style="position: fixed; right: 0">
                    <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
                        <v-hover>
                            <img @click="notificationDialog = true" class="cursor" width="24px"
                                src="../assets/bell 1.svg" />
                        </v-hover>
                    </v-badge>

                    <div>
                        <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
                    </div>
                    <div class="d-flex flex-column d-justify-evenly user-details">
                        <v-card-title class="ma-0 pa-0 elipsis">
                            {{ $store.state.userInfo.name }}
                        </v-card-title>
                        <v-card-subtitle class="ma-0 pa-0 elipsis">
                            {{ $store.state.userInfo.email }}
                        </v-card-subtitle>
                    </div>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                                <v-icon class="">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-btn @click="logout">logout</v-btn>
                    </v-menu>
                </div>
            </v-card>

            <div class="ma-8 mt-16">
                <div class="d-flex justify-space-between align-center mb-6">
                    <h2 class="text-h5">Interview History</h2>
                    <v-btn 
                        rounded 
                        outlined 
                        depressed
                         class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
                        @click="toggleExpandAll"
                    >
                        {{ allExpanded ? 'Collapse All' : 'Expand All' }}
                    </v-btn>
                </div>

                <div v-for="(roundData, round) in interviewRounds" :key="round" class="mb-4">
                    <v-expansion-panels 
                        v-model="expandedPanels[round]"
                        style="border: 1px solid #e0e0e0; border-radius: 8px"
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header class="py-2">
                                <template v-slot:default="{ open }">
                                    <div class="d-flex align-center">
                                        <span class="text-body-1 font-weight-medium">Round {{ round }}</span>
                                    </div>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <div class="py-4">
                                    <div v-for="(status, index) in roundData" :key="index">
                                        <div :class="index > 0 ? 'ml-8' : ''">
                                            <div class="d-flex align-end" :style="index > 0 ? 'position: relative; left: -28px' : ''">
                                                <img 
                                                    v-if="index > 0"
                                                    src="../assets/angle-arrow.svg"
                                                    class=""
                                                    :style="{
                                                        marginLeft: index === 2 ? '60px' : '',
                                                        position: 'relative',
                                                        top: '-6px'

                                                    }"
                                                
                                                    alt="arrow"
                                                />
                                                <span 
                                                    class="text-body-1 font-weight-medium"
                                                    :class="getStatusColor(status.status)"
                                                    style="left: -23px"
                                                >
                                                    {{ formatStatus(status.status) }}
                                                </span>
                                            </div>

                                            <div 
                                                class="d-flex flex-wrap mt-2" 
                                                :class="index > 0 ? `ml-${index === 2 ? '16' : '8'}` : ''"
                                            >
                                                <div class="d-flex align-center mr-6 ">
                                                    <span class="text-body-2  mr-2">{{ index===0 ? 'Interview Scheduled on' : 'Action Taken on'}}</span>
                                                    <span class="mx-2">-</span>
                                                    <span class="text-body-2">{{ formatDate(status.time) }}</span>
                                                </div>
                                                
                                                <div class="d-flex align-center mr-6 ">
                                                    <span class="text-body-2  mr-2">
                                                        {{ status.descisionMaker ? 'Decision Maker' : 'Scheduled By' }}
                                                    </span>
                                                    <span class="mx-2">-</span>
                                                    <span class="text-body-2">{{ status.descisionMaker || status.actionTakenBy }}</span>
                                                </div>

                                                <template v-if="status.hiringManagerName && index === 1">
                                                    <div class="d-flex align-center mr-6 ">
                                                        <span class="text-body-2  mr-2">Hiring Manager Name</span>
                                                        <span class="mx-2">-</span>
                                                        <span class="text-body-2">{{ status.hiringManagerName }}</span>
                                                    </div>
                                                    
                                                    <div class="d-flex align-center mr-6 ">
                                                        <span class="text-body-2  mr-2">Hiring Manager Designation</span>
                                                        <span class="mx-2">-</span>
                                                        <span class="text-body-2">{{ status.hiringManagerDesignation }}</span>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BubbleLoader from "@/components/BubbleLoader.vue";
import AuthService from "../services/AuthService";
import CampaignController from "@/controllers/CampaignController";

export default {
    components: {
        BubbleLoader
    },
    data() {
        return {
            preloader: true,
            campaignId: '',
            userId: '',
            campaignName: "",
            expandedPanels: {},
            allExpanded: false,
            notificationDialog: false,
            interviewRounds: {},
            campaignUserData: {}
        }
    },

    async mounted() {
        this.campaignId = this.$route.query.campaignId;
        this.userId = this.$route.query.userId;
        await this.fetchCampaignAudience();
        this.preloader = false;
    },
    
    methods: {
        async fetchCampaignAudience() {
            try {
                const query = `?campaignId=${this.campaignId}&itemsPerPage=1&page=1&userId=${this.userId}`;
                const audienceData = await CampaignController.getCampaignAudience(query, [], []);
                this.campaignUserData = audienceData.result.users[0];
                this.campaignName = audienceData.result.campaignName;
                
                // Update interview rounds based on the interview status history
                if (this.campaignUserData?.interviewStatusHistory) {
                    this.interviewRounds = this.campaignUserData.interviewStatusHistory;
                }
            } catch (error) {
                console.error('Error fetching campaign audience:', error);
            }
        },

        logout() {
            AuthService.logout();
            this.$router.push("/login");
        },
        
        toggleExpandAll() {
            this.allExpanded = !this.allExpanded;
            
            // Set all panels to match the allExpanded state
            Object.keys(this.interviewRounds).forEach(round => {
                this.$set(this.expandedPanels, round, this.allExpanded ? 0 : null);
            });
        },

        formatDate(dateString) {
            return new Date(dateString).toLocaleString('en-IN', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        },

        formatStatus(status) {
            return status.split('_').map(word => 
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            ).join(' ');
        },

        getStatusColor(status) {
            const colorMap = {
                'SCHEDULED': 'blue--text',
                'SEND_TO_HIRING_MANAGER': 'purple--text',
                'NOTIFY_HR_TO_SCHEDULE_NEXT_ROUND': 'indigo--text',
                'ACCEPTED': 'success--text',
                'REJECTED': 'error--text',
                'ONHOLD': 'warning--text'
            };
            return colorMap[status] || '';
        },

        
    },
    
    watch: {
        expandedPanels: {
            handler() {
                // Check if all panels are expanded
                const allRounds = Object.keys(this.interviewRounds);
                this.allExpanded = allRounds.every(round => this.expandedPanels[round] === 0);
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.repCard {
    margin: 80px 12px 0 32px;
}

.connector-line-1 {
    width: 45px;
    margin-right: 5px;
    height: 1px;
    background-color: #A3A3A3;
    position: relative;
}

.connector-line-2 {
    width: 115px;
    margin-right: 5px;
    height: 1px;
    background-color: #A3A3A3;
    position: relative;
}

.btn.outline-btn {
    border: 1px solid var(--v-primary-base);
    color: var(--v-primary-base);
}

.breadcrumb-text-unselected {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
}

.breadcrumb-text {
    color: var(--v-primary-base);
    font-size: 14px;
}

.breadcrumb-arrow-unselected {
    color: rgba(0, 0, 0, 0.6);
}

.breadcrumb-arrow {
    color: var(--v-primary-base);
}

.underline-on-hover:hover {
    text-decoration: underline;
}

.cursor {
    cursor: pointer;
}

/* panel styles */
.v-expansion-panels {
    border-radius: 8px !important;
    box-shadow: none !important; 
    border: 1px solid #e0e0e0 !important;
}

.v-expansion-panel {
    background-color: #ffffff !important;
    margin-bottom: 0 !important;
    position: relative !important;
}

.v-expansion-panel::before {
    box-shadow: none !important;
}

.v-expansion-panel-header {
    padding: 16px 24px !important;
    min-height: 64px !important;
}

.v-expansion-panel-header .font-weight-medium {
    font-size: 16px !important;
}

/* Style for the expanded state */
.v-expansion-panel--active {
    border-bottom: 1px solid #e0e0e0 !important;
}

.v-expansion-panel--active .v-expansion-panel-header {
    border-bottom: 1px solid #e0e0e0 !important;
}

.v-expansion-panel-header:hover {
    background-color: #f5f5f5 !important;
}

.repCard h2 {
    font-size: 20px;
}
</style>