import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});

export default {
    getFeedbackFormData: async function () {
        try {
            const response = await instance.get('/interviewFeedbackForm')
            return response;
        } catch (error) {
            return error.response;
        }
    },
    

}
