import { render, staticRenderFns } from "./BoardsView.vue?vue&type=template&id=7e9d57ee&scoped=true&"
import script from "./BoardsView.vue?vue&type=script&lang=js&"
export * from "./BoardsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9d57ee",
  null
  
)

export default component.exports