<template>
  <div v-if="preloader === true">
    <template>
      <div class="d-flex align-center justify-center flex-column" style="height: 100vh;">
        <bubble-loader/>
      </div>
    </template>
  </div>
  <div v-else>
    <div v-if="scheduleInterviewDialog">
      <v-dialog
        max-width="1020px"
        width="90%"
        class="cdz"
        v-model="scheduleInterviewDialog"
        center
      >
        <ScheduleInterView
          @closeDialog="manageDialog"
          :userData="currentUserDataForScheduleInterview"
        />
      </v-dialog>
    </div>
    <div v-if="isHrAction">
      <v-dialog v-model="isHrAction" class="cdz" max-width="366px" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="background" size="90"><v-icon size="65">mdi-email</v-icon></v-avatar>

              <p class="text-h5 pt-4 pb-0">Send Mail</p>
              <p class="text-disabled grey--text text-subtitle-1" color="rgba(0, 0, 0, 0.6)" disabled>
                Do you want to send the test link to the candidate?
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn depressed class="background black--text" large width="157px" rounded
                  @click="isHrAction = false">CANCEL</v-btn>
                <v-btn class="btn primary-btn" depressed large width="157px" rounded :loading="dLoading"
                  @click="sendHrEvalMail">SEND</v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    
    <v-card
      height="9.6vh"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="pl-0 ml-0">
        <p
          class="breadcrumb-text-unselected underline-on-hover cursor"
          @click="$router.push('/campaign')"
        >
          Campaigns
        </p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
          >mdi-chevron-right</v-icon
        >
        <p class="breadcrumb-text" style="margin-top: 2px">{{ campaignName }}</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important"
          >mdi-chevron-down</v-icon
        >
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
        style="position: fixed; right: 0"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <v-hover>
            <img
              @click="notificationDialog = true"
              class="cursor"
              src="../assets/bell 1.svg"
            />
          </v-hover>
        </v-badge>

        <div>
          <img
            src="../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>
    <div fluid class="px-8 pad">
      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card width="570px" height="100%" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div
                @click="clearFilter"
                class="clear-filter-btn"
              >
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>

          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <v-tab class="black--text justify-start" active-class="activeTab"
                >
                <span>Clusters</span>
                <span v-if="selectedClusterFilters.length > 0" class="ml-1"
                  >({{ selectedClusterFilters.length }})</span
                >
              </v-tab>
              <v-tab class="black--text justify-start" active-class="activeTab"
                >
                <span>Boards</span>
                <span v-if="selectedBoardsFilter.length > 0" class="ml-1"
                  >({{ selectedBoardsFilter.length }})</span
                >
              </v-tab>
            </div>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedClusterFilters"
                    active-class="active-chip"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(clusterType, index) in clusters"
                      :key="index"
                      :value="clusterType.name"
                      elevated
                    >
                      {{ clusterType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedBoardsFilter"
                    active-class="active-chip"
                    column
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(boardType, index) in boardsData"
                      :key="index"
                      :value="boardType.name"
                      elevated
                    >
                      {{ boardType.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-0">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                class="btn outline-btn pa-4"
                @click="filterDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                :disabled="
                  selectedClusterFilters.length == 0 &&
                  selectedBoardsFilter.length == 0
                "
                :loading="isFilterLoading"
                rounded
                class="btn primary-btn pa-4"
                @click="
                  (appliedFilter = true),
                    fetchCampaignAudience(),
                    getFilterCount()
                "
                >Apply</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <!-- QR Code Dialog -->
      <v-dialog v-model="qrDialog" class="cdz" persistent max-width="380px">
          <v-card>
            <!-- <v-container fluid class="pa-8"> -->
              <!-- <v-icon size="24" class="pt-4" @click="qrDialog = false"
                >mdi-close</v-icon
              > -->
              <v-card-text
                class="text-center d-flex flex-column justify-center align-center"
              >
              <div class="d-flex justify-space-between w-100">
                 <h2 class="pt-4">Share</h2>
                 <span><v-icon size="24" class="pt-4" @click="qrDialog = false"
                  >mdi-close</v-icon></span>
              </div>
                <div ref="qrDownloadRef">
                  <VueQRCodeComponent
                    style="
                      display: flex;
                      justify-content: center;
                      margin-bottom: 8px;
                      padding: 16px;
                    "
                    :text="qrCampaignLink"
                    error-level="L"
                  ></VueQRCodeComponent>
                </div>
                  <h4 class="w-100 text-left">Link</h4>
                  <div style="border: 1px solid; border-radius: 8px;" class="px-6 py-3 ma-2 w-100 d-flex justify-space-between">
                    <span>{{shortCampaignLink}}</span>
                    <div class="copy-image"><v-img
                      max-width="100%"
                      height="100%"
            v-bind="attrs" 
            v-on="on" 
            :src="require(`@/assets/copy_1.svg`)"
            @click="copyLink" /></div>
                  </div>
              </v-card-text>
            <!-- </v-container> -->
          </v-card>
      </v-dialog>

      <!-- Notification Dialog -->
      <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
        <Notification @close-dialog="notificationDialog = false" />
      </v-dialog>

      <v-dialog v-model="leagueDialog" class="cdz" max-width="500px">
        <v-card
          rounded="0"
          elevation="0"
          color="#D8D5C3"
          class="d-flex flex-row justify-space-between"
        >
          <v-card-title class="font-weight-bold">League</v-card-title>
          <v-icon @click="leagueDialog = false" class="mr-4">mdi-close</v-icon>
        </v-card>
        <v-card rounded="0" elevation="0">
          <v-card-title class="font-weight-medium">{{
            this.userName
          }}</v-card-title>
          <v-card-title class="text-body-1 font-weight-medium py-0">
            <v-row>
              <v-col cols="3">League</v-col>
              <v-col cols="2">Rank</v-col>
              <v-col cols="4">Subject</v-col>
              <v-col cols="3">Percentile</v-col>
            </v-row></v-card-title
          >
        </v-card>
        <v-card rounded="0" class="pb-2" elevation="0">
          <v-card-title
            v-for="(skill, index) in this.coreSkillsList"
            :key="index"
            class="text-body-1 font-weight-regular"
          >
            <v-row>
              <v-col cols="3">
                <div class="d-flex flex-column ml-3">
                  <img height="23px" width="25" :src="skill.leagueValueIcon" />
                </div>
              </v-col>
              <v-col cols="2">
                <div class="d-flex flex-column ml-1">#{{ skill.rank }}</div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex flex-column">
                  {{ skill.skillName }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex flex-column ml-4">
                  {{ skill?.percentile?.toFixed(2)  || ''}}%
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-dialog>

      <v-dialog v-model="restartAssessmentDialog" max-width="380px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="p-6">
            <v-card-text class="text-center">
              <p class="text-h5 pb-0">Restart Assessment
              </p>
              <p
                class="text-disabled grey--text text-subtitle-1 pt-3"
                color="rgba(0, 0, 0, 0.6)"
                disabled>
                Do you want to restart the assessment?
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn
                  depressed
                  class="btn outline-btn"
                  large
                  width="157px"
                  outlined
                  rounded
                  @click="restartAssessmentDialog = false;"
                  >CANCEL</v-btn>
                <v-btn
                  class="btn primary-btn"
                  depressed
                  large
                  width="157px"
                  rounded
                  :loading="restartAssessmentLoader"
                  @click="clearUserAssessment(userForClearAssessment)"
                  >RESTART
                </v-btn>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- <v-dialog v-model="proctorDialog" fullscreen persistent>
        <v-card class="proctorCard" v-if="proctorDialog">
          <v-tabs color="green accent-4" left>
            <v-tab>Cumulative</v-tab>
            <v-tab>Timeline</v-tab>


            <v-tab-item :key="1">
              <v-container fluid>
                <div class="procBubChart">
                  <GChart v-if="proctorDialog" type="BarChart" style="height: 100%; width: 100%;"
                    :data="cumulativeProctoringData" :options="barChartOptions" />
                </div>
              </v-container>
            </v-tab-item>

            <v-tab-item :key="2">
              <v-container fluid>

                <div class="procBubChart">
                  <GChart v-if="proctorDialog" type="BubbleChart" style="height: 100%; width: 100%;"
                    :data="bubbleChartData" :options="bubbleChartOptions" />
                </div>

              </v-container>
            </v-tab-item>
          </v-tabs>
          <div class="w-100 d-flex justify-end px-4">
            <v-btn class="accent rounded-pill elevation-0 mx-4" @click="proctorDialog = false">
              Close
            </v-btn>
          </div>
        </v-card>

      </v-dialog> -->

      <!-- <v-dialog v-model="reportDialog" fullscreen class="cdz">

        <v-card>
          <div style="position: sticky; top: 0; z-index: 2; background: white">
            <v-card elevation="0" color="#D8D5C3" class="d-flex flex-row justify-space-between px-3">
              <v-card-title class="font-weight-bold">{{
                selectedUserName
              }}</v-card-title>
              <div>
                <v-btn @click="goBack" outlined elevation="0" class="accent--text mr-4 mt-3" rounded>Close</v-btn>
                <v-btn @click="generateReport" :loading="downloadLoader" elevation="0" class="accent mt-3"
                  rounded>Download</v-btn>
              </div>
            </v-card>
            <div class="py-0 mx-4 d-flex flex-column justify-space-between viewTestProctoringBox">
              <div class="py-0 mx-4 d-flex flex-row justify-space-between">
                <div class="d-flex flex-row align-center py-0 pr-0">
                  <v-card-title class="pl-0"><b>Total Score-</b></v-card-title>
                  <div class="d-flex flex-row ml-1">
                    <v-card-title class="px-0"><b>({{ totalScore }} / {{ maxMarks }})</b></v-card-title>
                  </div>
                </div>

                <div v-if="isSelect === 1" class="d-flex flex-row">
                  <v-card-title class="px-0"><b>Rank- #</b></v-card-title>
                  <v-card-title class="px-0"><b>{{ this.selectedSkill.rank }}</b></v-card-title>
                </div>
              </div>
              <div class="py-0 pb-4 mx-4 d-flex flex-row justify-space-between">
                <p>Screenshot Attempted : {{ proctoringData.Screenshot }}</p>
                <p>
                  Exit Full Screen - {{ proctoringData["Exit Fullscreen"] }}
                </p>
                <p>
                  Screen Change - {{ proctoringData["Switch Tab/Browser"] }}
                </p>
                <p>Key Stroke Attempted : {{ proctoringData["Copy/Paste"] }}</p>
                <div style="display: flex;">
                  <p><b>Proctoring Data </b></p>
                  <v-icon @click="showProc" color="#864f20">mdi-information</v-icon>
                </div>
              </div>
            </div>


            <div class="w-100 d-flex flex-row align-center justify-space-between py-0 my-0">

              <div>
                <div class="d-flex flex-row align-center py-0 mb-3 mt-0">
                  <p class="mx-4 px-0">
                    {{ selectedSkill.skillName }} - <b>({{ selectedSkill.score }}/{{ selectedSkill.maximumMarks }})</b>
                  </p>
                </div>

                <div class="d-flex flex-row ml-4">
                  <div class="text-body-2">Time Taken -</div>
                  <div class="text-subtitle-2 pl-2"><b>({{ userTime }}/{{ maxTime }}) mins</b></div>

                </div>
              </div>

              <div class="d-flex flex-row mr-5">

                <v-card width="280px" class="mr-4 elevation-0 transparent mt-1">

                  
                  <v-progress-linear v-model="progressLevel" rounded :color="progressLevel < 40 ? 'red' : 'green'"
                    height="10" style="position: relative"></v-progress-linear>


                  <img src="../../public/svgs/PassingIcon.svg" alt="pass icon" style="position: absolute; left: 112px">
                </v-card>

                <div class="d-flex flex-row align-center mx-0 px-0">
                
                  <img class="mx-2" src="../../public/svgs/PassingIcon.svg" alt="pass reference icon">
                  <div class="text-body-2">Passing Score</div>
            
                </div>
              </div>

            </div>


            <v-row class="py-0 px-4 my-1 mx-0 flex-row align-center">

              <v-spacer></v-spacer>
              <v-btn-toggle @change="switchResult(isSelect)" v-model="isSelect" mandatory dense rounded>
                <v-btn width="100px" :class="isSelect == 0
                  ? 'btn-color white--text text-subtitle-2 '
                  : '#000000 btn-color--text text-subtitle-2'
                  ">
                  Screening
                </v-btn>
                <v-btn width="100px" :class="isSelect == 1
                  ? 'btn-color white--text ttext-subtitle-2'
                  : '#000000 btn-color--text text-subtitle-2'
                  ">
                  Mains
                </v-btn>
                <v-btn width="100px" :class="isSelect == 2
                  ? 'btn-color white--text ttext-subtitle-2'
                  : '#000000 btn-color--text text-subtitle-2'
                  ">
                  Demo
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </div>

          <div class="w-100 d-flex flex-row question-result-view pa-4">
            <v-card class="scroll-view w-18" height="calc(100vh - 312px)" id="myScroll" outlined elevation="0"
              style="position: sticky; top: 300px;">
              <v-list>
                <v-list-item class="" v-for="(skill, index) in selectedResult.skills" :key="index">
                  <v-btn depressed :color="currenrSkillIndex == index
                    ? skill.result == 'PASSED'
                      ? 'green'
                      : skill.result == 'FAILED'
                        ? 'red'
                        : ''
                    : skill.result == 'PASSED'
                      ? 'green'
                      : skill.result == 'FAILED'
                        ? 'red'
                        : 'grey'
                    " @click="getSelectedSkillData(skill, index)" outlined class="rounded-xl">{{ skill.skillName }} ({{
    skill.score }}/{{ skill.maximumMarks }})</v-btn>
                </v-list-item>
              </v-list>
            </v-card>

            <v-card class="scroll-view w-82" height="calc(100vh - 312px)" id="myScroll" elevation="0">
              <v-list>
                <v-list-item v-for="(item, index) in  selectedSkill.questions" :key="index">



                  <div class="w-100 d-flex flex-column">

                    <div class="d-flex flex-row w-100">
                      <div class="d-flex flex-column w-50">

                        <div class="d-flex flex-row">
                          <div>Q.{{ index + 1 }}</div>
                          <div class="pl-4">{{ item.questionStatement }}</div>
                        </div>

                        <v-row class="pl-8 pt-2 my-1 ml-2">
                          <v-chip v-if="item.difficultyLevel != 'NA'" small class="mr-2">{{ item.difficultyLevel
                          }}</v-chip>
                          <v-chip v-if="item.complexityLevel != 'NA'" small class="mr-2">{{ item.complexityLevel
                          }}</v-chip>
                          <v-chip v-if="item.knowledgeLevel != 'NA'" small class="mr-2">{{ item.knowledgeLevel }}</v-chip>
                          <v-chip v-if="item.level != 'NA'" small class="mr-2">{{ item.level }}</v-chip>
                        </v-row>

                        <v-layout wrap row class="pl-9 pt-2 ml-1">
                          <div class="pr-3">Options -</div>
                          <div v-for="(option, i) in item.questionOptions" :key="i">
                            <div :class="item.correctAnswer === option.optionKey
                              ? 'pr-3 mt-1 text-body-2 green--text'
                              : 'pr-3 mt-1 text-body-2'
                              ">
                              {{ option.optionKey }}. {{ option.optionValue }}
                            </div>
                          </div>
                        </v-layout>


                        <v-row class="pl-8 pt-2 ml-1">
                          <div class="pl-1 text-body-2">Time allocated -</div>
                          <div class="pl-1 text-subtitle-2">
                            {{ item.estimatedTime }} Sec
                          </div>
                          <v-spacer></v-spacer>
                          <div class="pl-1 text-body-2">
                            Avg time taken by other users -
                          </div>
                          <div v-if="(!item.averageTimeTaken)" class="pl-1 text-subtitle-2">NA</div>
                          <div v-else class="pl-1 text-subtitle-2">{{ item.averageTimeTaken.toFixed(2) }}</div>
                          <v-spacer></v-spacer>

                        </v-row>

                      </div>

                      <div class="w-25">
                        <PieChart
                          :pieData="[['Type', 'Percentage'], ['Correct', ((item.noOfCorrectAttempt / item.noOfAttempt) * 100)], ['Incorrect', item.noOfCorrectAttempt ? (100 - ((item.noOfCorrectAttempt / item.noOfAttempt) * 100)) : 100],]" />
                      </div>

                      <div class="w-25">
                        <TimeChart :timeData="[['Type', 'Time', { role: 'style' }],
                        ['Given', parseFloat(item.estimatedTime), '#9F84AE'],
                        ['Average', item.averageTimeTaken?.toFixed(2), '#AA69CE'],
                        ['Current', item.timeTaken?.toFixed(2), '#9F84AE'],]" />
                      </div>

                    </div>

                    <div class="d-flex flex-column w-100 ml-1">
                      <div class="px-0 pl-9 w-100 mt-4">
                        <v-divider></v-divider>
                      </div>

                      <div class="pl-9 pt-2 text-subtitle-2 w-100 d-flex flex-row">

                        <div>Response Analytics -

                          <span v-if="(!item.myAnswer)" class="mr-6">Not Attempted</span>
                          <span v-else class="mr-6">Attempted</span>

                        </div>

                        <div>Answered - </div>

                        <div v-if="(!item.myAnswer)" class="mr-6">Skipped</div>
                        <div class="mr-6" v-else :class="item.correctAnswer === item.myAnswer
                          ? 'pl-1 green--text'
                          : 'pl-1 red--text'
                          ">
                          {{
                            item.correctAnswer === item.myAnswer
                            ? item.myAnswer + " (Correct)"
                            : item.myAnswer + " (Incorrect)"
                          }}
                        </div>

                        <div>Time taken -</div>
                        <div v-if="item.timeTaken != null" class="pl-1">
                          {{ item.timeTaken.toFixed(2) }} Sec
                        </div>
                      </div>


                      <div class="my-3 customDivider"></div>
                    </div>

                  </div>


                </v-list-item>
              </v-list>
            </v-card>


          </div>
        </v-card>
      </v-dialog> -->

      <!-- <v-dialog v-model="demoDialog" fullscreen transition="dialog-bottom-transition">

        <div class="demoVis">
          <DemoReport @close-demo="closeDemo" />
        </div>

      </v-dialog> -->

      <!-- <VueHtml2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
        :paginate-elements-by-height="1400" filename="user-report" :pdf-quality="2" :manual-pagination="true"
        pdf-format="a4" :pdf-margin="10" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf"
        @progress=onProgress($event)>
        <section slot="pdf-content">
          <v-card class="w-85" height="100%" elevation="0">
            <v-list>
              <v-list-item v-for="( item, index ) in  selectedSkill.questions " :key="index">
                <v-col>
                  <v-row>
                    <div>Q.{{ index + 1 }}</div>
                    <div class="pl-4">{{ item.questionStatement }}</div>
                  </v-row>
                  <v-row class="pl-8 pt-2">
                    <v-chip v-if="item.difficultyLevel != 'NA'" small class="mr-2">{{ item.difficultyLevel }}</v-chip>
                    <v-chip v-if="item.complexityLevel != 'NA'" small class="mr-2">{{ item.complexityLevel }}</v-chip>
                    <v-chip v-if="item.knowledgeLevel != 'NA'" small class="mr-2">{{ item.knowledgeLevel }}</v-chip>
                    <v-chip v-if="item.level != 'NA'" small class="mr-2">{{ item.level }}</v-chip>
                  </v-row>
                  <v-layout wrap row class="pl-9 pt-2">
                    <div class="pr-3">Options -</div>
                    <div v-for="( option, i ) in  item.questionOptions " :key="i">
                      <div :class="item.correctAnswer === option.optionKey
                        ? 'pr-3 mt-1 text-body-2 green--text'
                        : 'pr-3 mt-1 text-body-2'
                        ">
                        {{ option.optionKey }}. {{ option.optionValue }}
                      </div>
                    </div>
                  </v-layout>
                  <v-row class="pl-8 pt-2">
                    <div class="pl-1 text-body-2">Time allocated -</div>
                    <div class="pl-1 text-subtitle-2">
                      {{ item.estimatedTime }} Sec
                    </div>
                    <v-spacer></v-spacer>
                    <div class="pl-1 text-body-2">
                      Avg time taken by other users -
                    </div>
                    <div v-if="(!item.averageTimeTaken)" class="pl-1 text-subtitle-2">NA</div>
                    <div v-else class="pl-1 text-subtitle-2">{{ item.averageTimeTaken.toFixed(2) }}</div>
                    <v-spacer></v-spacer>
                    <div class="pl-1 text-body-2">
                      People answered correctly -
                    </div>
                    <div v-if="(!item.noOfAttempt) && (!item.noOfCorrectAttempt)" class="pl-1 text-subtitle-2">NA</div>
                    <div v-else-if="(!item.noOfCorrectAttempt) && (item.noOfAttempt)" class="pl-1 text-subtitle-2">0%
                    </div>
                    <div v-else class="pl-1 text-subtitle-2">{{ (item.noOfCorrectAttempt / item.noOfAttempt) * 100 }}%
                    </div>
                  </v-row>
                  <v-row class="px-0 pl-9">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row class="pl-9 pt-2 text-subtitle-2">

                    <div>Response Analytics -

                      <span v-if="(!item.myAnswer)">Not Attempted</span>
                      <span v-else>Attempted</span>

                    </div>
                  </v-row>
                  <v-row class="pl-9 text-body-2">
                    <div>Answered - </div>
                    <div v-if="(!item.myAnswer)"> Skipped</div>
                    <div v-else :class="item.correctAnswer === item.myAnswer
                      ? 'pl-1 green--text'
                      : 'pl-1 red--text'
                      ">
                      {{
                        item.correctAnswer === item.myAnswer
                        ? item.myAnswer + " (Correct)"
                        : item.myAnswer + " (Incorrect)"
                      }}
                    </div>

                  </v-row>
                  <v-row class="pl-9 text-body-2">
                    <div>Time taken -</div>
                    <div v-if="item.timeTaken != null" class="pl-1">
                      {{ item.timeTaken.toFixed(2) }} Sec
                    </div>
                  </v-row>
                  <v-row class="px-0 pl-9">
                    <v-divider></v-divider>
                  </v-row>
                </v-col>
              </v-list-item>
            </v-list>
          </v-card>
        </section>
      </VueHtml2pdf> -->

      <v-container
        v-if="this.campaignType == 'TEACHER' || this.campaignType == 'NTP'"
        class="funnel-contianer pl-0 pr-3 mr-0"
        fluid
      >
        <div class="w-100 d-flex flex-row">
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p  class="py-0 my-0 pl-2 funnelText">
                  {{campaignType == 'TEACHER' ? 'TEACHER' : 'CANDIDATES'}}
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Registered/Invited</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="invited" propertyEnd="mainsStarted" funnelColor="#E0ED4B"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.invited -
                          chartData.mainsStarted,
                        chartData.invited
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <!-- <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  SCREENING
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Attempted</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.screeningStarted -
                          chartData.screeningPassed,
                        chartData.screeningStarted
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  SCREENING RESULT
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 green--text"
                  >Passed</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.screeningPassed -
                          chartData.mainsStarted,
                        chartData.screeningPassed
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div> -->
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  MAINS
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Attempted</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.mainsStarted -
                          chartData.mainsPassed,
                        chartData.mainsStarted
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  MAINS RESULT
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 green--text"
                  >Passed</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                <!-- <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.mainsPassed -
                          chartData.demoSubmitted,
                        chartData.mainsPassed
                      )
                    }}%
                  </div>
                </div> -->
              </v-card-text>
            </v-card>
          </div>
         <!--  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                <p class="py-0 my-0 pl-2 funnelText">
                  DEMO
                </p>
              </v-card-title>

              <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                <span class="pl-3 black--text"
                  >Submitted</span
                >
                <FunnelChartCommon :invites="chartData" scaleMax="invited" propertyBegin="demoSubmitted" propertyEnd="interviewSubmitted"/>
                <div class="d-flex justify-end py-2 pr-2">
                  <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                  <div 
                    class="red--text pl-2"
                    style="display: inline-block; margin-bottom: 0">
                    {{
                      calculatePercentage(
                        chartData.demoSubmitted -
                          chartData.interviewSubmitted,
                        chartData.demoSubmitted
                      )
                    }}%
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div> -->
          <div class="rounded-lg px-0 py-0 pl-1 w-19">
            <v-card
              class="rounded-lg pr-0 pl-0 cardBorder"
              width="100%"
              height="32vh"
            >
              <v-card-title class="pr-0 pl-0 pb-0">
                <p class="font-weight-bold pl-1 funnelText">CAMPAIGN SUMMARY</p>
              </v-card-title>

              <v-card
                outlined
                class="font-weight-medium pl-1 d-flex align-center rounded mx-3 my-2"
                width="80%"
                height="40px"
              >
                Candidates Passed : {{ apraisals }}
                <v-spacer></v-spacer>
                <!-- <v-icon>mdi-chevron-right</v-icon> -->
              </v-card>
              <!-- <v-card
                outlined
                class="font-weight-medium pl-1 d-flex align-center rounded mx-3 my-2"
                width="80%"
                height="40px"
              >
                Teachers on PIP : {{ pip }}
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-right</v-icon>
              </v-card>
              <v-card
                outlined
                class="font-weight-medium pl-1 d-flex align-center rounded mx-3 my-2"
                width="80%"
                height="40px"
              >
                Teachers on TBD : {{ tbd }}
                <v-spacer></v-spacer>
                <v-icon>mdi-chevron-right</v-icon>
              </v-card> -->
            </v-card>
          </div>
        </div>
        <!-- <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col> -->
      </v-container>
      <v-container
        v-if="this.campaignType == 'JOB_SEEKER'"
        class="funnel-contianer pl-0 pr-3 mr-0"
        fluid
      >
      <div class="w-100 d-flex flex-row">
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg pr-0 pl-0 cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1">
              <p class="py-0 my-0 pl-2 funnelText">
                JOB SEEKERS
              </p>
            </v-card-title>

            <v-card-text class="pt-0 pr-0 pl-0">
              <span class="pl-3 black--text"
                >Users Invited</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.invited -
                        chartData.screeningStarted,
                      chartData.invited
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">
                SCREENING
              </p>
            </v-card-title>

            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 black--text"
                >Attempted</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.screeningStarted -
                        chartData.screeningPassed,
                      chartData.screeningStarted
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">
                SCREENING RESULT
              </p>
            </v-card-title>
            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 green--text"
                >Passed</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.screeningPassed -
                        chartData.mainsStarted,
                      chartData.screeningPassed
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">MAINS</p>
            </v-card-title>


            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 black--text"
                >Attempted</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.mainsStarted -
                        chartData.mainsPassed,
                      chartData.mainsStarted
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">
                MAINS RESULT
              </p>
            </v-card-title>

            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 green--text"
                >Passed</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
              <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.mainsPassed -
                        chartData.demoSubmitted,
                      chartData.mainsPassed
                    )
                  }}%
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
          <v-card
            class="rounded-lg cardBorder"
            width="100%"
            height="32vh"
          >
            <v-card-title class="pb-0 pl-1 pr-0 pt-1 ">
              <p class="pl-2 funnelText">DEMO</p>
            </v-card-title>

            

            <v-card-text class="pr-0 pl-0 pt-0">
              <span class="pl-3 black--text"
                >Submitted</span
              >
              <FunnelChartCommon class="pt-2" :invites="chartData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>

              <!-- <div class="d-flex justify-end py-2 pr-2">
                <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                <div 
                  class="red--text pl-2"
                  style="display: inline-block; margin-bottom: 0">
                  {{
                    calculatePercentage(
                      chartData.demoSubmitted -
                        chartData.interviewSubmitted,
                      chartData.demoSubmitted
                    )
                  }}%
                </div>
              </div> -->
            </v-card-text>
          </v-card>
        </div>
        <div class="d-flex flex-column px-0 py-0 pl-1 w-20">
          <v-card
            @click="goToJobSeekersAppraisal()"
            class="cursor rounded-xl mt-1 col3-card cardBorder"
            outlined
            height="9.6vh"
            color="#4D695B"
          >
            <v-card-title class="text-subtitle-2">
              <v-row justify="space-between" class="pl-2 pt-1 white--text">
                <span>Recommended for Interview</span>
                <v-icon color="white">mdi-chevron-right</v-icon>
              </v-row>
            </v-card-title>

            <v-card-subtitle class="px-4 pt-3 white--text text-h6">
             {{ chartData.demoSubmitted }}
            </v-card-subtitle>
          </v-card>

          <v-card
            @click="goToJobSeekersInterviewed()"
            class="cursor rounded-xl mt-1 col3-card cardBorder"
            outlined
            color="#FB8C00"
            height="9.6vh"
          >
            <v-card-title class="text-subtitle-2">
              <v-row justify="space-between" class="pl-2 pt-1 white--text">
                <span>Job Seekers Interviewed</span>
                <v-icon color="white">mdi-chevron-right</v-icon>
              </v-row>
            </v-card-title>

            <v-card-subtitle class="px-4 pt-3 white--text text-h6">
              {{ chartData.interviewSubmitted }}
            </v-card-subtitle>
          </v-card>

          <v-card
          @click="goToRecommendedForHiring()"
          class="cursor rounded-xl mt-1 col3-card cardBorder"
          outlined
          height="9.6vh"
          color="#00000061"
        >
          <v-card-title class="text-subtitle-2">
            <v-row justify="space-between" class="pl-2 white--text">
              <div>
                <span class="no-space"
                  >Recommended for Hiring</span
                >
              </div>

              <v-icon color="white">mdi-chevron-right</v-icon>
            </v-row>
          </v-card-title>

          <v-card-subtitle class="px-4 pt-3 white--text text-h6">
            {{ this.recommendedJobseekerCount }}
          </v-card-subtitle>
        </v-card>
        </div>
      </div>
        <!-- <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col>
               <v-col cols="2.5">
                <FunnelChart/>
               </v-col> -->
      </v-container>

      <v-row justify="space-between" align="center" class="mb-2">
        <v-col cols="4" sm="4" md="4" class="status-group">
          <div class="text-h5">{{ campName }}</div>
          <v-chip
            :color="currentSelectedStatus == 'ALL'? 'user-status' : ''"
            @click="setStatusFilter('ALL')"
            >ALL</v-chip>
            <v-chip
            :color="currentSelectedStatus == 'ACTIVE' ? 'user-status' : ''"
            class="ml-2"
            
            @click="setStatusFilter('ACTIVE')"
            >ACTIVE</v-chip>
            <v-chip
            :color="currentSelectedStatus == 'ARCHIVED' ? 'user-status' : ''"
            class="ml-2"
            
            
            @click="setStatusFilter('ARCHIVED')"
            >ARCHIVED</v-chip>
        </v-col>

        <v-col cols="8" sm="8" class="d-flex flex-row justify-end">
          <!-- <div class="w-50"> -->
          <!-- <v-btn
            v-if="!['teacher', 'ntp'].includes(campaignType.toLowerCase())"
            @click="copyToClipboard"
            text
            color="#1B72E8"
            ><v-icon>mdi-link-variant</v-icon></v-btn
          >

          <v-btn
            v-if="!['teacher', 'ntp'].includes(campaignType.toLowerCase())"
            :loading="qrLoading"
            @click="generateQRCode"
            text
            color="#1B72E8"
            ><v-icon>mdi-qrcode</v-icon></v-btn
          > -->
          <v-btn
            v-if="!['teacher', 'ntp'].includes(campaignType.toLowerCase())"
            :loading="qrLoading"
            @click="generateQRCode"
            text
            color="#1B72E8"
            
            ><v-img
            v-bind="attrs" 
            v-on="on" 
            :src="require(`@/assets/component_share.svg`)"/>
          </v-btn>
        
          <!-- </div> -->

          <v-card width="326px" class="elevation-0 transparent">
            <v-text-field
              solo
              label="Search here..."
              hide-details
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              @input="handleSearchInput"
              class="search-bar border-button rounded-pill ml-4"
              dense
              clearable
            ></v-text-field>
          </v-card>
          <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="btn outline-btn elevation-0 mx-2 pl-4 pr-6"
              :class="filterCount > 0 ? 'outline-disabled-btn' : ''"
              rounded
              depressed
              v-bind="attrs"
              v-on="on"
              :disabled="filterCount > 0"
            >
            <v-img
            v-bind="attrs" 
            v-on="on" 
            :style="filterCount > 0 ? 'filter: grayscale(100%) opacity(50%);' :''"
            :src="require(`@/assets/svg/sort.svg`)"
            
            />              
              <span>SORT</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item 
              v-for="option in sortOptions" 
              :key="option.value" 
              @click="selectSortOption(option.value)"
              :class="{'selected-sort-option': selectedSort === option.value}"
            >
              <v-list-item-title>
                {{ option.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
          <v-btn depressed rounded outlined class="btn outline-btn mx-3"
            ><v-icon>mdi-tune</v-icon>
            <span v-if="filterCount > 0" @click="filterDialog = true"
              >Filter ({{ filterCount }})</span
            >
            <span v-else @click="filterDialog = true">Filter</span>
          </v-btn>
          
          <v-btn
            depressed
            outlined
            rounded
            @click="downlaodCamapignUserDetails"
            class="btn outline-btn elevation-0 mx-3"
            :loading="downloadLoader"
            ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn>
          <v-btn
            class="btn primary-btn mx-2 elevation-0"
            v-bind="attrs"
            v-on="on"
            rounded
            dark
            >View Schedule</v-btn
          >
        </v-col>
      </v-row>

      <!-- <v-data-table
      v-model="selected"
      :loading="dataTableLoading"
      loading-text="Loading Audience"
      :headers="headers"
      :items="users"
      show-select
      :single-select="singleSelect"
      :options.sync="options"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 50, 100],
      }"
      :server-items-length="count"
    >
      <template v-slot:[`item.fullName`]="{ item }">
        {{ item.personalInfo.firstName + " " + item.personalInfo.lastName }}
      </template>
      <template v-slot:[`item.INVITED`]="{ item }">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text class="text-subtitle-2 mr-5" @click="getResult(item)">
          View Result
        </v-btn>
      </template></v-data-table> -->
      <template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="itemsPerPage"
      :page.sync="currentPage"
      :options.sync="options"
      @update:options="updateOptions"
      :loading="dataTableLoading"
      :server-items-length="totalUsers"
      class="elevation-0 custom-data-table"
      :footer-props="{
          itemsPerPageOptions: [5, 10, 20, 25, -1],
        }"
    >
    <template v-slot:loading>
      <bubble-loader/>
    </template>
    <template v-slot:no-data>
      <div class="custom-no-data">
        <p>No Data Available</p>
      </div>
  </template>
  <template v-slot:header.stages>
  <v-row class="pl-4">
    <v-col cols="12" class=" reportedText d-flex justify-space-between">
      <div v-for="(step, index) in stepperStages" :key="index" class="text-center" style="width: 90px">
        <span>{{ step.label }}</span>
      </div>
    </v-col>
  </v-row>
    </template>

    <template v-slot:header.league>
      <v-row class="text-center custom-header">
        <v-col class="header-cell">
          <span class="custom-header-text">League</span>
        </v-col>
      </v-row>
    </template>
    <template v-slot:header.actions>
      <v-row class="text-center custom-header">
        <v-col class="header-cell">
          <span class="custom-header-text">Action</span>
        </v-col>
      </v-row>
    </template>



<template v-slot:item.stages="{ item, index }">
  <v-row class="d-flex flex-row align-center pl-15 pr-8">
    <v-col cols="12" class="d-flex justify-space-between">
      <v-row class="d-flex flex-row align-center">
        <!-- Loop through each step -->
        <div v-for="(step, stepIndex) in stepperStages" :key="stepIndex" class="text-center" style="width: 80px">
          <v-icon medium :color="stepper.get(index)[step.key]?.iconColor">
            {{ stepper.get(index)[step.key].name }}
          </v-icon>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

      <template v-slot:item.name="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ item.personalInfo.firstName }}
              {{ item.personalInfo.lastName }}
            </div>
          </template>
          <span v-if="item.userType == 'Teacher'">
            {{
              "Grade : " +
              item.responsibleTeachingAt[0].grade +
              ", " +
              "Level : " +
              item.responsibleTeachingAt[0].level +
              ", " +
              "Subject : " +
              item.responsibleTeachingAt[0].subject
            }}
          </span>
          <span v-else>
            {{
              "Levels : " +
              item.teachingInterests?.levels +
              ", " +
              "Subjects : " +
              item.teachingInterests?.subjects +
              ", " +
              "School : " +
              item.teachingInterests?.schools
            }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.email="{ item }">
        {{ item.personalInfo.contactInfo.email }}
      </template>
      <template v-slot:item.location="{item}">
        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <span class='multi-line-ellipsis'  v-bind="attrs" v-on="on" >{{ showSchools(item.teachingInterests) }}</span>
             
            </template>
            <span>{{ showSchools(item.teachingInterests) }}</span>
          </v-tooltip>
     
  
      </template>
      <template v-slot:item.jobrole="{item}">
        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <span   v-bind="attrs" v-on="on"  class='multi-line-ellipsis'>{{ showJobRoles(item.teachingInterests)}}</span>
             
            </template>
            <span >{{  showJobRoles(item.teachingInterests)  }}</span>
          </v-tooltip>
       <!-- <span class='multi-line-ellipsis'> {{ item.teachingInterests?.jobRole?.HRIS_unique_role || "" }} </span> -->
      </template>
      <template v-slot:item.stages="{ item, index }">
        <v-row class="d-flex flex-row align-center pl-15 pr-8">

          <v-col cols="12" v-if="['MESTA_STUDENTS', 'JOB_SEEKER', 'NTP'].includes(campaignType)">
            <v-row class="d-flex flex-row align-center">
              <v-icon
                medium
                :color="stepper.get(index).invitation?.iconColor"
              >{{ stepper.get(index).invitation?.name }}</v-icon>

              <!-- Only show the screening section if campaignType is 'MESTA_STUDENTS', 'JOB_SEEKER' -->
              <template v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType)">
                <v-divider
                  :thickness="10"
                  class="border-opacity-25 px-0 py-0"
                  :color="stepper.get(index).screening?.iconColor"
                ></v-divider>
                <v-icon
                  v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) && stepper.get(index).screening?.stepType == 'showStepperIcon'"
                  medium
                  :color="stepper.get(index).screening?.iconColor"
                >{{ stepper.get(index).screening?.name }}</v-icon>
                  <div style="height: 24px" v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) && stepper.get(index).screening?.stepType == 'showActionIcon' ">
            
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <v-img
                          v-bind="attrs" 
                          v-on="on" 
                                class="stepper-icon"
                                :src="require(`@/assets/svg/${stepper.get(index).screening?.iconName}`)"
                                alt=""  
                              />
                        
                      </template>
                      <div v-if="users[index].isHREvaluate">
                        <p>Waiting for HR Evaluation</p>
                        <p>candidate is waiting for HR team to evaluate application and get back.</p>
                      </div>
                      <div v-if="users[index].isTakeLater">
                        <p>Candidate will take test later</p>
                        <p>An email is sent to candidate with test link.</p>
                      </div>
                    </v-tooltip>
                  </div>          
                 
                  <span  :class="getStepperClasses(index, 'screening')"  v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) && stepper.get(index).screening?.stepType == 'showMarks'"> {{ stepper.get(index).screening.marks }}/10</span>
              </template>

              <!-- Mains section should be visible for all campaigns types-->
              <v-divider
                :thickness="2"
                class="border-opacity-87 px-0 py-0"
                :color="stepper.get(index).mains?.iconColor"
              ></v-divider>
              <v-icon
                v-if="['TEACHER'].includes(campaignType)"
                medium
                :color="stepper.get(index).mains?.iconColor"
              >{{ stepper.get(index).mains?.name }}</v-icon>
              <v-icon
                v-if="['MESTA_STUDENTS', 'JOB_SEEKER', 'NTP'].includes(campaignType) && stepper.get(index).mains?.stepType == 'showStepperIcon'"
                medium
                :color="stepper.get(index).mains?.iconColor"
              >{{ stepper.get(index).mains?.name }}</v-icon>
              <span  :class="getStepperClasses(index, 'mains')"  v-if="['MESTA_STUDENTS', 'JOB_SEEKER', 'NTP'].includes(campaignType) && stepper.get(index).mains?.stepType == 'showMarks' "> {{ stepper.get(index).mains.marks }}/10</span>
                   <div style="height: 24px" v-if="['MESTA_STUDENTS', 'JOB_SEEKER',  'NTP'].includes(campaignType)  && stepper.get(index).mains?.stepType == 'showActionIcon'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <v-img
                          v-bind="attrs" 
                          v-on="on" 
                                class="stepper-icon"
                                 :src="require(`@/assets/svg/${stepper.get(index).mains?.iconName}`)"
                                alt=""  
                              />
                        
                      </template>
                      <div v-if="users[index].isHREvaluate">
                        <p>Waiting for HR Evaluation</p>
                        <p>candidate is waiting for HR team to evaluate application and get back.</p>
                      </div>
                      <div v-if="users[index].isTakeLater">
                        <p>Candidate will take test later</p>
                        <p>An email is sent to candidate with test link.</p>
                      </div>
                    </v-tooltip>
                  </div> 
              <!-- Demo and Interview steps only for non-teacher campaigns -->
              <v-divider v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType)"
                :thickness="10"
                class="border-opacity-25 px-0 py-0"
                :color="stepper.get(index).demo?.iconColor"
              ></v-divider>
                <v-icon v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) && stepper.get(index).demo?.stepType == 'showStepperIcon'"
                medium
                :color="stepper.get(index).demo?.iconColor"
              >{{ stepper.get(index).demo?.name }}</v-icon>

              <span :class="getStepperClasses(index, 'demo')"   v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) &&  stepper.get(index).demo?.stepType == 'showMarks'"> {{ stepper.get(index).demo.marks }}/10</span>
                  <div style="height: 24px" v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) &&  stepper.get(index).demo?.stepType == 'showActionIcon' ">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <v-img
                          v-bind="attrs" 
                          v-on="on" 
                                class="stepper-icon"
                                :src="require(`@/assets/svg/${stepper.get(index).demo?.iconName}`)"
                                alt=""  
                              />
                        
                      </template>
                      <div v-if="users[index].isHREvaluate">
                        <p>Waiting for HR Evaluation</p>
                        <p>candidate is waiting for HR team to evaluate application and get back.</p>
                      </div>
                      <div v-if="users[index].isTakeLater">
                        <p>Candidate will take test later</p>
                        <p>An email is sent to candidate with test link.</p>
                      </div>
                    </v-tooltip>
                  </div>             
              <v-divider v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType)"
                :thickness="10"
                class="border-opacity-25 px-0 py-0"
                :color="stepper.get(index).interview?.iconColor"
              ></v-divider>
              <v-icon v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) && stepper.get(index).interview?.stepType == 'showStepperIcon'"
                medium
                class="pr-4"
                :color="stepper.get(index).interview?.iconColor"
              >{{ stepper.get(index).interview?.name }}</v-icon>
              <span  :class="getStepperClasses(index, 'interview')"   v-if="['MESTA_STUDENTS', 'JOB_SEEKER'].includes(campaignType) && stepper.get(index).interview?.stepType == 'showMarks'"> {{ stepper.get(index).interview.marks }}/10</span>
            </v-row>

          </v-col>
          <v-col v-else>
            <v-row class="d-flex flex-row align-center">
              <v-icon
                medium
                :color="stepper.get(index).invitation?.iconColor"
              >{{ stepper.get(index).invitation?.name }}</v-icon>

              <v-divider
                :thickness="2"
                class="border-opacity-87 px-0 py-0"
                :color="stepper.get(index).screening?.iconColor"
              ></v-divider>
              <v-icon
                medium
                :color="stepper.get(index).screening?.iconColor"
              >{{ stepper.get(index).screening?.name }}</v-icon>

            </v-row>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.status="{item}">
        <v-chip :class="getStatusClass(item).class">
          {{ getStatusClass(item).statusName }}
        </v-chip>
      </template>

      <template v-slot:item.league="{ item }">
        <div v-if="item.coreSkillsSubject != undefined && item.coreSkillsSubject.length > 0" class="text-subtitle-1 d-flex justify-center">
          <div v-if="!item.NoLeague" class="d-flex flex-wrap justify-space-around pt-4">
            <div v-for="(coreSkill, index) in item.coreSkillsSubject.slice(0,3)" :key="index" class="w-fit">
              <v-tooltip v-if="coreSkill.league!='Not Eligible'" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="d-flex flex-column align-center mx-2">
                    <img v-if="coreSkill.league=='Gold'"
                    height="23px" width="25"
                    src="./../assets/gold.svg"/>
                    <img v-if="coreSkill.league=='Silver'"
                    height="23px" width="25"
                    src="./../assets/silver.svg"/>
                    <img v-if="coreSkill.league == 'Bronze' ||
                    coreSkill.league == 'Bonze'"
                    height="23px" width="25"
                    src="./../assets/bronze.svg"/>
                    <div style="font-size: 12px">
                      <span v-if="coreSkill.rank !== undefined">#{{ coreSkill.rank }}</span>
                    </div>
                  </div>
                </template>
                <span style="font-size: 15px"
                  >{{ coreSkill.skillName }} -
                  {{ coreSkill?.percentile?.toFixed(2) || '' }}%
                </span>
              </v-tooltip>
            </div>
            <v-btn v-if="item.coreSkillsSubject.length > 3" :loading="btnLoading" @click="
            openLeagueDialog(
              item.coreSkillsSubject,
              item.personalInfo.firstName,
              item.personalInfo.lastName
            )
            " height="10px" width="10px" text color="#1B72E8" class="text-body-2 ml-9">View More</v-btn>
          </div>
          <div v-else class="d-flex justify-center mt-1">
          -
          </div>
        </div>
        <div v-else class="d-flex justify-center mt-1" >
          -
        </div>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <v-menu left bottom offset-x offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="item.userType.toLowerCase() !== 'teacher' && item.isHREvaluate && !item.demoStatus"
              @click="confirmHrEvaluation({
                fName: item.personalInfo?.firstName,
                lName: item.personalInfo?.lastName,
                email: item.personalInfo.contactInfo?.email,
                userId: item.userId,
                stage: getHrEvaluationStage(stepper.get(index)),
                deviceType: item.deviceType,
              })"
            >
              <v-list-item-title>Initiate Test</v-list-item-title>
            </v-list-item>
            
            <v-list-item :disabled="!isTestReportEnabled(index, item.userType)" @click="getResult(item)">
              <v-list-item-title>Test Report</v-list-item-title>
            </v-list-item>
            
            <v-list-item :disabled="!(item.userType.toLowerCase() !== 'teacher' && isUserReportEnabled(index, item.userType))" @click="getFinalResult(item.userId)">
              <v-list-item-title>User Dashboard</v-list-item-title>
            </v-list-item>
            
            <v-list-item :disabled="!canRestartExam(item.campaignStatus, item)" @click="handleRestartClick(item)">
              <v-list-item-title>Restart Test</v-list-item-title>
            </v-list-item>
            
            <v-menu offset-x offset-y bottom left open-on-hover close-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-if="item.userType !== 'Teacher'" :disabled="!canScheduleInterview(item) && item.userType !== 'Teacher'" v-bind="attrs" v-on="on">
                  <v-list-item-title>Interview</v-list-item-title>   
                  <v-list-item-icon>
                  <v-icon style="font-size: 24px !important;">mdi-chevron-double-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <v-list>
                  <v-list-item 
                  @click="gotoScheduleInterview(item)" 
                  :disabled="isInterviewFeedbackComplete(item)">
                  
                  <v-list-item-title v-if="!item?.interviewDetails">
                    Schedule Interview
                  </v-list-item-title>
                  
                  <v-list-item-title v-else-if="item?.interviewFinalStatus?.status === 'NOTIFY_HR' && !item?.interviewDetails?.interviewSlotDetails[item?.interviewFinalStatus.round-1]">
                    Schedule New Round ({{ item.round + 1 }})
                  </v-list-item-title>
                  
                  <v-list-item-title v-else>
                    Re Schedule Interview
                  </v-list-item-title>
                </v-list-item>

                <v-list-item :disabled="!isInterviewFeedbackComplete(item)"  @click="gotoScheduleInterview(item)">
                  <v-list-item-title>Send to Hiring Manager</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="!isInterviewFeedbackComplete(item)"  @click="gotoScheduleInterview(item)">
                  <v-list-item-title>Reject</v-list-item-title>
                </v-list-item>
                <v-list-item :disabled="!isInterviewFeedbackComplete(item)"  @click="gotoScheduleInterview(item)">
                  <v-list-item-title>On Hold</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-list-item :disabled="isInterviewHistory(item)" @click="handleInterviewHistoryClick(item)">
              <v-list-item-title>View Interview History</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
    </template>


    </v-data-table>
  </v-card>
</template>




      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn
                class="primary"
                large
                width="157px"
                rounded
                @click="errorDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="successDialog"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-4">Cluster</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="successDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar v-model="snackbar" :timeout="1000">
      {{ "Link Copied" }}
    </v-snackbar>
    <v-snackbar v-model="clearUserAssessmentSuccessSnackbar" :timeout="1000">
      Success
    </v-snackbar>
    <v-snackbar v-model="clearUserAssessmentFailureSnackbar" :timeout="1000">
      Application error encountered
    </v-snackbar>
    <v-snackbar v-model="HrEvaluationMailSnackBar" :timeout="1000">
      Mail Sent to the user
    </v-snackbar>
  </div>
</template>
  
<script>
// import "abc" from "../../svgs/"
import FunnelChart from "./FunnelChart.vue";
// import FunnelChart1 from "./FunnelChart1.vue";
// import FunnelChart2 from "./FunnelChart2.vue";
// import FunnelChart3 from "./FunnelChart3.vue";
// import FunnelChart4 from "./FunnelChart4.vue";
import CampaignController from "@/controllers/CampaignController";
import AuthService from "../services/AuthService";
import Notification from "./Notification";
import ScheduleInterView from "../components/ScheduleInterView.vue";
import { mapActions } from 'vuex';
// import DemoReport from "./DemoReport";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
  where,
  query
} from "firebase/firestore";
import { db, app } from "../firebase";
import ClusterController from "@/controllers/ClusterController";
import BoardController from "@/controllers/BoardController";
import { GChart } from "vue-google-charts/legacy";
import FunnelChart1 from "./FunnelChart1.vue";
import FunnelChart2 from "./FunnelChart2.vue";
import FunnelChart3 from "./FunnelChart3.vue";
import FunnelChart4 from "./FunnelChart4.vue";
import FunnelChart5 from "./FunnelChart5.vue";
import FunnelChart6 from "./FunnelChart6.vue";
import FunnelChart7 from "./FunnelChart7.vue";
import FunnelChart8 from "./FunnelChart8.vue";
import FunnelChart9 from "./FunnelChart9.vue";
// import html2pdf from "html2pdf.js"
// import jsPDF from "jspdf"
// import VueHtml2pdf from "vue-html2pdf";
// import PieChart from "../components/ReportGraphs/PieChart.vue"
// import TimeChart from "../components/ReportGraphs/TimeChart.vue"

import axios from "axios";

import VueQRCodeComponent from "vue-qrcode-component";
import html2canvas from "html2canvas";
import ScheduleInterViewVue from "@/components/ScheduleInterView.vue";
import Papa from "papaparse";
import FunnelChartCommon from "./FunnelChartCommon.vue";
import { filter } from "jszip";
import { debounce } from 'lodash';
import MailController from "../controllers/mailController";
import { liveListenerUnsubscriber } from "@/mixins/liveListenerUnsubcriber";
import BubbleLoader from "@/components/BubbleLoader.vue";
export default {
  name: "CampaignUserView",
  components: {
    // TimeChart,
    // PieChart,
    // VueHtml2pdf,
    ScheduleInterView,
    Notification,
    // DemoReport,
    FunnelChart,
    GChart,
    FunnelChart1,
    FunnelChart2,
    FunnelChart3,
    FunnelChart4,
    FunnelChart5,
    FunnelChart6,
    FunnelChart7,
    FunnelChart8,
    FunnelChart9,
    FunnelChartCommon,
    VueQRCodeComponent,
    BubbleLoader
  },
  mixins: [liveListenerUnsubscriber],
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 5,
      sortCol:"",
      sortOrder: "",
      options: {},
      restartAssessmentLoader: false,
      restartAssessmentDialog: false,
      userForClearAssessment: "",
      progressLevel: 0,
      userTime: 0,
      maxTime: 0,
      maxMarks: 0,
      campName: "",
      audienceId: null,
      isHrAction: false,
      // dataDemoVideos: [],
      notificationDialog: false,
      appliedFilter: false,
      scheduleInterviewDialog: false,
      pageSize: 10,
      snackbar: false,
      totalUsers: 0,
      page: 1,
      isSelect: 0,
      count: 0,
      errorDialog: false,
      successDialog: false,
      errorMessage: "",
      singleSelect: false,
      dLoading: false,
      loading: false,
      selected: [],
      clusters: [],
      attrs: "",
      on: "",
      currentUserDataForScheduleInterview: {},
      downloadLoader: false,
      btnLoading: false,
      search: "",
      selectedClusterFilters: [],
      selectedBoardsFilter: [],
      filterDialog: false,
      filterCount: 0,
      id: "",
      uniqueArray: [],
      skillsList: [],
      power: 80,
      campaignName: "",
      users: [],
      preSearchUser: [],
      questions: [],
      totalScore: 0,
      boardsData: [],
      currenrSkillIndex: 0,
      reportDialog: false,
      demoDialog: false,
      color: "white",
      selectedSkill: {},
      userName: "",
      selectedResult: {},
      isFilterLoading: false,
      resultData: [],
      searchBool: false,
      coreSkillsList: [],
      preloader: true,
      dataTableLoading: true,
      isResudule: false,
      headers: [
        { text: 'Name', value: 'name', sortable: false, width: '20%', },
        { text: 'Email ID', value: 'email', sortable: false, width: '20%'  },
        { text: 'Stages', value: 'stages', sortable: false, width: '30%'  },
        { text: 'League', value: 'league',sortable: false, width: '20%'  },
        { text: 'Action', value: 'actions', sortable: false,width: '20%' },
      ],
      tableData: [],
      invited: 0,
      screeningStarted: 0,
      screeningPassed: 0,
      screeningFailed: 0,
      mainsStarted: 0,
      leagueDialog: false,
      mainsPassed: 0,
      mainsFailed: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0,
        apraisals: 0,
        pip: 0,
        tbd: 0,
        procBarColor: "#000",
      },
      selectedUser: "",
      proctorDialog: false,
      selectedUserName: "",
      stepper: new Map(),
      csvData: [],
      campaignDetails: {},
      recommendedJobseekerCount: 0,
      campaignType:'',
      selectedSort: '',
      sortOptions: [
        { value: "nameAsc", label: "Name - Ascending (A-Z)" },
        { value: "nameDesc", label: "Name - Descending (Z-A)" },
        { value: "dateNewest", label: "Date - Newest" },
        { value: "dateOldest", label: "Date - Oldest" },
      ],

      // bubbleChartOptions: {
      //   title: "",

      //   hAxis: {
      //     title: 'Time (min)', format: 0, viewWindow: {
      //       min: 0,
      //       max: 10
      //     }
      //   },
      //   vAxis: {
      //     title: 'Ques', format: 0, viewWindow: {
      //       min: 0,
      //       max: 10
      //     }
      //   },

      //   sizeAxis: {
      //     minSize: 5,
      //     maxSize: 5
      //   },

      //   // legend: 'none',
      // },

      // bubbleChartData: [
      //   ["Id", "Time (min)", "Ques", "Activity", "Time Stamp"],
      // ],

      // barChartData: this.cumulativeProctoringData,

      // barChartOptions: {
      //   title: '',
      //   titleTextStyle: {
      //     fontSize: 12
      //   },
      //   hAxis: {
      //     title: 'Count',
      //     format: 0,
      //     gridlines: {
      //       color: 'transparent'
      //     },
      //     viewWindow: {
      //       min: 0,
      //     },
      //   },
      //   vAxis: {
      //     title: 'Activity',
      //   },
      //   legend: 'none'
      // },

      // userName: "",
      snackbar: false,
      qrLoading: false,
      qrCampaignLink: "",
      qrDialog: false,
      qrDownloadLoading: false,
      clearUserAssessmentSuccessSnackbar: false,
      clearUserAssessmentFailureSnackbar: false,
      debouncedSearch: null,
      HrEvaluationMailSnackBar: false,
      notifyUserDetails:{},
      currentSelectedStatus:'ALL',
      shortCampaignLink:null,
    };
  },
  watch: {

    currentCampaignId: {
      immediate: true,
      handler() {
        this.restorePageState()
      }
    },
    //   options: {
    //     handler() {
    //       console.log(this.options);
    //       this.pageSize = this.options.itemsPerPage;
    //       this.page = this.options.page;
    //       if(this.searchBool){
    //         this.searchData(this.search);
    //       }else{
    //       this.fetchAllClusters();
    //       }
    //     },
    //     deep: true,
    //   },
    options: {
      handler() {
        this.pageSize = this.options.itemsPerPage;
        this.page = this.options.page;
        this.users=[];
        this.isLoading=true
        this.fetchCampaignAudience();
      },
      deep: true,
    },

    // selectedClusterFilters(newValue) {
    //   if(newValue.length === 0) {
    //     this.selectedClusterFilters = []
    //     this.fetchCampaignAudience()
    //   } else {
    //     this.selectedClusterFilters = newValue
    //     this.fetchCampaignAudience()
    //   }
    // },

    // selectedBoardsFilter(newValue) {
    //   if(newValue.length === 0) {
    //     this.selectedBoardsFilter = []
    //     this.fetchCampaignAudience()
    //   } else {
    //     this.selectedBoardsFilter = newValue
    //     this.fetchCampaignAudience()
    //   }
    // },
  },
  mounted() {
    this.restorePageState(this.id);
    if (this.campaignType === 'TEACHER') {
      this.headers = this.headers.filter(header => 
        !['screening', 'demo', 'interview'].includes(header.value)
      );
    }
    if (!['TEACHER', 'NTP'].includes(this.campaignType)) {
      this.headers = [
        { text: 'Name', value: 'name',width: '20%', sortable: false },
        { text: 'Email ID', value: 'email',width: '10%', sortable: false  },
        { text: 'Location', value: 'location', sortable: false,width: '10%', },
        { text: 'Job Role', value: 'jobrole' ,sortable: false,width: '15%', },
        { text: 'Stages', value: 'stages',sortable: false, width: '10%'  },
        { text: 'Status', value: 'status',sortable: false, width: '15%', align: 'center'},
        { text: 'League', value: 'league',sortable: false, width: '10%'  },
        { text: 'Action', value: 'actions', sortable: false,width: '10%' },
      ]
    }
    console.log("id :", this.id, this.campaignName, this.campaignType);

    this.recommendedJobseekerCountListener();
    this.copyToClipboard();
    // this.fetchCampaignAudience();

    // this.getDemoVideos();
  },
  computed: {
    currentCampaignId() {
      return this.$route.query.id;
    },
    stepperStages() {
      if (this.campaignType === 'TEACHER' || this.campaignType === 'NTP') {
        return [
          { label: 'Invited' },
          { label: 'Mains' },
        ];
      } else {
        return [
          { label: 'Invited' },
          { label: 'Screening' },
          { label: 'Mains' },
          { label: 'Demo' },
          { label: 'Interview' },
        ];
      }
    },
  },
  methods: {
    setStatusFilter(status){
      this.currentSelectedStatus = status;
      this.fetchCampaignAudience(this.currentSelectedStatus)
    },
    deriveStatus(user) {
    const finalStatus = ['ACCEPT', 'REJECT', 'ONHOLD', 'SEND_TO_HIRING_MANAGER'];
    const status = user?.interviewFinalStatus?.status?.toUpperCase();
    const interviewRound = user?.interviewFinalStatus?.round;
    const userRound = user?.round;
    const isFeedbackCompleted = this.isInterviewFeedbackComplete(user);
    const isReschedule = user.isReshedule 

    if (finalStatus.includes(status)) {
        return status;
    }

    if (status === 'NOTIFY_HR') {
        if (isFeedbackCompleted) {
            return 'INTERVIEW_COMPLETED';
        } else if (userRound === interviewRound) {
            return isReschedule ? 'RESCHEDULED' : 'SCHEDULED';
        }
        return 'NOTIFY_HR';
    }

    if (userRound > 0 ) {
      return isFeedbackCompleted ? 'INTERVIEW_COMPLETED' : isReschedule ? 'RESCHEDULED' : 'SCHEDULED' ;
    }

},
    getStatusClass(user) {
      let status = this.deriveStatus(user)
      let round = user.round || 0
      let display = status == 'SCHEDULED' ? 'number' : 'icon'

      switch (status) {
        case 'ACCEPT':
          return { class: 'accepted-status', round, icon: require('../assets/check-1.svg'), display, statusName: 'Accepted' };
        case 'SCHEDULED':
          return  { class: 'scheduled-status', round, icon: require('../assets/check-1.svg'), display, statusName: 'Scheduled' };
        case 'REJECT':
        return  { class: 'rejected-status', round, icon: require('../assets/close-1.svg'), display, statusName: 'Rejected'  };
     
        case 'ONHOLD':
        return  { class: 'on-hold-status', round, icon: require('../assets/warning-sign.svg'), display, statusName: 'On Hold'  };
        
        case 'INTERVIEW_COMPLETED':
          return {class: 'interview-completed-status', round, icon: require('../assets/check-1.svg'), display, statusName: 'Interview Completed'};
        
        case 'SEND_TO_HIRING_MANAGER':
          return {class: 'hiring-manager-status', round, icon: require('../assets/check-1.svg'), display, statusName: 'Sent to Hiring Manager'};
        
        case 'NOTIFY_HR':
          return {class: 'notify-hr-status', round, icon: require('../assets/check-1.svg'), display, statusName: 'Notified HR'}
          case 'RESCHEDULED':
          return {class: 'scheduled-status', round, icon: require('../assets/check-1.svg'), display, statusName: 'Rescheduled'}
        default:
          return {class: 'not-scheduled-status', round, icon: '', statusName: 'Not Scheduled'};
      }
    },
    confirmHrEvaluation(data){
      this.notifyUserDetails = data
      this.isHrAction = true
    },
    sendHrEvalMail(){
      let {fName, lName, email, userId, stage, deviceType} = this.notifyUserDetails
      this.notifyUserForAssessment(fName, lName, email, userId, stage, deviceType)
      this.isHrAction = false;
    },
    async notifyUserForAssessment(firstName, lastName, email, userId, assessmentStage, deviceType) {
            try {
                const response = await MailController.notifyUserForAssessment(
                    `${firstName} ${lastName}`,
                    email,
                    userId,
                    this.id,
                    assessmentStage,
                    deviceType
                )

                this.HrEvaluationMailSnackBar = true
                return response;
            }
            catch (error) {
                return error.response;
            }

        },
    hrEvaluation(){
      console.log('hr evaluation clicked')
    },  
     ...mapActions(['savePaginationState', 'clearPaginationStates']),
     updateOptions(newOptions) {
      this.savePaginationState({
        campaignId: this.currentCampaignId,
        paginationState: {
          page: newOptions.page,
          itemsPerPage: newOptions.itemsPerPage,
        }
      })
    },
    getStepperClasses(index, step) {
      const stepColor = this.stepper.get(index)[step]?.iconColor;
      return {
        'pass-step': stepColor === 'green',
        'fail-step': stepColor === 'red'
      }
    },
    calculateInterview(data = []) {
    if (data.length === 0) return 0;

    let maxRound = Math.max(...data.map(element => Number(element.round)));

    let interviewAvgScore = 0;
    let filteredData = data.filter(element => Number(element.round) === maxRound);

    if (filteredData.length === 0) {
        maxRound--;
        filteredData = data.filter(element => Number(element.round) === maxRound);
    }

    if (filteredData.length > 0) {
        interviewAvgScore = filteredData.reduce((sum, element) => sum + parseFloat(element.avgScore), 0) / filteredData.length;
    }

    return interviewAvgScore;
    },
    isInterviewFeedbackComplete(item) {
      if (item?.interviewFinalStatus?.status === "NOTIFY_HR" && Number(item?.round) !== Number(item?.interviewFinalStatus?.round)) {
        return false;
      }
    
    const roundIndex = item.round - 1; // Adjust for zero-based index
    
    // Get emails for the current round
    const emails = item?.interviewDetails?.interviewSlotDetails[roundIndex]?.interviewerEmails || [];
    
    // Get feedback objects for the current round
    const feedbackForRound = item?.interViewFeedback?.filter(fb => Number(fb.round) === Number(item.round));
    
    // Check if every email in the slot has a corresponding feedback entry
      const isComplete = emails.length > 0 && (
    (emails.length >= 3 
      ? emails.filter(email => feedbackForRound?.some(fb => fb.interviewerEmail === email)).length >= 2 
      : emails.every(email => feedbackForRound?.some(fb => fb.interviewerEmail === email))
    )
);    return isComplete;

  },
  isInterviewHistory(item) {
        return !item?.interviewDetails
      },
    addInterviewScore(data){
      this.users = data.map((item)=>{
        return {interveiwScore:  this.calculateInterview(item?.interViewFeedback), ...item}
      })
    },
    restorePageState() {
      const savedState = this.$store.state.campaignPaginationStates[this.currentCampaignId]
      if (savedState) {
        this.currentPage = savedState.page
        this.itemsPerPage = savedState.itemsPerPage
        this.options = { ...this.options, ...savedState }
      } else {
        this.resetToDefaultPagination()
      }
    },
    resetToDefaultPagination() {
      this.currentPage = 1
      this.itemsPerPage = 5
      this.options = { ...this.options, page: 1, itemsPerPage: 5 }
    },


    canScheduleInterview(item) {
      return (this.$store.state.role?.interview?.admin ?? false) && (item.enableScheduleInterview);
    },
    isTestReportEnabled(index, userType){
      if (['mesta_students', 'job seeker'].includes(userType.toLowerCase())) {
        return this.stepper.get(index).screening.stepType == "showMarks";
      } else {
        return this.stepper.get(index).screening.iconColor != "grey";
      }
    },
    isUserReportEnabled(index, userType) {
      if (userType != 'Teacher') {
        return this.stepper.get(index).interview.stepType == "showMarks";
      } else {
        // return this.stepper.get(this.users.indexOf(item)).screening.iconColor == 'green' &&
        return false;
              //  this.stepper.get(this.users.indexOf(item)).demo.iconColor == 'green';
      }
    },
    snapPromise(ref){
      let data = []
     return new Promise((res)=> {
      let unsubscribe = onSnapshot(ref, (snap)=>{ 
          if(!snap.empty){
           snap.docs.map((doc)=> data.push(doc.data())) 
          }
          res(data)
        })
        this.liveListenersToUnsubscribeMixin.push(unsubscribe);
     })
    },
    getSubSorted(data){
      if(data?.length){
          return data.sort((a, b) => {
          const subA = a.subjectName.toUpperCase(); 
          const subB = b.subjectName.toUpperCase(); 
            if (subA < subB) {
            return -1;
          }
          if (subA > subB) {
            return 1;
          }

          return 0;
        })
      }
      return data

    }, 
    getCoreNonCoreSkill(data, core){
      if(data?.length){
        if(core){
          return data.filter(item => item.isCoreSkill)
        } else {
          return data.filter(item => !item.isCoreSkill)
        }
       
      }
    },
    getNonCoreObj(data){
      let obj = {};
      if(data?.length){
          data.forEach((item)=>{
              obj[item.subjectName] = item.score;
              obj[`${item.subjectName}_maximumMarks`] = item.maximumMarks
          })
      }
        return obj
    },
    getSceeningMainsStatusAndTime(campaignStatus, type){
      if(type == 'screening'){
        if(campaignStatus[2]){
          return campaignStatus[2]
        } else {
          return campaignStatus[1] || {status: '', time: ''}
        }
      } else {
         if(campaignStatus[4]){
          return campaignStatus[4]
         } else {
          return campaignStatus[3] || {status: '', time: ''}
         }
      }
     
    },
    getTeacherItems(items, key){
      return items.map(item => item[key]).join()
    },
    evalZeors(score = -1){
      let hasDecimal = (score - Math.floor(score)) !== 0;
      if(score >= 0){
        return  hasDecimal ? `${score}/10` :  `${score}.0/10`
      } 
      return 'NA'    
    }, 
    getRecommendation(coreSkills = {}, interviewFeedback = []){
     let recommendedSubjects = [];
     let notRecommendedSubjects = [];
     let onHoldSubjects = [];
     let interviewSubXmarks =  this.getInterveiwSubjectsAndPercent(interviewFeedback, coreSkills);
     coreSkills.forEach((subject)=>{
      let finalPercent = this.getFinalPercent(subject.percentile,  interviewSubXmarks.get(subject.subjectName))
      if(finalPercent > 0 && finalPercent < 40 ){
        notRecommendedSubjects.push(subject.subjectName)
      }else if (finalPercent >= 40 && finalPercent < 60){
          onHoldSubjects.push(subject.subjectName)
      } else if( finalPercent >=  60){
        recommendedSubjects.push(subject.subjectName)
      }
     })
       return {recommend:recommendedSubjects.join(), hold: onHoldSubjects.join(), notRecommended: notRecommendedSubjects.join()}
    },
   
    getInterveiwSubjectsAndPercent(interviewData, coreSkills){
      let  interviewSubMap = new Map();
            let subjects = coreSkills?.map(item => item.subjectName);
            subjects?.forEach((subject)=> {
                let subPercent = []
                interviewData?.forEach((interview)=> {
                    let subjectData = interview.subjects.filter((interviewsub)=> interviewsub.subjectName == subject)   
                    subPercent.push(this.ratingSum(subjectData[0].feedback) / subjectData[0].feedback.length * 100 / 10)
                })
               interviewSubMap.set(subject,  this.sumPercentValues(subPercent) / subPercent.length)
            })
       return interviewSubMap
        },
        ratingSum(feeback){
           return feeback.reduce((acc, cur)=> {
                return acc + cur.rating
            },0)
        },
        sumPercentValues(data){
          return data.reduce((acc, cur )=> acc+ cur,0)
        },
    getFinalPercent(systemPercent, interviewPercent){
       return  (systemPercent + interviewPercent) / 2;
    },
    getNetScore(data){
      let netScore = 0;
      if(data?.length){
        data?.forEach((skil) => {
            netScore = netScore + parseFloat(skil.finalNetScore);
          });
          netScore = netScore / data.length;
        return  netScore = !isNaN(netScore) ? netScore.toFixed(2) : 0.00;
      }
      return netScore
    },
    getMainsScoreOutOfTen(data = {}){
      let res = 0
      if(data.hasOwnProperty('score') && data.hasOwnProperty('maximumMarks')){
        res = ((parseFloat(data.score) / parseFloat(data.maximumMarks))*10).toFixed(1);
      } 
       return res !== 'NaN' ? res : '0'
    },
    getSubjectWiseDemoAvgScore(demoData = new Array(3).fill("")) {
      let res ={}
      demoData.length < 3 ? demoData.push(...new Array(3-demoData.length).fill("")) : ''
        demoData.forEach((item, index)=>{
            delete item.name
            const totalNumOfParams = Object.values(item)?.length
            res[`subject_${index+1}_demo_avg_score`] = item !== "" ? (Object.values(item).reduce((acc,cur) => acc+cur,0)/totalNumOfParams) : 'NA'
        })
      return res; 
    },
    getDemoScores(demoData = new Array(3).fill("")){
      let res = {}
      demoData.length < 3 ? demoData.push(...new Array(3-demoData.length).fill("")) : ''
        demoData.forEach((item, index)=> {
          res[`subject_${index+1}_Knowledge_Level_Score`] = item !== "" ? item?.knowledgeLevel || 'NA'  :'NA';
          res[`subject_${index+1}_Confidence_Level_Score`] = item !== "" ? item?.confidenceLevel || 'NA' : 'NA';
          res[`subject_${index+1}_Behavior_Level_Score`] = item !== "" ? item?.behaviorlLevel || 'NA' : 'NA';
          res[`subject_${index+1}_Fluency_Level_Score`] = item !== "" ? item?.fluencyLevel || 'NA' : 'NA'
        })
        return res;
    },
    async downlaodCamapignUserDetails(){
      this.downloadLoader = true;
      let query = "?campaignId="+this.id;
      const data = await CampaignController.downloadCampaignAudience(query);
      this.downloadCSV(data.result, data.fileName)
      this.downloadLoader = false;
    },
    sortCsvOnMainsNetScoreDesc(csv){
      return csv.sort((a, b) => b.Mains_net_score - a.Mains_net_score  )
    },
    formatCreatedDate(date){
       if(date == '' || date == null || date == undefined){
        return "NA"
       }
      date = new Date(date)
      return (
        String(date.getDate()).padStart(2, "0") +
        "/" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(date.getFullYear()).padStart(2, "0")
      );
    },
    showSchools(teachingInterests ={}){
        const schools = Array.isArray(teachingInterests?.schools) 
      ? [...teachingInterests?.schools] 
      : [teachingInterests?.schools];

      const otherLocations = Array.isArray(teachingInterests?.otherlocation) 
        ? [...teachingInterests?.otherlocation] 
        : [teachingInterests?.otherlocation];

      return [...schools, ...otherLocations].filter(Boolean).join(", ");
    },
    showJobRoles(teachingInterests ={}){
      if(teachingInterests?.jobRole?.HRIS_unique_role){
        const jobRole = [teachingInterests?.jobRole.HRIS_unique_role]
        const otherJobRole = teachingInterests?.otherJob ?? [];
        return [...jobRole,...otherJobRole].join(", ");
      }else{
        return ''
      }
      
      
    },
    convertArrayToCommaSepratedVals(data){
      if(typeof data == 'string'){
        return data;
      }else if(Array.isArray(data)) {
        return data.join(", ")
      } else {
        return 'NA'
      }
    },
    getFullName(info){
      let keys = ['firstName', 'middleName', 'lastName']
       return  keys.map((key)=> info.hasOwnProperty(key) ? `${info[key]}` : '' ).join(" ")
    
    },
     downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    async downloadQrCode() {
      // qrDownloadRef
      this.qrDownloadLoading = true;
      html2canvas(this.$refs.qrDownloadRef).then((canvas) => {
        let link = document.createElement("a");
        // link.download = 'screenshot.jpg';
        link.download = `${this.campaignName}.jpg`;
        link.href = canvas.toDataURL("image/jpeg", 0.5);
        link.click();
      });
      this.qrDownloadLoading = false;
    },

    async generateQRCode() {
      try {
        this.qrLoading = true;
        const id = this.id;
        const audienceType = this.campaignType;

        // console.log('data :', data);

        let shortCampaignLink =
          `https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=` + id;

        if (audienceType.toLowerCase() !== "teacher") {
          const campaignLink = `https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome`;

          const apiRes = await axios.post(
            `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`,
            {
              longDynamicLink: campaignLink,
              suffix: {
                option: "SHORT",
              },
            }
          );

          if (apiRes?.data?.shortLink) {
            shortCampaignLink = apiRes?.data?.shortLink;
          }
        }
        // this.qrCampaignName=data.name
        this.qrCampaignLink = shortCampaignLink;
        this.qrLoading = false;
        this.qrDialog = true;
      } catch (err) {
        console.log("Error :", err);
        this.qrLoading = false;
      }
    },
    manageDialog(data) {
      console.log("debfgjwkdsbfvebdcv,br", data);
      this.scheduleInterviewDialog = data;
      this.fetchCampaignAudience();
    },
    copyLink(){
      console.log('copy link')
      navigator.clipboard
        .writeText(this.shortCampaignLink) 
         .then(() => {
          this.snackbar = true;
        }) 
    },
    async copyToClipboard() {
      // this.shortCampaignLink =
      //   `https://${this.$FRONTEND_DOMAIN}/#/campaignInvite?campaignId=` +
      //   this.id;

      if (this.campaignType.toLowerCase() !== "teacher") {
        const campaignLink = `https://${this.$DEEPLINK}/?link=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&apn=${this.$PACKAGE_NAME}&afl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome&isi=6446600030&ibi=${this.$PACKAGE_NAME}&ifl=https%3A%2F%2F${this.$FRONTEND_DOMAIN}%2F%23%2Fwelcome`;

        const apiRes = await axios.post(
          `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${app.options.apiKey}`,
          {
            longDynamicLink: campaignLink,
            suffix: {
              option: "SHORT",
            },
          }
        );

        if (apiRes?.data?.shortLink) {
          this.shortCampaignLink = apiRes?.data?.shortLink;
        }
      }
      
    },

    getFinalResult(userId) {
      this.$router.push({
        path: "/userreport",
        name: "UserReport", //use name for router push
        query: {
          campaignId: this.id,
          userId: userId,
        },
      });
      // this.$router.push('/userreport')
    },

    async gotoScheduleInterview(userData) {
      
      let query = this.$route.query;
      query['userId'] = userData.userId
      query['campaignName'] = this.campaignName 
      
     this.$router.push({path:'/schedule-interview-rounds', query})
    },

    // generateRandomColor() {
    //   const minRed = 255;
    //   const maxRed = 255;
    //   const minGreen = 255;
    //   const maxGreen = 0;
    //   const minBlue = 0;
    //   const maxBlue = 0;

    //   const randomRed = Math.floor(Math.random() * (maxRed - minRed + 1) + minRed);
    //   const randomGreen = Math.floor(Math.random() * (maxGreen - minGreen + 1) + minGreen);
    //   const randomBlue = Math.floor(Math.random() * (maxBlue - minBlue + 1) + minBlue);

    //   const randomColor = `rgb(${randomRed},${randomGreen},${randomBlue})`;

    //   return randomColor;
    // },

    // demoReport() {
    //   // console.log("data demo videos...???", this.dataDemoVideos)
    //   this.dataDemoVideos.sort((x, y) => x.date.localeCompare(y.date))

    //   // this.dataDemoVideos = this.dataDemoVideos[this.dataDemoVideos.length - 1]
    //   // localStorage.setItem("videoInfo", JSON.stringify(this.dataDemoVideos))
    //   // this.$store.commit("setVideoDetails", this.dataDemoVideos)

    //   const demoVideos = this.dataDemoVideos[this.dataDemoVideos.length - 1]
    //   localStorage.setItem("videoInfo", JSON.stringify(demoVideos))
    //   this.$store.commit("setVideoDetails", demoVideos)

    //   // this.$router.push("/demoreport")
    //   this.demoDialog = true
    // },

    // closeDemo() {
    //   this.demoDialog = false;
    //   this.switchResult(0);
    //   this.isSelect = 0;
    // },

    // showProc() {
    //   if (this.bubbleChartData.length > 1) {
    //     this.proctorDialog = true;
    //   }
    //   else {
    //     alert("Proctoring data not found")
    //   }
    // },

    // async generateDemoReport() {
    //   try {
    //     console.log("entered in generated report")
    //     item.loading = true;
    //     const rowData = data.dataVideosTable[item.index];
    //     console.log("row data...??", rowData)
    //     if (!rowData.avgEmotions) {
    //       const response = await VideoController.getSentiment(rowData.email, rowData.videoId, rowData.downLoadLink);
    //       console.log("Generating Report....??", response)
    //     }

    //     let cap = "";

    //     if ((!rowData.caption) && (rowData.caption !== "")) {
    //       const response = await VideoController.generateTranscript(rowData.email, rowData.videoId, rowData.savedVideoName)

    //       if (response && response?.flag) {
    //         cap = response.caption
    //         await VideoController.speechAnalysis(rowData.email, rowData.videoId, cap)
    //       }
    //       else {
    //         console.log('retrying...1')
    //         const res2 = await VideoController.generateTranscript(rowData.email, rowData.videoId, rowData.savedVideoName)

    //         cap = res2.caption
    //         await VideoController.speechAnalysis(rowData.email, rowData.videoId, cap)
    //       }
    //     }
    //     else {
    //       cap = rowData.caption
    //     }

    //     if (rowData.caption && !rowData.audioAnalysisReport) {
    //       const response = await VideoController.speechAnalysis(rowData.email, rowData.videoId, cap);
    //     }

    //     console.log("here1")

    //     item.loading = false;

    //   } catch (err) {
    //     console.log("here2")
    //     item.loading = false;
    //     console.log(err);
    //     alert('Error :', err);
    //   }
    // },

    // async checkDemoReportStatus() {
    //   const temp = this.dataDemoVideos[item.index].avgEmotions && this.dataDemoVideos[item.index].caption && this.dataDemoVideos[item.index].audioAnalysisReport

    //   if (!temp) {
    //     item.loading = true;
    //   }

    //   return (this.dataDemoVideos[item.index].avgEmotions && this.dataDemoVideos[item.index].caption && this.dataDemoVideos[item.index].audioAnalysisReport)
    // },

    goBack() {
      // this.reportDialog = false;
      this.$router.back();
    },

    // hasGenerated(event) {
    //   console.log("generated pdf", event)
    // alert("PDF generated successfully!");
    // },

    onProgress(event) {
      console.log(`progress data ${event}`);
      if (event === 100) {
        this.downloadLoader = false;
      }
    },

    // generateReport() {
    //   this.downloadLoader = true;
    //   this.$refs.html2Pdf.generatePdf();
    // },

    // downloadPdf(){
    //   const pdf=new jsPDF()
    //   const content=this.$refs.downloadableContent.innerHTML
    //   pdf.html(content)
    //   pdf.save("test.pdf")
    //   console.log("entered")

    //   const element=this.$refs.downloadableContent
    //   console.log("elemnt", element)
    //   console.log("innerhtml", element.innerHTML)
    //   html2pdf(element,{
    //     margin:1,
    //     filename: "test..pdf",
    //     image: { type: "jpeg", quality:0.98},
    //     html2canvas: { dpi: 192, letterRendering: true},
    //     jspdf: {unit: "in", format: "letter", orientation: "portrait"}
    //   })

    //   this.$refs.html2Pdf.generatePdf();
    // },

    // updateChart(){
    //   let testType="SCREENING"
    //   if (this.isSelect==1){
    //     testType="MAINS"
    //   }
    //   const testRef = doc(
    //     db,
    //     "proctoredtests",
    //     this.id,
    //     this.selectedUser,
    //     testType
    //   );

    //   console.log("Path...>>>", this.id, this.selectedUser, this.testType);
    //   onSnapshot(testRef, (snapshot)=>{
    //     // console.log("entered");
    //     if (!snapshot.data){
    //       console.log("entered");
    //       return;
    //     }
    //     console.log("testRef Data...>>>>",snapshot.data());
    //   })
    // },

    // secondsToTime(seconds) {
    //   var hours = Math.floor(seconds / 3600);
    //   var minutes = Math.floor((seconds % 3600) / 60);
    //   var seconds = seconds % 60;
    //   return hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    // },

    // addDataToChart() {
    //   this.bubbleChartData.splice(1);
    //   this.bubbleChartOptions.hAxis.viewWindow.max = parseInt(this.timeToMinutes(this.userProctoringActivities[this.userProctoringActivities.length - 1].time) - this.timeToMinutes(this.userProctoringActivities[0].time)) + 1

    //   this.bubbleChartOptions.vAxis.viewWindow.max = 1;

    //   for (const act of this.userProctoringActivities) {

    //     // this.bubbleChartData.push([act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time), act.time, act.activity, act.ques])
    //     this.bubbleChartData.push([act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time), act.ques, act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time)])

    //     this.bubbleChartOptions.vAxis.viewWindow.max = Math.max(this.bubbleChartOptions.vAxis.viewWindow.max, act.ques + 1);
    //   }
    // },

    timeToMinutes(timeString) {
      var timeParts = timeString.split(":");
      var hours = parseInt(timeParts[0]);
      var minutes = parseInt(timeParts[1]);
      var seconds = parseInt(timeParts[2]);
      return (hours * 3600 + minutes * 60 + seconds) / 60;
    },

    openLeagueDialog(skillsList, firstName, lastName) {
      this.userName = firstName + " " + lastName;
      this.coreSkillsList = skillsList;
      this.leagueDialog = true;
    },

    clearFilter() {
      this.selectedClusterFilters = [];
      this.selectedBoardsFilter = [];
      this.selectedSort = "";
      this.sortCol="mainsTotal";
      this.sortOrder = "desc";
      this.fetchCampaignAudience();
      this.appliedFilter = false;
      this.filterCount = 0;
    },

    getSelectedSkillData(skill, index) {
      // console.log("skill :", skill)
      // console.log("selectedResult :", this.selectedResult)
      this.currenrSkillIndex = index;
      this.selectedSkill = skill;
      this.progressLevel =
        (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedClusterFilters.length > 0) {
        this.filterCount++;
      }
      if (this.selectedBoardsFilter.length > 0) {
        this.filterCount++;
      }
    },

    logout() {
      this.clearPaginationStates();
      AuthService.logout();
      this.$router.push("/login");
    },

    // switchResult(index) {
    //   // console.log("index" , index)
    //   if (index != 2) {
    //     this.totalScore = 0;
    //     this.maxMarks = 0;
    //     this.selectedResult = this.resultData[index];
    //     // console.log("selectedResult....????", this.selectedResult)
    //     this.maxTime = this.selectedResult.maximumTime / 60;
    //     this.userTime = (this.selectedResult.timeTakenByUser / 60).toFixed(2);
    //     if (this.selectedResult == null) {
    //       alert("Test not started yet");
    //       this.switchResult(0);
    //       this.isSelect = 0;
    //     } else {
    //       this.selectedSkill = this.resultData[index].skills[0];
    //       // console.log("selectedSkills...???", this.selectedSkill)
    //       this.progressLevel = (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100
    //       this.selectedResult.skills.forEach((skill) => {
    //         this.totalScore = this.totalScore + skill.score;
    //         this.maxMarks += skill.maximumMarks;

    //       });

    //     }
    //   }

    //   else if (index === 2) {
    //     if (this.dataDemoVideos?.length > 0) {
    //       this.demoReport();
    //     } else {
    //       alert("Demo video not uploaded");
    //       this.switchResult(0);
    //       this.isSelect = 0;
    //     }
    //   }

    // },

    async getResult(data) {
      const url = this.$router.resolve({ path: `/testreport/${this.id}/${data.userId}` }).href;
  window.open(url, '_blank');
      // const response = await CampaignController.getResult(data.userId, this.id);
      // if (response.status == 200) {
      //   if (response.data.length == 0) {
      //     alert("User test not started");
      //   } else {
      //     this.$router.push(`/testreport/${this.id}/${data.userId}`);
      //   }
      // } else {
      //   alert(response.data.error);
      // }
    },

    // async getResult(data) {
    //   // console.log("Seeing here all the data...????", data, this.id);
    //   // console.log("data...>>>", data.userId, this.id)
    //   // this.btnLoading = true;

    //   const response = await CampaignController.getResult(data.userId, this.id);
    //   // console.log("questions", response);
    //   if (response.status == 200) {
    //     if (response.data.data.length == 0) {
    //       alert("User test not Started");
    //       // this.btnLoading = false;
    //     } else {
    //       this.resultData = response.data.data;
    //       // const dataToStore = JSON.stringify(this.resultData);
    //       // localStorage.setItem('resultData', dataToStore);
    //       // this.reportDialog = true;
    //       this.$router.push(`/testreport/${this.id}/${data.userId}`)
    //       this.switchResult(0);
    //       this.selectedUser = data.userId;
    //       this.selectedUserName = data.personalInfo.firstName + " " + data.personalInfo.middleName + " " + data.personalInfo.lastName
    //       this.bubbleChartOptions.title = this.selectedUserName
    //       // console.log("this.selectedUser...>>>", this.selectedUser)
    //       // console.log("this.id...>>>", this.id)

    //       const videoRef = collection(db, "campaigns", this.id, "audience", data.userId, "demovideos");
    //       // console.log("demo video reference..??", videoRef)

    //       onSnapshot(videoRef, async (videoSnapshot) => {

    //         this.dataDemoVideos.splice(0)

    //         // console.log("docs :::", videoSnapshot.size)

    //         videoSnapshot.forEach((v) => {
    //           const videoData = v.data();
    //           const videoId = v.id;
    //           this.dataDemoVideos.push({ name: data.personalInfo.firstName, email: data.personalInfo.email, videoId: videoId, ...videoData })
    //         })

    //         // console.log("data demo videos after video snapshot...??", this.dataDemoVideos)
    //       })

    //       const testRef = doc(
    //         db,
    //         "proctoredtests",
    //         this.id,
    //         this.selectedUser,
    //         "SCREENING"
    //       );

    //       const test = await getDoc(testRef);
    //       this.proctoringData = {
    //         "Copy/Paste": 0,
    //         "Exit Fullscreen": 0,
    //         Screenshot: 0,
    //         "Switch Tab/Browser": 0,
    //       };

    //       if (test.exists()) {
    //         // console.log(test.data())
    //         this.userProctoringActivities.splice(0)
    //         this.cumulativeProctoringData.splice(1)
    //         for (const temp of test.data().activities) {
    //           if (temp.activity in this.proctoringData) {
    //             // console.log(temp)
    //             this.proctoringData[temp.activity] += 1;
    //           }
    //           this.userProctoringActivities.push(temp);

    //           if (temp.activity !== "Started" && temp.activity !== "Completed") {
    //             let flag = true;
    //             for (let i = 0; i < this.cumulativeProctoringData.length; i++) {
    //               // if activitiy already exists
    //               if (this.cumulativeProctoringData[i][0] === temp.activity) {
    //                 this.cumulativeProctoringData[i][1] += 1
    //                 flag = false;
    //               }
    //             }
    //             // if a new activity is found
    //             if (flag) {
    //               this.cumulativeProctoringData.push([temp.activity, 1, this.generateRandomColor()]);
    //             }

    //           }

    //         }
    //         this.addDataToChart();

    //         // console.log("cumulativeProcChartData :", this.cumulativeProctoringData);

    //         // creating cumulative data
    //         // for (const act of this.userProctoringActivities){
    //         //   if (act.activity!=="Started" && act.activity!=="Completed"){
    //         //     let flag=true;
    //         //     for (let i=0; i<this.cumulativeProctoringData.length; i++){
    //         //       // if activitiy already exists
    //         //       if (this.cumulativeProctoringData[i][0]===act.activity){
    //         //         this.cumulativeProctoringData[i][1]+=1
    //         //         flag=false;
    //         //       }
    //         //     }
    //         //     // if a new activity is found
    //         //     if (flag){
    //         //       this.cumulativeProctoringData.push([act.activity, 1]);
    //         //     }

    //         //   }
    //         // }

    //         // console.log("cumulative Data :", this.cumulativeProctoringData);

    //       }

    //       // this.btnLoading = false;
    //     }
    //   } else {
    //     alert(response.data.error);
    //   }

    //   // console.log("data here", data);
    //   // if (data.result == null) {
    //   //   alert("User test not Started");
    //   //   this.btnLoading = false;
    //   // } else {
    //   //   (this.reportDialog = true),
    //   //     (this.questions = data.result.questions),
    //   //     this.getSkillsArray(data.result.questions),
    //   //     (this.resultData = data.result);
    //   //   this.btnLoading = false;

    //   //   // console.log("questions", data);
    //   // }

    //   //  "reportDialog = true; questions= item.questions"
    // },

    searchData(search) {
      let user = this.preSearchUser;
      let searchString = this.removeSpaces(search);
      let usersList = user.filter((item) => {
        let searchByName = this.removeSpaces(item.personalInfo?.firstName +  item.personalInfo?.lastName)
        let searchByEmail = this.removeSpaces(item.personalInfo.contactInfo?.email)
        return  new RegExp(searchString, "i").test(searchByName) ||  new RegExp(searchString, "i").test(searchByEmail)
      }
      );
      this.users = this.calculateUsersForLeague(usersList);
    },
    removeSpaces(str = ""){
      return str.replace(/\s/g, '');
    },
    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    filterByStatus(mainArray, statusToFilter) {
      return mainArray.filter((item) =>
        item.campaignStatus.some(
          (nestedItem) => nestedItem.status === statusToFilter
        )
      );
    },
    createStepperData(data){
      data?.forEach((item, index)=>{
        let obj = {invitation: { name:'mdi-check-circle', iconColor: 'green'}, 
                 screening:{  },
                 mains:{ },
                 demo:{ },
                 interview:{}}
        let status = item?.campaignStatus
        let userType = item?.userType || "Undefined";
        if(userType?.toLowerCase() === "teacher"){
        if(status[1]?.status === "MAINS STARTED"){
          // For Teachers we are storing mains data in screening as it is rendering screnning data
          obj['screening'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        }
        else{
          obj['screening'] = this.getStepperIconAndColor("","")
        }
      }
        else if(userType?.toLowerCase() === "ntp"){
          let mainsStepAndIcon = {...this.getStepTypeAndIcon('mains', item,  status[2]?.status), ...this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))}
          obj['mains'] = { marks: item?.mainsTotal?.toFixed(0), ...mainsStepAndIcon };
        }
        else{
        let screeningStepAndIcon = {...this.getStepTypeAndIcon('screening', item,  status[2]?.status), ...this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))}
        let mainsStepAndIcon = {...this.getStepTypeAndIcon('mains', item,  status[4]?.status), ...this.getStepperIconAndColor(this.getPassFailGenericVal(status[3]?.status), this.getPassFailGenericVal(status[4]?.status))}
        let demoStepAndIcon = {...this.getStepTypeAndIcon('demo', item,  status[6]?.status), ...this.getStepperIconAndColor(this.getPassFailGenericVal(status[5]?.status), this.getPassFailGenericVal(status[6]?.status))}
        let interveiwStepAndIcon = {...this.getStepTypeAndIcon('interview', item,  status[8]?.status), ...this.getStepperIconAndColor(this.getPassFailGenericVal(status[7]?.status), this.getPassFailGenericVal(status[8]?.status))}
        obj['screening'] = {marks: item?.screeningTotal?.toFixed(0), ...screeningStepAndIcon}
        obj['mains'] = { marks: item?.mainsTotal?.toFixed(0), ...mainsStepAndIcon }
        obj['demo'] = { marks: item?.cumulativeNetDemoScore?.toFixed(0),...demoStepAndIcon}
        obj['interview'] = { marks: this.calculateInterview(item?.interViewFeedback)?.toFixed(0), ...interveiwStepAndIcon}
        }
        this.stepper.set(index, obj)
        obj = {}
      })
      console.log('stepper',this.stepper)
    },
    getStepTypeAndIcon(stage, data, stateStatus){
      let curStatus = this.getCurrentStatus(data?.campaignStatus)
      let passFailVal = this.getPassFailGenericVal(stateStatus)
      let isHREvaluate = this.showIcon(stage, curStatus,  data?.isHREvaluate)
      let isTakeLater = this.showIcon(stage, curStatus,  data?.isTakeLater)
      let iconName = isHREvaluate ? 'hourglass.svg' :  isTakeLater ? 'schedule_1.svg' : ''
      return {
        iconName,
        stepType: ['failed', 'passed'].includes(passFailVal) ? 'showMarks' : isHREvaluate || isTakeLater ? 'showActionIcon' : 'showStepperIcon'
      }
    },
    getStepperIconAndColor(prevStatus = '', curStatus = ''){
      let map = new Map([
        ['started', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['startedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['startedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failed',{name:'mdi-circle-double', iconColor: 'grey'}],
        ['failedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['passed', {name:'mdi-circle-double', iconColor: 'grey'}],
        ['passedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['passedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['', {name:'mdi-circle-double', iconColor: 'grey'}],

      ])
     let key = prevStatus+curStatus.trim()
      return map.get(key)

    },
    getPassFailGenericVal(status){
      if(status !== null && status !== undefined && status !== ''){
        return status.split(' ')[1]?.toLowerCase().trim()
      } else{   return ''}
  
    },
    addDemoInterviewScore(){
     this.users =  this.users.map((user)=>{
        if(user?.demoStatus){
          if(user.userType !== 'Teacher' && user.demoStatus) {
            user.enableScheduleInterview = true
          }
          if(user.campaignStatus.filter((item)=> item.status.includes('DEMO')).length == 0){
            user.campaignStatus.push({status: 'DEMO STARTED'})
           user.campaignStatus.push({status: 'DEMO PASSED'}) 
          }
                  
        }
        if(user?.interViewFeedback){
          if(user.campaignStatus.filter((item)=> item.status.includes('INTERVIEW')).length == 0){
            user.campaignStatus.push({status: 'INTERVIEW STARTED'})
           user.campaignStatus.push({status: 'INTERVIEW PASSED'})
          }       
        }
        if(user?.interviewFinalStatus?.status) {
          user.enableScheduleInterview = user.interviewFinalStatus.status === "NOTIFY_HR" ? true : false
        }
        return user
      })
     
    },
    async fetchCampaignAudience() {
      
      if(this.selectedClusterFilters.length > 0 || this.selectedBoardsFilter.length > 0)
      {
        this.selectedSort = "";
        this.sortCol = "mainsTotal";
        this.sortOrder = "desc";
        this.isFilterLoading = true;
      }
      this.preSearchUser = [];
      this.users = [];
      this.dataTableLoading = true;
      let query = "?campaignId="+this.id+"&itemsPerPage="+this.pageSize+"&page="+this.page+"&sortOrder="+this.sortOrder+"&sortCol="+this.sortCol;
      if (this.search) {
        query += "&search="+this.search
      }
    
      if (this.currentSelectedStatus != null ) {
        
        console.log('this.currentSelectedStatus',this.currentSelectedStatus)
        console.log('status in if')
        if(this.currentSelectedStatus != 'ALL'){
          query += `&status=${this.currentSelectedStatus}`;
        }
        
      }
      const audienceData = await CampaignController.getCampaignAudience(query, this.selectedClusterFilters, this.selectedBoardsFilter);
      this.preSearchUser = audienceData.result.users
      this.totalUsers = audienceData.result.totalUsers;
      this.campaignName = audienceData.result.campaignName;
      console.log(this.totalUsers);
      let preUsers = this.preSearchUser
      
      this.users = this.preSearchUser;
      this.users = this.calculateUsersForLeague(preUsers);
          this.users=this.users.map(user=> 
            {
              if(user.coreSkillsSubject.length > 0 && user.coreSkillsSubject != undefined){
                  let leagueArray = user.coreSkillsSubject.map(subject => subject.league );
                  user['NoLeague'] = leagueArray.every((item)=>{return item=='Not Eligible'});
              }
              return user;
            }
          )
          this.addDemoInterviewScore()
          this.createStepperData(this.users)
          this.addInterviewScore(this.users)
          console.log(this.users)
      this.dataTableLoading = false;
      this.isFilterLoading = false;
      this.filterDialog = false;
    },
    async setupSnapshotListenerFunnel(id) {

    const dashboardTeachersRef = doc(
    db,
    "dashboards",
    "management-dashboard-001",
    "campaigns",
    id
  );

  try {
    let unsubscribe =  onSnapshot(dashboardTeachersRef, (snapshot) => {
    this.chartData = { ...this.emptyChartData };

    const resultData = snapshot.data();

    // Define the required keys
    const requiredKeys = [
      "invited",
      "screeningStarted",
      "screeningPassed",
      "screeningFailed",
      "mainsStarted",
      "mainsPassed",
      "mainsFailed",
      "demoSubmitted",
      "interviewSubmitted",
    ];

    // Check if any required key is missing
    const hasMissingData = requiredKeys.some((key) => resultData[key] === undefined);

    if (hasMissingData) {
      return;
    }

    // Assign resultData to chartData and component-level variables
    Object.assign(this.chartData, resultData);

    // Map specific variables to chartData keys
    this.invited = this.chartData.invited;
    this.screeningStarted = this.chartData.screeningStarted;
    this.screeningPassed = this.chartData.screeningPassed;
    this.mainsStarted = this.chartData.mainsStarted;
    this.mainsPassed = this.chartData.mainsPassed;
    this.screeningFailed = this.chartData.screeningFailed;
    this.mainsFailed = this.chartData.mainsFailed;
    this.apraisals = this.chartData.mainsPassed;
    this.pip = this.chartData.mainsFailed;
    this.tbd = this.chartData.screeningFailed;
  });
  this.liveListenersToUnsubscribeMixin.push(unsubscribe);

  } catch (error) {
    console.error("Error checking document existence or setting up snapshot:", error);
  }
  this.preloader = false;
  this.dataTableLoading = false;
},

async fetchCampaignAudienceOld(id) {
        const campaignRef = collection(db, "campaigns", id, "audience");
        const campDetailsRef = doc(db, "campaigns", id);

        const docSnap = await getDoc(campDetailsRef);

        if (docSnap.exists()) {
          // console.log("camp details here..?", docSnap.data())
          const campDetails = docSnap.data();
          this.campName = campDetails.name;
          this.campaignDetails = campDetails
          // console.log("name of the campaign..?", this.campName)
        }

        // console.log("heyyylooooo");
        let unsubscribe = onSnapshot(campaignRef, (snapshot) => {
          this.preloader = false;
          this.dataTableLoading = false;
          this.users = [];
          this.preSearchUser = [];
          var preUsers = [];
          if (snapshot.empty) {
          } else {
            this.count = snapshot.docs.length;
            snapshot.docs.forEach(async (doc) => {
              // this.users.push(doc.data());
              this.preSearchUser.push(doc.data());
              preUsers.push(doc.data());
            });
            this.users = this.calculateUsersForLeague(preUsers);
            this.users = this.users.map(user => {
              if (user.coreSkillsSubject.length > 0 && user.coreSkillsSubject != undefined) {
                let leagueArray = user.coreSkillsSubject.map(subject => subject.league);
                user['NoLeague'] = leagueArray.every((item) => { return item == 'Not Eligible' });
              }
              return user;
            }
            )
            this.addDemoInterviewScore()
            this.createStepperData(this.users)
            this.csvData = this.users
            // console.log("preUsers", preUsers);

            this.chartData = this.filterByStatus(preUsers, "INVITED");

            this.invited = this.filterByStatus(preUsers, "INVITED").length;
            this.screeningStarted = this.filterByStatus(
              preUsers,
              "SCREENING STARTED"
            ).length;
            this.screeningPassed = this.filterByStatus(
              preUsers,
              "SCREENING PASSED"
            ).length;
            this.mainsStarted = this.filterByStatus(
              preUsers,
              "MAINS STARTED"
            ).length;
            this.mainsPassed = this.filterByStatus(
              preUsers,
              "MAINS PASSED"
            ).length;
            this.screeningFailed = this.filterByStatus(
              preUsers,
              "SCREENING FAILED"
            ).length;
            this.mainsFailed = this.filterByStatus(
              preUsers,
              "MAINS FAILED"
            ).length;

            this.chartData.invited = this.filterByStatus(
              preUsers,
              "INVITED"
            ).length;
            this.chartData.screeningStarted = this.filterByStatus(
              preUsers,
              "SCREENING STARTED"
            ).length;
            this.chartData.screeningPassed = this.filterByStatus(
              preUsers,
              "SCREENING PASSED"
            ).length;
            this.chartData.mainsStarted = this.filterByStatus(
              preUsers,
              "MAINS STARTED"
            ).length;
            this.chartData.mainsPassed = this.filterByStatus(
              preUsers,
              "MAINS PASSED"
            ).length;
            this.chartData.screeningFailed = this.filterByStatus(
              preUsers,
              "SCREENING FAILED"
            ).length;
            this.chartData.mainsFailed = this.filterByStatus(
              preUsers,
              "MAINS FAILED"
            ).length;
            this.chartData.demoSubmitted = this.filterByStatus(preUsers, "DEMO PASSED").length;
            this.chartData.interviewSubmitted = this.filterByStatus(preUsers, "INTERVIEW STARTED").length;
            this.apraisals = this.filterByStatus(preUsers, "MAINS PASSED").length;
            this.pip = this.filterByStatus(preUsers, "MAINS FAILED").length;
            this.tbd = this.filterByStatus(preUsers, "SCREENING FAILED").length;
          }
          // console.log("this.chartData", this.chartData)
        });
        this.liveListenersToUnsubscribeMixin.push(unsubscribe);
        // onSnapshot(campDetailsRef, (snapshot)=>{

        // })

        // const response = await CampaignController.fetchCampaignAudience(id);
        // if (response.data.flag) {
        //   // this.preloader = false;
        //   // this.count = response.data.count;
        //   // this.dataTableLoading = false;
        //   if (response.data.audience != undefined) {
        //     this.users = response.data.audience;
        //     this.preSearchUser = response.data.audience;
        //     var preUsers = response.data.audience == null ? [] : response.data.audience;
        //     this.chartData.invited = this.filterByStatus(preUsers, 'INVITED').length;
        //     this.invited = this.filterByStatus(preUsers, 'INVITED').length;
        //     this.screeningStarted = this.filterByStatus(preUsers, 'SCREENING STARTED').length;
        //     this.screeningPassed = this.filterByStatus(preUsers, 'SCREENING PASSED').length;
        //     this.mainsStarted = this.filterByStatus(preUsers, 'MAINS STARTED').length;
        //     this.mainsPassed = this.filterByStatus(preUsers, 'MAINS PASSED').length;
        //     this.screeningFailed = this.filterByStatus(preUsers, 'SCREENING FAILED').length;
        //     this.mainsFailed = this.filterByStatus(preUsers, 'MAINS FAILED').length;
        //     this.chartData.screeningStarted = this.filterByStatus(preUsers, 'SCREENING STARTED').length;
        //     this.chartData.screeningPassed = this.filterByStatus(preUsers, 'SCREENING PASSED').length;
        //     this.chartData.mainsStarted = this.filterByStatus(preUsers, 'MAINS STARTED').length;
        //     this.chartData.mainsPassed = this.filterByStatus(preUsers, 'MAINS PASSED').length;
        //     this.chartData.screeningFailed = this.filterByStatus(preUsers, 'SCREENING FAILED').length;
        //     this.chartData.mainsFailed = this.filterByStatus(preUsers, 'MAINS FAILED').length;
        //     this.apraisals = this.filterByStatus(preUsers, 'MAINS PASSED').length;
        //     this.pip = this.filterByStatus(preUsers, 'MAINS FAILED').length;
        //     this.tbd = this.filterByStatus(preUsers, 'SCREENING FAILED').length;
        //   }
        // } else {
        //   alert(response.data.error);
        // }
      },

    calculateUsersForLeague(users) {
      users.forEach((user) => {
        var coreSkills = [];
        if (user.coreSkillsSubject !== undefined) {
          user.coreSkillsSubject.forEach((coreSkillSubject) => {
            if (coreSkillSubject.result == "PASSED") {
              coreSkills.push(coreSkillSubject);
            }
          });
        }
        user.coreSkillsSubject = coreSkills;
      });
      return users;
    },
    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();

      if (response.status == 200) {
        // console.log(response);
        if (response.data.clusters.length > 0) {
          this.clusters = response.data.clusters;
        } else {
          this.clusters = [];
        }
        // console.log("afkdsbxbfabsbdj,a", this.clusters);
      } else {
        alert(response.data.error);
      }
    },
    async getBoards() {
      const response = await BoardController.getBoards();
      if (response.status == 200) {
        this.count = response.data.count;
        if (response.data.boards.length > 0) {
          //  console.log("vall" , response.data.boards);
          this.boardsData = response.data.boards;
        }
      } else {
        alert(response.data.error);
      }
    },
    getHrEvaluationStage(obj){
      return Object.entries(obj).find((item)=> item[1].iconName == 'hourglass.svg')[0]
    },

    getLatestStatus(campaignStatus) {
      return campaignStatus[campaignStatus.length - 1]?.status;
    },

    canRestartExam(campaignStatus,item) {
     
      if (item.demoStatus === true || ['NTP'].includes(item.userType)) {
        return false;
      }
    const latestStatus = this.getLatestStatus(campaignStatus);
    return latestStatus !== 'INVITED';
},
    handleRestartClick(item) {
      this.restartAssessmentDialog = true;
      this.userForClearAssessment = item.userId;
      this.currentUserType = item.userType;
      this.currentCampaignStatus = this.getLatestStatus(item.campaignStatus);
    },
    handleInterviewHistoryClick(data){
      this.$router.push({
        path: "/interview-history",
        name: "InterviewHistory",
        query: {
          campaignId: this.id,
          userId: data.userId,
        },
      });
    },

    async clearUserAssessment(userId){
      this.restartAssessmentLoader = true;
      const response = await CampaignController.clearUserAssessment(userId, this.id,this.$store.state.userInfo.email);
      this.restartAssessmentDialog = false;
      this.restartAssessmentLoader = false;
      if(response.flag == true){
        this.clearUserAssessmentSuccessSnackbar = true;
      } else {
        alert(response.result);
        this.clearUserAssessmentFailureSnackbar = true;
      }
    },
    showIcon(stage = "", curStatus = "", flag = false){
        if(stage == 'screening' && curStatus == 'invited'){
          return   flag
        }else if(stage == 'mains' && curStatus == 'screening'){
          return   flag
        } else if(stage == 'demo' && curStatus == 'mains'){
          return flag
        }
    },
    getCurrentStatus(data){ 
      return data[data.length -1]?.status.split(' ')[0].toLowerCase()
    },
    // async clearUserAssessment(userId){
    //   this.restartAssessmentLoader = true;
    //   const response = await CampaignController.clearUserAssessment(userId, this.id);
    //   this.restartAssessmentDialog = false;
    //   this.restartAssessmentLoader = false;
    //   if(response.flag == true){
    //     this.clearUserAssessmentSuccessSnackbar = true;
    //   } else {
    //     alert(response.result);
    //     this.clearUserAssessmentFailureSnackbar = true;
    //   }
    // },

    // async recommendedJobseekerCountListener() {
    //   try {
    //     const campaignRef = query(collection(db, "campaigns"), where("id", "==", this.id));

    //     // Real-time listener for campaign documents
    //     onSnapshot(campaignRef, async (snapshot) => {
    //       this.recommendedJobseekerCount = 0; // Reset count at the start of each snapshot

    //       // Iterate over all campaign documents
    //       for (const doc of snapshot.docs) {
    //         const campaignData = doc.data();

    //         // Ensure it's a valid job seeker audience
    //         const audienceType = campaignData?.audienceType?.toLowerCase();
    //         if (audienceType === "job_seeker" || audienceType === "jobseeker" || audienceType === "job seeker") {
    //           try {
    //             const jobSeekerSchoolCluster = campaignData.clusters ?? [];
    //             const jobSeekerRef = collection(db, "campaigns", doc.id, "audience"); // Use correct ref to audience sub-collection
    //             const jobSeekDocs = await getDocs(jobSeekerRef);

    //             // Loop through job seeker documents
    //             for (const d of jobSeekDocs.docs) {
    //               const jobSeekerData = d.data();
    //               const schoolName = this.$store.state.role.schools;
    //               const clusterName = this.$store.state.role.clusterName;
    //               const jobSeekerSchoolName = jobSeekerData.teachingInterests?.schools ?? [];

    //               // Apply the filter logic
    //               if (
    //                 (clusterName === "All Clusters" && schoolName === "All") ||
    //                 (clusterName !== "All Clusters" &&
    //                   schoolName === "All" &&
    //                   ((typeof jobSeekerSchoolCluster === "string" && jobSeekerSchoolCluster === clusterName) ||
    //                     (Array.isArray(jobSeekerSchoolCluster) && jobSeekerSchoolCluster.includes(clusterName)))) ||
    //                 (clusterName !== "All Clusters" &&
    //                   ((typeof jobSeekerSchoolName === "string" && jobSeekerSchoolName === schoolName) ||
    //                     (Array.isArray(jobSeekerSchoolName) && jobSeekerSchoolName.includes(schoolName))))
    //               ) {
    //                 // Condition to determine recommendation eligibility
    //                 if (
    //                   jobSeekerData?.campaignStatus?.length === 5 &&
    //                   jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" &&
    //                   jobSeekerData.campaignStatus[4].status === "MAINS PASSED" &&
    //                   jobSeekerData?.coreSkillsSubject?.length > 0 &&
    //                   jobSeekerData?.interViewFeedback?.length > 0
    //                 ) {
    //                   let netScoreMains = 0;
    //                   let netScoreInterview = 0;

    //                   // Calculate Mains Score
    //                   jobSeekerData.coreSkillsSubject.forEach((dt) => {
    //                     if (dt.league !== "Not Eligible") {
    //                       netScoreMains += parseFloat(dt.finalNetScore || 0);
    //                     }
    //                   });

    //                   // Calculate Interview Score
    //                   jobSeekerData.interViewFeedback.forEach((interview) => {
    //                     netScoreInterview += parseFloat(interview.avgScore || 0);
    //                   });

    //                   // Calculate averages
    //                   netScoreMains = netScoreMains / jobSeekerData.coreSkillsSubject.length;
    //                   netScoreInterview = netScoreInterview / jobSeekerData.interViewFeedback.length;

    //                   // Final Recommendation Score
    //                   const netFinalRecommendationScore = (netScoreMains + netScoreInterview) / 2;

    //                   // Increment count if the score meets criteria
    //                   if (netFinalRecommendationScore >= 0) {
    //                     this.recommendedJobseekerCount++;
    //                   }
    //                 }
    //               }
    //             }
    //           } catch (error) {
    //             console.error("Error fetching job seeker data:", error);
    //           }
    //         }
    //       }
    //     });
    //   } catch (error) {
    //     console.error("Error setting up jobseeker listener:", error);
    //   }
    // },
    
    async recommendedJobseekerCountListener() {
      const campaignDocRef = doc(db, "campaigns", this.id);
      let unsubscribe = onSnapshot(campaignDocRef, async (docSnapshot) => {
        this.recommendedJobseekerCount = 0;
        if (docSnapshot.exists()) {
          const campaignData = docSnapshot.data();
          // Check if the document is for job seekers
          if (campaignData.audienceType.toLowerCase() === "job_seeker" || 
              campaignData.audienceType.toLowerCase() === "jobseeker" || 
              campaignData.audienceType.toLowerCase() === "job seeker") {
            try {
              const jobSeekerSchoolCluster = campaignData.clusters ?? [];
    
              const jobSeekerRef = collection(campaignDocRef, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);
    
              // Process each job seeker document in the audience sub-collection
              for (const d of jobSeekDocs.docs) {
                const jobSeekerData = d.data();  
    
                const schoolName = this.$store.state.role.schools;
                const clusterName = this.$store.state.role.clusterName;
                const jobSeekerSchoolName = jobSeekerData.teachingInterests?.schools ?? [];
    
                if (
                  (clusterName === "All Clusters" && schoolName === "All") ||
                  (clusterName !== "All Clusters" && schoolName === "All" && 
                    ((typeof jobSeekerSchoolCluster === 'string' && jobSeekerSchoolCluster === clusterName) || 
                    (Array.isArray(jobSeekerSchoolCluster) && jobSeekerSchoolCluster.includes(clusterName)))) ||
                  (clusterName !== "All Clusters" && 
                    ((typeof jobSeekerSchoolName === 'string' && jobSeekerSchoolName === schoolName) || 
                    (Array.isArray(jobSeekerSchoolName) && jobSeekerSchoolName.includes(schoolName))))
                ) {
                  if (jobSeekerData?.campaignStatus?.length === 5 && 
                      jobSeekerData?.demoStatus && 
                      jobSeekerData?.coreSkillsSubject?.length > 0 && 
                      jobSeekerData?.interViewFeedback?.length > 0) {
                    let netScoreMains = 0;
                    let netScoreInterview = 0;
                    for (const dt of jobSeekerData.coreSkillsSubject) {
                      if (dt.league !== "Not Eligible") {
                        try {
                          netScoreMains += parseFloat(dt.finalNetScore);
                        } catch (error) {
                          console.error("Error parsing mains score:", error);
                        }
                      }
                    }
    
                    for (const interview of jobSeekerData.interViewFeedback) {                    
                      try {
                        netScoreInterview += parseFloat(interview.avgScore);                      
                      } catch (error) {
                        console.error("Error parsing interview score:", error);
                      }                    
                    }
                    netScoreMains /= jobSeekerData.coreSkillsSubject.length;
                    netScoreInterview /= jobSeekerData.interViewFeedback.length;
                    const netFinalRecommendationScore = (netScoreMains + netScoreInterview) / 2;
                    if (netFinalRecommendationScore >= 0) {
                      this.recommendedJobseekerCount += 1;
                    }
                  }
                }
              }
            } catch (error) {
              console.error("Error processing campaign document:", error);
            }
          }
        } else {
          console.log("Campaign document does not exist");
        }
      });
      this.liveListenersToUnsubscribeMixin.push(unsubscribe);
    },

    async goToRecommendedForHiring() {
      try {
        this.$router.push({
          path: "/jobseekerstbd",
          name: "jobseekerstbd",
          query: {
            id: this.id,
          },
        });
      } catch (error) {
        console.error("Error navigating to recommended job seekers:", error);
      }
    },
    async goToJobSeekersInterviewed() {
      try {
        this.$router.push({
          path: "/jobseekerspip",
          name: "jobseekerspip",
          query: {
            id: this.id,
          },
        });
      } catch (error) {
        console.error("Error navigating to recommended job seekers:", error);
      }
    },
    async goToJobSeekersAppraisal() {
      try {
        this.$router.push({
          path: "/jobseekersappraisal",
          name: "jobseekersappraisal",
          query: {
            id: this.id,
          },
        });
      } catch (error) {
        console.error("Error navigating to recommended job seekers:", error);
      }
    },
    handleSearchInput() {
      this.debouncedSearch();
      this.updateUrlParams();
    },
    performSearch() {
      this.page = 1
      this.currentPage = 1
      this.fetchCampaignAudience()
    },
    updateUrlParams() {
      const query = {
        id:this.id,
        campaignType: this.campaignType,
      };
      
      if (this.search) {
        query.search = this.search;
      }

      this.$router.replace({ 
        query: query 
      }).catch(() => {});
    },
    selectSortOption(sortOption) {
      this.selectedSort = sortOption;
      switch (sortOption) {
        case "nameAsc":
          this.sortCol = "name";
          this.sortOrder = "asc";
          break;
        case "nameDesc":
          this.sortCol = "name";
          this.sortOrder = "desc";
          break;
        case "emailAsc":
          this.sortCol = "email";
          this.sortOrder = "asc";
          break;
        case "emailDesc":
          this.sortCol = "email";
          this.sortOrder = "desc";
          break;
        case "dateNewest":
          this.sortCol = "createdOn";
          this.sortOrder = "desc";
          break;
        case "dateOldest":
          this.sortCol = "createdOn";
          this.sortOrder = "asc";
          break;
        default:
          this.selectedSort = "";
          this.sortCol = "mainsTotal";
          this.sortOrder = "desc";
      }
      this.options.page = 1;
      this.fetchCampaignAudience();
    },
  },

  created() {
    // this.setupSnapshotListenerFunnel();
    this.id = this.$route.query.id;
    this.setupSnapshotListenerFunnel(this.id);
    this.campaignType = this.$route.query.campaignType;
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    this.debouncedSearch = debounce(this.performSearch, 300);
    this.fetchAllClusters();
    this.getBoards();
    // this.getResult();
  },
};
</script>
<style scoped>
.disable-class{
  opacity: 0.5;
  cursor: default;
}
.multi-line-ellipsis{
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines you want to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: auto;
}
.stepper-icon{
  height: auto;

}
.pass-step{
  color: green
}
.fail-step{
  color: var(--error);
}
.cardBorder {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin: 0px;
}

.mdi-chevron {
  font-size: 24px !important;
  /* Set the desired size for the icon */
}

.rounded-table {
  border-radius: 8px 8px 0px 0px !important;
}

.viewTestProctoringBox {
  border: 2px solid black;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
}
.disabled-icon {
  opacity: 0.5;
  cursor: not-allowed;
}
::v-deep .v-data-table-header th {
  font-weight: 500;      /* Make headers bold */
  color: #333;   
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 14px !important;
}

.action-row {
  position: relative;
}

.notification-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  background: var(--light-red);
  font-size: 10px;
  border-radius: 20px;
  line-height: 16px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: 0px;
}

.v-chip {
  padding: 11px 16px !important;
}

.accepted-status {
  background: var(--success-03) !important;
  color: var(--success-02);
}

.scheduled-status {
  color: blue;
  background: #3F41D12B !important;
}

.not-scheduled-status {
  background: var(--gray-03) !important;
  color: var(--gray-04);
}

.rejected-status {
  background: var(--error-03) !important;
  color: var(--red);
}

.on-hold-status {
  background: var(--light-yellow) !important;
  color: var(--orange);
}

.notify-hr-status {
  background: #EFDCF8  !important;
  color: #AF52DE;
}


.hiring-manager-status {
  background: #E8E3FF !important;
  color: #6D54E1;
}

.interview-completed-status {
  background: #CAEDF5 !important;
  color: #166475;
}

.copy-image {
  width: 24px;
  height: 24px;
}


</style>