<template>
    <div>
      <v-col>
        <v-card height="64px" width="100%"
          class="ml-4 pl-1 pt-5 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
          <v-card-title class="pl-0 ml-0">
            <span @click="$router.push('/')" class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</span>
  
            <span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></span><v-menu offset-y>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="breadcrumb-text">
                  Campaign Result
                  <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
                </span>
              </template>
  
              <v-list>
                <v-list-item @click="$router.push('/schoolteachersappraisal')">No. of teachers to be Appraised</v-list-item>
                <v-list-item @click="$router.push('/schoolteacherspip')">No. of teachers on PIP</v-list-item>
                <v-list-item @click="$router.push('/schoolteacherstbd')">No. of teachers on TBD</v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <div class="size-menu d-flex flex-row justify-start w-fit align-center fixLog">
            <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
            </v-badge>
            <div>
              <img src="../assets/avtar.png" class="rounded-xl mr-2" width="24" height="24" />
            </div>
            <div class="d-flex flex-column d-justify-evenly user-details">
              <v-card-title class="ma-0 pa-0 elipsis">
                {{ $store.state.userInfo.name }}
              </v-card-title>
              <v-card-subtitle class="ma-0 pa-0 elipsis">
                {{ $store.state.userInfo.email }}
              </v-card-subtitle>
            </div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                  <v-icon class="">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-btn @click="logout">logout</v-btn>
            </v-menu>
          </div>
        </v-card>
  
        <div class="pad">
  
          <!-- <v-container class="funnel-contianer pl-0 pr-3 ml-3" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 campaign-funnel-card">
                <v-card class="rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pb-0 pr-0 pl-0">
                  <p class="font-weight-bold pl-2 funnelText">TEACHERS</p>
                  </v-card-title>
                  <v-card-text class="pt-0 pr-0 pl-0">
                    <span class="pl-2 black--text">Teachers Invited : {{ schoolChartData.totalInvited }}
                    </span>
                    <FunnelChartSchool :school="schoolChartData" />
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        <b>
                          {{
                            calculatePercentage(
                              schoolChartData.totalInvited -
                                schoolChartData.totalMainsStarted,
                              schoolChartData.totalInvited
                            )
                          }}%
                        </b>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 campaign-funnel-card">
                <v-card class="rounded-lg" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                  <p class="font-weight-bold pl-2 funnelText">MAINS</p>
                  </v-card-title>
                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-2 black--text">Attempted : {{ schoolChartData.totalMainsStarted }}</span>
                    <FunnelChartSchool3 :school="schoolChartData" />
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        <b>
                          {{
                            calculatePercentage(
                              schoolChartData.totalMainsStarted -
                                schoolChartData.totalMainsPassed,
                              schoolChartData.totalMainsStarted
                            )
                          }}%
                        </b>
                      </div>
                    </div>
                    
                    <!-- <img src="DownIcon.png" alt="Icon" style="margin-top: auto;"> -->
                  <!-- </v-card-text>
                
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 campaign-funnel-card">
                <v-card class="rounded-lg" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                  <p class="font-weight-bold pl-2 funnelText">RESULT</p>
                  </v-card-title>
                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-2 black--text">Passed : {{ schoolChartData.totalMainsPassed }}</span>
                    <FunnelChartSchool4 :school="schoolChartData" /> -->
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        <b>
                          {{
                            calculatePercentage(
                              schoolChartData.totalMainsPassed,
                              schoolChartData.totalMainsStarted
                            )
                          }}%
                        </b>
                      </div>
                    </div> -->
                    
                  <!-- </v-card-text>
                </v-card>
              </div> -->
              <!-- <div class="rounded-lg px-0 py-0 pl-1 campaign-funnel-card">
                <v-card class="rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                  <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Teachers Passed : {{ schoolChartData.totalMainsPassed }}
                    <v-spacer></v-spacer> -->
                    <!-- <v-icon>mdi-chevron-right</v-icon> -->
                  <!-- </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Mains Failed : {{ schoolChartData.totalMainsFailed }}
                    <v-spacer></v-spacer> -->
                    <!-- <v-icon>mdi-chevron-right</v-icon> -->
                  <!-- </v-card>
                </v-card>
              </div>
            </div>
          </v-container>
   -->
   <v-container class="funnel-contianer pb-0 pr-7 ml-2" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      TEACHERS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Registered/Invited</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="invited" propertyEnd="mainsStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.invited -
                            schoolChartData.mainsStarted,
                            schoolChartData.invited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            schoolChartData.mainsStarted -
                            schoolChartData.mainsPassed,
                            schoolChartData.mainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>
    
                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="schoolChartData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="mainsPassed"/>
                    <!-- <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.mainsPassed -
                              funnelTeachersData.mainsStarted,
                            funnelTeachersData.mainsPassed
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-container>  
          <v-row class="d-flex justify-center pt-0 my-0 ml-3 pr-1" fluid>
            <v-col cols="4" md="4" sm="4">
              <div class="text-h6 font-weight-medium highEmphasis">
                Campaign Result
              </div>
            </v-col>
  
            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field v-model="search" solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>
  
              <v-btn depressed rounded outlined class="btn outline-btn mx-3" :disabled="true"  @click=""><v-icon>mdi-tune</v-icon>FILTER</v-btn>
             
  
              <v-btn depressed outlined rounded class="btn outline-btn mx-3" @click="downloadCampaignTeachersResults()" :loading="downloadLoading"><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-card class="mx-5 mt-2 rounded-table">
            <v-data-table
              :headers="headers"
              :items="displayedTeachers"
              :items-per-page="itemsPerPage"
              :page.sync="currentPage"
              :server-items-length="totalItems"
              :loading="loading"
              :options.sync="options"
              class="elevation-0 custom-data-table"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 20, 25,-1]
              }"
            > 
            <template v-slot:loading>
              <bubble-loader/>
            </template>
            <template v-slot:no-data>
              <div class="custom-no-data">
                <p>No Data Available</p>
              </div>
            </template>
              <!-- S.No Column -->
              <template v-slot:item.index="{ index }">
              {{ (currentPage - 1) * itemsPerPage + index + 1 }}
              </template>
  
              <!-- Full Name Column -->
              <template v-slot:item.fullName="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ `${item.fullName}` }}
                    </span>
                  </template>
                  <span style="font-size: 15px">
                    {{ "Grade : " + item.responsibleTeachingAt[0].grade + ", " +
                       "Level : " + item.responsibleTeachingAt[0].level + ", " +
                       "Subject : " + item.responsibleTeachingAt[0].subject }}
                  </span>
                </v-tooltip>
              </template>
  
              <!-- Stages Column -->
              <template v-slot:header.stages>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                  <!-- Left-aligned label -->
                  <div class="text-start" style="width: 90px">
                    {{ stepperStages[0].label }}
                  </div>

                  <!-- Right-aligned label -->
                  <div class="text-end" style="width: 90px; margin-left: auto;">
                    {{ stepperStages[1].label }}
                  </div>
                </v-col>
              </v-row>

            </template>

              <template v-slot:item.stages="{ item }">
                <v-row class="d-flex flex-row align-center pl-2 pr-2">
                  <v-icon medium :color="item.stepper.invitation.iconColor">
                    {{ item.stepper.invitation.name }}
                  </v-icon>
                  <v-divider
                    :thickness="2"
                    class="border-opacity-87 px-0 py-0"
                    :color="item.stepper.mains.iconColor"
                  ></v-divider>
                  <v-icon medium :color="item.stepper.mains.iconColor">
                    {{ item.stepper.mains.name }}
                  </v-icon>
                </v-row>
              </template>

              <!-- Mains Total Column -->
  
              <!-- League Column -->
              <template v-slot:item.league="{ item }">
                <div v-if="item.coreSkillsSubject && item.coreSkillsSubject.length > 0" 
                     class="d-flex justify-center">
                  <div v-for="(coreSkill, index) in item.coreSkillsSubject.slice(0, 3)" 
                       :key="index" 
                       class="mx-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon
                            :class="coreSkill?.league?.toLowerCase() ==='gold' ? 'goldIcons--text': coreSkill?.league?.toLowerCase() !=='bonze' ? coreSkill?.league?.toLowerCase()+'Icon--text': 'bronzeIcon--text'"
                            height="23px"
                            width="25"
                          >
                            mdi-shield-crown-outline
                          </v-icon>
                          <div style="font-size: 12px" v-if="coreSkill.rank">
                            #{{ coreSkill.rank }}
                          </div>
                        </div>
                      </template>
                      <span>
                        {{ coreSkill.skillName }} - 
                        {{ coreSkill?.percentile?.toFixed(2) || "0" }}%
                      </span>
                    </v-tooltip>
                  </div>
                </div>
                <div v-else class="text-center">-</div>
              </template>
  
              <!-- Actions Column -->
              <template v-slot:item.actions="{ item }">
                <div class="d-flex justify-center">
                  <img
                    @click="getResult(item)"
                    class="cursor mr-3"
                    width="24px"
                    src="../assets/Live.svg"
                  />
                  <img
                    v-if="item.userType?.toLowerCase() !== 'teacher'"
                    @click="getFinalResult(item)"
                    class="cursor"
                    width="24px"
                    src="../assets/Final.svg"
                  />
                  <img
                    v-else
                    class="cursor"
                    width="24px"
                    src="../assets/user_report_disabled.svg"
                  />
                </div>
              </template>
  
            </v-data-table>
          </v-card>
          </div>
      </v-col>
    </div>
  </template>
    
  <script >
  import AuthService from "@/services/AuthService";
  import Papa from "papaparse";
  import {
    collection,
    getDocs,
    onSnapshot,
    query,
    where,
  } from "firebase/firestore";
  import { db } from "../firebase";
  import FunnelChartSchool from "./schoolPrincipalChart/FunnelChartSchool.vue";
  import FunnelChartSchool1 from "./schoolPrincipalChart/FunnelChartSchool1.vue";
  import FunnelChartSchool2 from "./schoolPrincipalChart/FunnelChartSchool2.vue";
  import FunnelChartSchool3 from "./schoolPrincipalChart/FunnelChartSchool3.vue";
  import FunnelChartSchool4 from "./schoolPrincipalChart/FunnelChartSchool4.vue";
  import DashboardController from "@/controllers/DashboardController";
  import CampaignController from "@/controllers/CampaignController";
  import FunnelChartCommon from "./FunnelChartCommon.vue";
  import {debounce} from "lodash"
  import BubbleLoader from "@/components/BubbleLoader.vue";

  export default {
    name: "ViewSchoolData",
    components: {
      FunnelChartSchool,
      FunnelChartSchool1,
      FunnelChartSchool2,
      FunnelChartSchool3,
      FunnelChartSchool4,
      FunnelChartCommon,
      BubbleLoader
    },
    data() {
      return {
        data: [
          {
            name: "Frozen Yogurt",
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
          },
          {
            name: "Eclair",
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
          },
          {
            name: "Cupcake",
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
          },
  
          {
            name: "Honeycomb",
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
          },
        ],
        headers: [
        { text: 'S.No', value: 'index', width: '5%', sortable: false, filterable: false},
        { text: 'Full Name', value: 'fullName', width: '15%', sortable: false },
        { text: 'Campaign', value: 'campaignName', sortable: false, width: '25%' },
        // { text: 'School Name', value: 'schoolName', width: '20%' },
        { text: 'Stages', value: 'stages', sortable: false, width: '25%'  },
        { text: 'League', value: 'league',sortable: false, width: '20%', align:"center"  },
        { text: 'Action', value: 'actions', sortable: false,width: '20%' },
      ],
        clusters: [],
        schoolClusterTeacher: [],
        topTeacherInCluster: [],
        invited: 0,
        screeningAttempted: 0,
        screeningResult: 0,
        mainsAttempted: 0,
        result: 0,
        apraisals: 0,
        pip: 0,
        tbd: 0,
        chartData: {},
        schoolChartData: {},
        emptySchoolChartData: {
          invited: 0,
          mainsStarted: 0,
          mainsFailed: 0,
          mainsPassed: 0,
        },
        downloadLoading: false,
        search:"",
        loading:true,
        schooolTeacherAppraisal: 0,
        schooolTeacherPip: 0,
        schooolTeacherTBD: 0,
        jobSeekerList: [],
        filteredAudienceData: [],
        options:{},
        itemsPerPage: 10,
        totalItems: 0,
        currentPage: 1,
        
      };
    },

    watch:{
      options:{
      handler() {
        this.itemsPerPage = this.options.itemsPerPage;
        this.currentPage = this.options.page;
        this.topTeacherInCluster=[];
        this.isLoading=true
        this.getTeacherResults();
      },
      deep: true,
    },
    
    search(newValue){
      this.currentPage = 1
      this.topTeacherInCluster = [];
      this.debouncedSearch();
      this.$router.replace({ 
        query: { 
          ...this.$route.query,
          search: newValue || undefined 
        }
      }).catch(() => {});
    },
    },
  
    computed: {
      displayedTeachers() {
        return this.topTeacherInCluster;
      },
      stepperStages() {
      
      return [
        { label: 'Invited' },
        { label: 'Mains' },
      ];
  },
    },
    methods: {
      logout() {
        AuthService.logout();
        this.$router.push("/login");
      },
      getClusterOrSchool() {
        this.roleCluster = this.$store.state.role.clusterName;
        this.roleSchool = this.$route.params.schoolName;
  
        // console.log("my cluster>>", this.roleCluster);
        console.log("my school>>", this.roleSchool);
      },

      async getTeacherResults() {
      this.loading = true;
      let query = "?itemsPerPage="+this.itemsPerPage+"&currentPage="+this.currentPage+"&school="+this.roleSchool;
      if(this.search) {
        query += "&search="+this.search;
      }
      const response = await DashboardController.getTeacherResults(query);
      if(response.flag) {
        this.schoolTeacher = response.data.teachers;
        this.currentPage = response.data.currentPage;
        this.totalItems = response.data.totalTeachers;
        this.totalPages = response.data.totalPages;
      }
      else {
        return;
      }
      this.schoolTeacher = this.calculateUsersForLeague(this.schoolTeacher);
    
      // this.schoolTeacher = this.addDemoInterviewScore(this.schoolTeacher);
      
      this.schoolTeacher = this.createStepperData(this.schoolTeacher);

      this.topTeacherInCluster = this.schoolTeacher;
      this.loading = false;
    },


    async downloadCampaignTeachersResults() {
        this.downloadLoading = true;
        const fileName = this.roleSchool+"CampaignTeachersResults.csv";
        const dataForDownload = [];
        let query = "?itemsPerPage=-1&currentPage=1&school="+this.roleSchool;
        let downloadTeachers;
      const response = await DashboardController.getTeacherResults(query);
      if(response.flag) {
       downloadTeachers = response.data.teachers;
      }
        for (const teacher of downloadTeachers) {
          const fullName = teacher.fullName;
          const campaign = teacher.campaignName;
          const campstat = teacher.status;

          dataForDownload.push({
              FullName : fullName,
              Campaign : campaign,
              CurrentCampaignStatus : campstat
            });
          }
          this.downloadCSV(dataForDownload,fileName);
          this.downloadLoading = false;
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  
      // async teacherSchoolSnapshotListener() {
      //   const campaignRef = collection(db, "campaigns");
      //   onSnapshot(campaignRef, (snapshot) => {
      //     snapshot.docs.forEach(async (doc) => {
      //       const campaignName = doc.data().name;
      //       if (doc.data().audienceType.toLowerCase() === "teacher") {
      //         try {
      //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
      //           const jobSeekDocs = await getDocs(jobSeekerRef);
  
      //           jobSeekDocs.forEach((d) => {
      //             // console.log("jobSeekersDocsTraverse...", d.data());
      //             if (d.data().schoolName == this.roleSchool) {
      //               // console.log("entered...////");
      //               const topTeachersClusterData = {
      //                 screeningTotal:
      //                   d.data().screeningTotal == null
      //                     ? "-"
      //                     : d.data().screeningTotal,
      //                 mainsTotal:
      //                   d.data().mainsTotal == null ? "-" : d.data().mainsTotal,
      //                 firstName:
      //                   d.data().personalInfo.firstName == null
      //                     ? ""
      //                     : d.data().personalInfo.firstName,
      //                 lastName:
      //                   d.data().personalInfo.lastName == null
      //                     ? ""
      //                     : d.data().personalInfo.lastName,
      //                 schoolName:
      //                   d.data().schoolName == null ? "" : d.data().schoolName,
      //                 lastStatus: (() => {
      //                   let stageValue = "";
      //                   let statusValue = "";
  
      //                   if (
      //                     d.data().campaignStatus &&
      //                     d.data().campaignStatus.length > 0
      //                   ) {
      //                     const lastStatus =
      //                       d.data().campaignStatus[
      //                         d.data().campaignStatus.length - 1
      //                       ].status;
      //                     if (lastStatus === "INVITED") {
      //                       stageValue = "Screening";
      //                       statusValue = "Yet to Attempt";
      //                     } else if (lastStatus === "SCREENING PASSED") {
      //                       stageValue = "Mains";
      //                       statusValue = "Yet to Attempt";
      //                     } else if (lastStatus === "SCREENING FAILED") {
      //                       stageValue = "Screening";
      //                       statusValue = "Fail";
      //                     } else if (lastStatus === "MAINS PASSED") {
      //                       stageValue = "Mains";
      //                       statusValue = "Pass";
      //                     } else if (lastStatus === "MAINS FAILED") {
      //                       stageValue = "Mains";
      //                       statusValue = "Fail";
      //                     }
      //                   }
  
      //                   return {
      //                     stageValue: stageValue,
      //                     statusValue: statusValue,
      //                   };
      //                 })(),
      //                 responsibleTeachingAt: d.data().responsibleTeachingAt,
                      
      //                 campaignName: campaignName,
      //                 campaignStatus : d.data().campaignStatus,
      //                 coreSkillsSubject : d.data().coreSkillsSubject,
      //                 campaignId :doc.id,
      //                 userId: d.data().userId
      //               };
      //               this.schoolClusterTeacher.push(topTeachersClusterData);
      //               console.log(
      //                 "screening total result show >>",
      //                 d.data().screeningTotal
      //               );
      //             }
      //           });
      //           let sortedProducts = this.schoolClusterTeacher.sort((p1, p2) =>
      //             p1.screeningTotal < p2.screeningTotal
      //               ? 1
      //               : p1.screeningTotal > p2.screeningTotal
      //                 ? -1
      //                 : 0
      //           );
      //           this.topTeacherInCluster = this.calculateUsersForLeague(this.schoolClusterTeacher);
      //         } catch (error) {
      //           console.error("Error fetching audience collection:", error);
      //         }
      //       }
      //     });
      //   });
      // },

      //new code new table data unfinished
//       campaignSchoolSnapshotListener() {
//   const campaignRef = collection(db, "campaigns");

//   onSnapshot(campaignRef, async (snapshot) => {
//     this.filteredAudienceData.splice(0); // Clear previous data

//     for (const doc of snapshot.docs) {
//       const campaignData = doc.data();
//       const schools = Array.isArray(campaignData.schools) ? campaignData.schools : [];
      
//       if (schools.includes(this.roleSchool) && campaignData.audienceType.toLowerCase() === "teacher") {
//         try {
//           const audienceRef = collection(campaignRef, doc.id, "audience");
//           const audienceDocs = await getDocs(audienceRef);

//           for (const audienceDoc of audienceDocs.docs) {
//             const audienceData = audienceDoc.data();
//             const audienceSchoolName = audienceData.schoolName;

//             if (audienceSchoolName === this.roleSchool) {
//               let screeningTotal = 0;
//               let mainsTotal = 0;
//               let screeningNetScore = 0;
//               let mainsNetScore = 0;

//               // Calculate screening scores
//               if (audienceData.coreSkillsSubjectScreening?.length > 0) {
//                 audienceData.coreSkillsSubjectScreening.forEach((screeningData) => {
//                   screeningTotal += parseFloat(screeningData?.finalNetScore || 0);
//                 });
//                 screeningTotal /= audienceData.coreSkillsSubjectScreening.length;
//                 screeningNetScore = screeningTotal.toFixed(2);
//               }

//               // Calculate mains scores
//               if (audienceData.coreSkillsSubject?.length > 0) {
//                 audienceData.coreSkillsSubject.forEach((mainsData) => {
//                   mainsTotal += parseFloat(mainsData?.finalNetScore || 0);
//                 });
//                 mainsTotal /= audienceData.coreSkillsSubject.length;
//                 mainsNetScore = mainsTotal.toFixed(2);
//               }

//               const lastStatus = audienceData.campaignStatus 
//                 ? audienceData.campaignStatus[audienceData.campaignStatus.length - 1].status 
//                 : "INVITED";

//               let stage = "Invited";
//               let statusValue = "Yet to Attempt";

//               if (lastStatus === "SCREENING PASSED") {
//                 stage = "Mains";
//                 statusValue = "Yet to Attempt";
//               } else if (lastStatus === "SCREENING FAILED") {
//                 stage = "Screening";
//                 statusValue = "Fail";
//               } else if (lastStatus === "MAINS PASSED") {
//                 stage = "Mains";
//                 statusValue = "Pass";
//               } else if (lastStatus === "MAINS FAILED") {
//                 stage = "Mains";
//                 statusValue = "Fail";
//               }

//               this.filteredAudienceData.push({
//                 firstName: audienceData?.personalInfo?.firstName || "",
//                 lastName: audienceData?.personalInfo?.lastName || "",
//                 schoolName: audienceData.schoolName,
//                 campaignId: doc.id,
//                 userId: audienceData.userId,
//                 status: lastStatus,
//                 responsibleTeachingAt: audienceData.responsibleTeachingAt,
//                 stage: stage,
//                 statusValue: statusValue,
//                 campaignStatus: audienceData.campaignStatus,
//                 coreSkillsSubject: audienceData.coreSkillsSubject,
//                 campaignName: campaignData.name,
//                 screeningTotal: screeningNetScore,
//                 mainsTotal: mainsNetScore,
//                 stepper: audienceData.stepper,
//               });
//             }
//           }
//         } catch (error) {
//           console.error("Error fetching audience data:", error);
//         }
//       }
//     }
//     this.topTeacherInCluster = this.calculateUsersForLeague(this.filteredAudienceData);
//     console.log("Filtered Audience Data: ", this.filteredAudienceData);
//   });
// },
// campaignSchoolSnapshotListener() {
//   const campaignRef = collection(db, "campaigns");

//   onSnapshot(campaignRef, async (snapshot) => {
//     this.filteredAudienceData.splice(0); // Clear previous data

//     for (const doc of snapshot.docs) {
//       const campaignData = doc.data();
//       const schools = Array.isArray(campaignData.schools) ? campaignData.schools : [];
      
//       if (schools.includes(this.roleSchool) && campaignData.audienceType.toLowerCase() === "teacher") {
//         try {
//           const audienceRef = collection(campaignRef, doc.id, "audience");
//           const audienceDocs = await getDocs(audienceRef);

//           for (const audienceDoc of audienceDocs.docs) {
//             const audienceData = audienceDoc.data();
//             const audienceSchoolName = audienceData.schoolName;

//             if (audienceSchoolName === this.roleSchool) {
//               let screeningTotal = 0;
//               let mainsTotal = 0;
//               let screeningNetScore = 0;
//               let mainsNetScore = 0;

//               // Calculate screening scores
//               if (audienceData.coreSkillsSubjectScreening?.length > 0) {
//                 audienceData.coreSkillsSubjectScreening.forEach((screeningData) => {
//                   screeningTotal += parseFloat(screeningData?.finalNetScore || 0);
//                 });
//                 screeningTotal /= audienceData.coreSkillsSubjectScreening.length;
//                 screeningNetScore = screeningTotal.toFixed(2);
//               }

//               // Calculate mains scores
//               if (audienceData.coreSkillsSubject?.length > 0) {
//                 audienceData.coreSkillsSubject.forEach((mainsData) => {
//                   mainsTotal += parseFloat(mainsData?.finalNetScore || 0);
//                 });
//                 mainsTotal /= audienceData.coreSkillsSubject.length;
//                 mainsNetScore = mainsTotal.toFixed(2);
//               }

//               const lastStatus = audienceData.campaignStatus 
//                 ? audienceData.campaignStatus[audienceData.campaignStatus.length - 1].status 
//                 : "INVITED";

//               let stage = "Invited";
//               let statusValue = "Yet to Attempt";

//               if (lastStatus === "SCREENING PASSED") {
//                 stage = "Mains";
//                 statusValue = "Yet to Attempt";
//               } else if (lastStatus === "SCREENING FAILED") {
//                 stage = "Screening";
//                 statusValue = "Fail";
//               } else if (lastStatus === "MAINS PASSED") {
//                 stage = "Mains";
//                 statusValue = "Pass";
//               } else if (lastStatus === "MAINS FAILED") {
//                 stage = "Mains";
//                 statusValue = "Fail";
//               }

//               const teacherData = {
//                 firstName: audienceData?.personalInfo?.firstName || "",
//                 lastName: audienceData?.personalInfo?.lastName || "",
//                 schoolName: audienceData.schoolName,
//                 campaignId: doc.id,
//                 userId: audienceData.userId,
//                 status: lastStatus,
//                 responsibleTeachingAt: audienceData.responsibleTeachingAt,
//                 stage: stage,
//                 statusValue: statusValue,
//                 campaignStatus: audienceData.campaignStatus,
//                 coreSkillsSubject: audienceData.coreSkillsSubject,
//                 campaignName: campaignData.name,
//                 screeningTotal: screeningNetScore,
//                 mainsTotal: mainsNetScore,
//                 userType: audienceData.userType
//               };

//               // Create stepper data
//               teacherData.stepper = this.createStepperData(teacherData);

//               this.filteredAudienceData.push(teacherData);
//             }
//           }
//         } catch (error) {
//           console.error("Error fetching audience data:", error);
//         }
//       }
//     }
//     this.topTeacherInCluster = this.calculateUsersForLeague(this.filteredAudienceData);
//     console.log("Filtered Audience Data: ", this.filteredAudienceData);
//   });
// },

getStepperIconAndColor(prevStatus = '', curStatus = '') {
  let key = prevStatus + curStatus.trim();
  let map = new Map([
    ['started', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue'}],
    ['startedfailed', {name:'mdi-close-circle', iconColor: 'red'}],
    ['startedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
    ['failed', {name:'mdi-circle-double', iconColor: 'grey'}],
    ['failedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
    ['failedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue'}],
    ['passed', {name:'mdi-circle-double', iconColor: 'grey'}],
    ['passedfailed', {name:'mdi-close-circle', iconColor: 'red'}],
    ['passedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue'}],
    ['', {name:'mdi-circle-double', iconColor: 'grey'}],
  ]);
  return map.get(key);
},

getPassFailGenericVal(status) {
  if (status?.includes('PASSED')) return 'passed';
  if (status?.includes('FAILED')) return 'failed';
  if (status?.includes('STARTED')) return 'started';
  return '';
},

createStepperData(data){
     return data?.map((item)=>{
        let obj = {invitation: { name:'mdi-check-circle', iconColor: 'green'}, 
                 
                 mains:{ }}
        let status = item?.campaignStatus;
        if(status[1]?.status == "MAINS STARTED"){

          obj['mains'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        }
        else{
          obj['mains'] = this.getStepperIconAndColor("","");
        }
        // obj['mains'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[3]?.status), this.getPassFailGenericVal(status[4]?.status))
        // obj['demo'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[5]?.status), this.getPassFailGenericVal(status[6]?.status))
        // obj['interview'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[7]?.status), this.getPassFailGenericVal(status[8]?.status))
        item['stepper'] = obj;
        obj = {};
        return item;
      })
    },



    //old code old table data
      // async teacherSchoolSnapshotListener() {
      //   const campaignRef = collection(db, "campaigns");

      //   onSnapshot(campaignRef, (snapshot) => {
          
      //     this.jobSeekerList.splice(0);

      //     // Loop through each document in the campaigns collection snapshot
      //     snapshot.docs.forEach(async (doc) => {
      //       const campaignName = doc.data().name;

      //       if (doc.data().audienceType.toLowerCase() === "teacher") {
      //         try {
      //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
      //           const jobSeekDocs = await getDocs(jobSeekerRef);

      //           // Process each job seeker document in the audience sub-collection
      //           for (const d of jobSeekDocs.docs) {
      //             const jobSeekerData = d.data();
      //             console.log("jobSeekerData>>>>>>>>>>>>>>", jobSeekerData)
      //             // const schoolName = this.$store.state.role.schools;
      //             const jobSeekerSchoolName = Array.isArray(jobSeekerData.schoolName) ? jobSeekerData.schoolName : (jobSeekerData.schoolName ? [jobSeekerData.schoolName] : []);

      //             if (
      //               (typeof jobSeekerSchoolName === 'string' && jobSeekerSchoolName == this.roleSchool) ||
      //               (Array.isArray(jobSeekerSchoolName) && jobSeekerSchoolName.includes(this.roleSchool))
      //             ) {
      //               if (jobSeekerData?.campaignStatus?.length > 0) {
      //                 for (const dt of jobSeekerData.coreSkillsSubject || []) {
      //                   // Ensure coreSkillsSubjectScreening exists and is iterable
      //                   if (jobSeekerData.coreSkillsSubjectScreening?.length > 0) {
      //                     try {
      //                       let screeningTotal = 0;
      //                       let mainsTotal = 0;
      //                       let screeningNetScore = 0;
      //                       let mainsNetScore = 0;

      //                       // Calculate the total scores for screening
      //                       jobSeekerData.coreSkillsSubjectScreening.forEach((jobSeekerScreeningData) => {
      //                         screeningTotal += parseFloat(jobSeekerScreeningData?.finalNetScore);
      //                       });
      //                       screeningTotal = screeningTotal / jobSeekerData.coreSkillsSubjectScreening.length;
      //                       screeningNetScore = (screeningTotal) ? screeningTotal.toFixed(2) : 0.00;

      //                       // Calculate the total scores for mains
      //                       jobSeekerData.coreSkillsSubject.forEach((jobSeekerMainsData) => {
      //                         mainsTotal += parseFloat(jobSeekerMainsData?.finalNetScore);
      //                       });
      //                       mainsTotal = mainsTotal / jobSeekerData.coreSkillsSubject.length;
      //                       mainsNetScore = (mainsTotal) ? mainsTotal.toFixed(2) : 0.00;

      //                       // Prepare the job seeker data for display
      //                       const temp = {
      //                         firstName: jobSeekerData?.personalInfo?.firstName || "",
      //                         lastName: jobSeekerData?.personalInfo?.lastName || "",
      //                         campaignName: campaignName,
      //                         schoolName: jobSeekerData.schoolName,
      //                         screeningTotal: screeningNetScore,
      //                         mainsTotal: mainsNetScore,
      //                         status: jobSeekerData.campaignStatus ? jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length - 1].status : "INVITED",
      //                         stage: "Invited",
      //                         campaignId: doc.id,
      //                         userId: jobSeekerData.userId,
      //                         responsibleTeachingAt: jobSeekerData.responsibleTeachingAt,
      //                         coreSkillsSubject: jobSeekerData.coreSkillsSubject,

      //                       };

      //                       // Determine the stage based on status
                            
      //                       if (temp.status === "MAINS PASSED" || temp.status === "MAINS FAILED")
      //                         temp.stage = "Mains";

      //                       if (temp.status === "INVITED") {
      //                         temp.stage = "MAINS";
      //                         temp.statusValue = "Yet to Attempt";
      //                       } else if (temp.status === "SCREENING PASSED") {
      //                         temp.stage = "Mains";
      //                         temp.statusValue = "Yet to Attempt";
      //                       } else if (temp.status === "SCREENING FAILED") {
      //                         temp.stage = "Screening";
      //                         temp.statusValue = "Fail";
      //                       } else if (temp.status === "MAINS PASSED") {
      //                         temp.stage = "Mains";
      //                         temp.statusValue = "Pass";
      //                       } else if (temp.status === "MAINS FAILED") {
      //                         temp.stage = "Mains";
      //                         temp.statusValue = "Fail";
      //                       }

      //                       this.jobSeekerList.push(temp);
      //                       console.log("jobSeekerList>>>>>>>>>>>>>>", this.jobSeekerList)
      //                       break;
      //                     } catch (error) {
      //                       console.error("Error fetching screening or mains documents:", error);
      //                     }
      //                   } else {
      //                     console.warn("coreSkillsSubjectScreening is not iterable or empty.");
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //           // this.topTeacherInCluster = this.jobSeekerList;
      //         } catch (error) {
      //           console.error("Error fetching audience collection:", error);
      //         }
      //       }
      //     });
      //   });
      // },

      calculateUsersForLeague(users) {
      users.forEach((user) => {
        var coreSkills = [];
        if (user.coreSkillsSubject !== undefined) {
          user.coreSkillsSubject.forEach((coreSkillSubject) => {
            if (coreSkillSubject.result == "PASSED") {
              coreSkills.push(coreSkillSubject);
            }
          });
        }
        user.coreSkillsSubject = coreSkills;
      });
      return users;
    },
    async getResult(data) {
      console.log("data" , data.campaignId);
      const response = await CampaignController.getResult(data.userId, data.campaignId);
      if (response.status == 200) {
        if (response.data.length == 0) {
          alert("User test not started");
        } else {
          this.$router.push(`/testreport/${data.campaignId}/${data.userId}`);
        }
      } else {
        alert(response.data.error);
      }
    },
    getFinalResult(data) {
      this.$router.push({
        path: "/userreport",
        name: "UserReport", //use name for router push
        query: {
          campaignId: data.campaignId,
          userId: data.userId,
        },
      });
    },

  
      async setupSnapshotListenerSchoolHrFunnel() {
        const id = collection(
          db,
          "dashboards",
          "management-dashboard-001",
          "schools"
        );
        console.log("inside snapshot", id);
        if (!id) {
          this.schoolChartData = this.emptySchoolChartData;
          console.log("isnide snap", this.schoolChartData);
        }
  
        const q = query(id, where("name", "==", this.roleSchool));
        if (!q.exists) {
          this.schoolChartData = this.emptySchoolChartData;
        }
  
        onSnapshot(q, (snapshot) => {
          snapshot.forEach((doc) => {
            if (
              doc.data().totalInvited === undefined ||
              doc.data().totalScreeningStarted === undefined ||
              doc.data().totalScreeningPassed === undefined ||
              doc.data().totalScreeningFailed === undefined ||
              doc.data().totalMainsFailed === undefined ||
              doc.data().totalMainsPassed === undefined ||
              doc.data().totalMainsStarted === undefined
            ) {
              this.schoolChartData = this.emptySchoolChartData;
            } else {  
              this.schoolChartData = {
                "invited": doc.data().totalInvited,
                "mainsStarted": doc.data().totalMainsStarted,
                "mainsFailed": doc.data().totalMainsFailed,
                "mainsPassed": doc.data().totalMainsPassed,
              }
            }
          });
        });
      },
      calculatePercentage(value, total) {
        if (total === 0) {
          return 0;
        }
        const percentage = (value / total) * 100;
        if (isNaN(percentage)) {
          return 0;
        }
        const roundedPercentage = Math.round(percentage);
        return roundedPercentage;
      },
    },
  
    async created() {
      this.search = this.$route.query.search;
      this.debouncedSearch = debounce(this.getTeacherResults, 500)
      this.getClusterOrSchool();
      this.setupSnapshotListenerSchoolHrFunnel();
      // this.teacherSchoolSnapshotListener();
      // this.campaignSchoolSnapshotListener();
      // this.getTeacherResults();
    },
  };
  </script>
    
  <style scoped>
  .tbl>.t-head>.t-row>.head {
    background-color: primary;
  }
  
  .tbl {
    height: 196px;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  
  .tbl th {
    padding: 8px;
  }
  
  .tbl td {
    padding: 5px;
  }
  
  .tbl>.t-body>.t-row {
    background-color: white;
  }
  
  .head {
    text-align: center;
  }
  
  .tbl>.t-body>.t-row>.t-data {
    text-align: center;
  }
  
  .tbl>tr:nth-child(even) {
    background-color: white !important;
  }
  
  
  .fail {
    color: #FF0505;
  
  }
  
  .pass {
    color: #06C270;
  }
  
  .yetToAttempt {
    color: #000000DE;
  }
  
  .dotyetToAttempt {
    width: 8px;
    height: 8px;
    background-color: #000000de;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .dotfail {
    width: 8px;
    height: 8px;
    background-color: #ff0505;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .dotpass {
    width: 8px;
    height: 8px;
    background-color: #06c270;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }

  .campaign-funnel-card{
    flex: 1;
  }
  </style>